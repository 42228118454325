@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,500&display=swap&subset=cyrillic,cyrillic-ext,latin-ext);
:root {
  --filter-bright-color-PNG: 1;
  --whiteColor: #fff;
  --box-shadow: rgba(0, 0, 0, 0.11);
  --footer-text: rgba(75, 101, 132, 0.7);
  --white-with-opacity: rgba(255, 255, 255, 0.3);
  --blackColor: #000;
  --not-changine-black: #000;
  --not-changine-white: #fff;
  --theme-primary-80: rgba(0, 128, 201, 0.8);
  --lavender-grey: #f7f9fc;
  --additional-blue: #cde1ff;
  --additional-gray-blue: #e2eaf6;
  --additional-gray-blue-20: rgba(226, 234, 246, 0.2);
  --additional-light-blue: #e4efff;
  --blue-twinkle: #d1d8e0;
  --button-default-disabled: #80bbde;
  --button-default-enabled: #0080c9;
  --button-default-focused: #0078bd;
  --button-default-focused-10: #f0f6ff;
  --button-default-pressed: #0071b1;
  --button-default-pressed-15: #ceedff;
  --button-icon-enabled: #e4efff;
  --button-icon-focused: #f0f6ff;
  --button-icon-pressed: #0080c9;
  --button-outline-focused: #e8f5fc;
  --button-primary-disabled: #fff380;
  --button-primary-enabled: #fee801;
  --button-primary-focused: #f4df01;
  --button-primary-focused-10: #fefce6;
  --button-primary-pressed: #ead501;
  --button-primary-pressed-15: #fcf9d9;
  --not-answer: #ffc107;
  --button-secondary-disabled: #f1f5fa;
  --button-secondary-enable: #e2eaf6;
  --button-secondary-focused: #dae2ee;
  --button-secondary-pressed: #d3deed;
  --functional-error-10: rgba(255, 124, 148, 0.1);
  --functional-error-15: rgba(255, 124, 148, 0.15);
  --functional-success: #15cf74;
  --functional-success-bold: #28a745;
  --functional-success-10: rgba(21, 207, 116, 0.1);
  --functional-warning: #ff7d95;
  --functional-warning-light: rgba(255, 125, 149, 0.53);
  --functional-warning-bold: #e20000;
  --gray-6: #f2f2f2;
  --neutral-dark-blue: #000;
  --neutral-dark-blue-70: #000;
  --neutral-dark-blue-50: #000;
  --neutral-gray-blue: #000;
  --neutral-gray-blue-50: #000;
  --neutral-gray: #d1d8e0;
  --dark-mode-input-placeholder: #9b9ea5;
  --neutral-light-gray: #f7f9fc;
  --neutral-light-gray-no-opacity: #f7f9fc;
  --text-color: #000;
  --badge-color: #29b1ff;
  --light-white: #fafafa;
  --placeholder-color: rgba(75, 101, 132, 0.8);
  --modal-btn-color: rgba(255, 255, 255, 0.6);
  --modal-footer-btn-color: #0e71b8;
  --theme-accent: #fee801;
  --theme-primary: #0080c9;
  --badge-category-color: #fad978;
  --wrong-game-button: #e03d24;
  /*--body-color: #e9f6fe;*/
  --body-color: #fff;
}

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.77a030c8.eot);
  src: url(/static/media/icomoon.77a030c8.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.69afc0f2.ttf) format('truetype'), url(/static/media/icomoon.f94cc7bc.woff) format('woff'),
    url(/static/media/icomoon.9a71bce7.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-speaker:before {
  content: '\e91c';
}
.icon-zoom:before {
  content: '\e908';
}
.icon-star-line:before {
  content: '\e900';
}
.icon-profile:before {
  content: '\e931';
}
.icon-arrow-fill:before {
  content: '\e918';
}
.icon-arrow:before {
  content: '\e917';
}
.icon-close:before {
  content: '\e909';
}
.icon-grid:before {
  content: '\e90a';
}
.icon-list:before {
  content: '\e91b';
}
.icon-star-fill:before {
  content: '\e907';
  color: var(--button-primary-pressed);
}
.icon-driving-school:before {
  content: '\e901';
}
.icon-check:before {
  content: '\e902';
}
.icon-multilang:before {
  content: '\e904';
}
.icon-theory:before {
  content: '\e905';
}
.icon-support:before {
  content: '\e90b';
}
.icon-email:before {
  content: '\e90c';
}
.icon-share:before {
  content: '\e90d';
}
.icon-whatsapp:before {
  content: '\e90e';
}
.icon-facebook:before {
  content: '\e90f';
}
.icon-instagram:before {
  content: '\e910';
}
.icon-twitter:before {
  content: '\e911';
}
.icon-linkedine:before {
  content: '\e912';
}
.icon-e-bike:before {
  content: '\e913';
}
.icon-tractor:before {
  content: '\e914';
}
.icon-motorcycle:before {
  content: '\e915';
}
.icon-car:before {
  content: '\e916';
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'museo-slab', sans-serif;
  font-weight: 700;
}

h1,
.h1 {
  font-size: 38px;
  line-height: 60px;
}

h2,
.h2 {
  font-size: 36px;
  line-height: 44px;
}

h3,
.h3 {
  font-size: 28px;
  line-height: 42px;
}

h4,
.h4 {
  font-size: 26px;
  line-height: 36px;
}

h5,
.h5 {
  font-size: 24px;
  line-height: 36px;
}

h6,
.h6 {
  font-size: 20px;
  line-height: 30px;
}

.h7 {
  font-family: 'hind', sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.h8 {
  font-family: 'hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

p {
  font-family: 'hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
}

.p3 {
  font-family: 'hind', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--blackColor);
}

@media (max-width: 991px) {
  h3,
  .h3 {
    font-size: 24px;
    line-height: 36px;
  }
}

button,
button:hover {
  border-color: transparent;
}

/*fot titles*/
@font-face {
  font-family: 'museo-slab';
  src: url(/static/media/MuseoSlab-700.384384ce.eot);
  src: url(/static/media/MuseoSlab-700.384384ce.eot?#iefix) format('embedded-opentype'),
    url(/static/media/MuseoSlab-700.a18283a5.woff) format('woff'),
    url(/static/media/MuseoSlab-700.910d8a0a.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'museo-slab';
  src: url(/static/media/MuseoSlab-300.0ea46bf3.eot);
  src: url(/static/media/MuseoSlab-300.0ea46bf3.eot?#iefix) format('embedded-opentype'),
    url(/static/media/MuseoSlab-300.80fdc9df.woff) format('woff'),
    url(/static/media/MuseoSlab-300.56e7d890.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

/*fot text*/
@font-face {
  font-family: 'hind';
  src: url(/static/media/MuseoSansCyrl-700.ca722d99.eot);
  src: url(/static/media/MuseoSansCyrl-700.ca722d99.eot?#iefix) format('embedded-opentype'),
    url(/static/media/MuseoSansCyrl-700.a128eded.woff) format('woff'),
    url(/static/media/MuseoSansCyrl-700.75a016c8.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'hind';
  src: url(/static/media/MuseoSansCyrl-300.ac9e2c9a.eot);
  src: url(/static/media/MuseoSansCyrl-300.ac9e2c9a.eot?#iefix) format('embedded-opentype'),
    url(/static/media/MuseoSansCyrl-300.7c413ff1.woff) format('woff'),
    url(/static/media/MuseoSansCyrl-300.c2e5987a.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'retro';
  src: url(/static/media/retro-game.4c19fc87.ttf);
  font-weight: 400;
  font-style: normal;
}

/* Should be removed to disable scroll top when modal open */
/* html,
body {
  overflow-x: hidden;
} */

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--body-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.form-control:focus {
  box-shadow: none;
}

.form-control {
  color: var(--placeholder-color);
  padding: 8px 12px;
  height: 44px;
}

.form-control::-webkit-input-placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.form-control::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul,
ol {
  list-style: none;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--theme-primary);
  transition: all ease 0.3s;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
  color: var(--button-default-focused);
}

*,
:focus,
a,
a:focus,
button,
button:focus {
  outline: none;
}

video {
  display: inline-block;
}

#root {
  margin: 0 auto;
  background-color: var(--whiteColor);
  transition: all 0.5s ease;
}

/*********************************BUTTON*********************************/

.btnLabel {
  color: var(--theme-primary);
  text-decoration: none;
  transition: all ease-in 0.3s;
  background-color: transparent;
}

.btnLabel:hover,
.btnLabel:focus,
.btnLabel.hover,
.btnLabel.focus {
  background-color: var(--button-default-focused-10);
  border-color: var(--button-default-focused-10);
  color: var(--theme-primary);
}

.btn {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  letter-spacing: 0.02px;
  padding: 6px 24px;
  border-radius: 4px;
  transition: all ease 0.3s;
  min-width: 104px;
  border-width: 2px;
}

.btn:first-letter {
  text-transform: uppercase;
}

.btn.focus,
.btn:focus,
.btn.active,
.btn:active {
  outline: 0;
  box-shadow: none;
}

.btn-primary {
  background-color: var(--theme-accent);
  border-color: var(--theme-accent);
  color: var(--not-changine-black);
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:hover {
  background-color: var(--button-primary-focused);
  border-color: var(--button-primary-focused);
  color: var(--not-changine-black);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--button-primary-disabled);
  border-color: var(--button-primary-disabled);
  color: rgba(var(--blackColor), 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--button-primary-pressed);
  border-color: var(--button-primary-pressed);
  color: var(--blackColor);
}

.btn-secondary {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: var(--not-changine-white);
}

.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  background-color: var(--button-default-focused);
  border-color: var(--button-default-focused);
  color: var(--not-changine-white);
  box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: var(--button-default-disabled);
  border-color: var(--button-default-disabled);
  color: var(--not-changine-white);
}

.btn-outline-primary {
  border-color: var(--theme-accent);
  color: var(--blackColor);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: var(--button-primary-focused-10);
  border-color: var(--button-primary-focused);
  color: var(--blackColor);
  box-shadow: none;
}

.btn-outline-secondary {
  border-color: var(--theme-primary);
  color: var(--neutral-dark-blue);
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--button-outline-focused);
  border-color: var(--theme-primary);
  color: var(--neutral-dark-blue);
  box-shadow: none;
}

.dark-theme .btn-outline-secondary:focus,
.dark-theme .btn-outline-secondary.focus,
.dark-theme .btn-outline-secondary:hover,
.dark-theme .btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--theme-primary);
  color: var(--neutral-dark-blue);
}

.linkBtn {
  display: flex;
  align-items: center;
  font-family: 'museo-slab', sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: var(--theme-primary);
}

.linkBtn > .icon-arrow {
  color: currentColor;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 2px;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background-color: var(--modal-btn-color);
  padding: 0;
  min-width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051;
}

.closeBtn .icon-close {
  color: var(--theme-primary);
}

.closeBtn:focus,
.closeBtn:hover,
.closeBtn:visited {
  background-color: transparent;
}

.closeBtnWhite,
.closeBtnWhite:focus,
.closeBtnWhite:hover,
.closeBtnWhite:visited {
  color: var(--whiteColor);
}

.closeBtnBlue,
.closeBtnBlue:focus,
.closeBtnBlue:hover,
.closeBtnBlue:visited {
  color: var(--theme-primary);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: var(--theme-primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary.dropdown-toggle {
  color: var(--theme-primary);
}

/*********************************BUTTON END*********************************/

/**********************************MODAL***************************************/

.modal-dialog.modal-sm {
  max-width: 346px;
}

.modal-dialog.modal-lg {
  max-width: 740px;
}

.modal-content {
  border-radius: 6px;
}

.modal-header button.close {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 32px;
  height: 32px;
  margin: 0;
  opacity: 1;
  font-weight: normal;
  cursor: pointer;
  text-shadow: none;
}

button.close > span {
  opacity: 0;
}

.modal-header button.close:before {
  content: '\e909';
  position: absolute;
  font-family: 'icomoon';
  font-size: 32px;
  color: var(--theme-primary);
  left: 0;
  top: 0;
}

.modal-open {
  height: 100vh;
}

.modal.show .modal-dialog {
  width: 100%;
  margin: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal.fade .modal-dialog {
  transition: all 0.3s ease;
}

.modalLogin .modal-header {
  padding: 20px;
}

.modalLogin .modal-body,
.modalLogout .modal-body,
.modalLogout .modal-header {
  padding: 0 20px;
}

.modal-body .btn-link {
  display: inline-block;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  color: var(--not-changine-black);
  margin-top: 18px;
}

.modal-content {
  border: none;
}

.modal-footer {
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  border-top: none;
}

.modal-sm .modal-footer .btn {
  width: 100%;
  border-radius: 0 0 6px 6px;
  text-align: center;
}

.modal-sm .modal-footer .btn:nth-last-child(2) {
  display: inline-block;
  text-align: center;
}

.modal-footer > :not(:first-child) {
  margin-left: 0;
}

.modal-footer > :not(:last-child) {
  margin-right: 0;
}

.modal-footer > .btn-link {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: var(--modal-footer-btn-color);
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modalQuestion .modal-content {
  padding: 34px 50px 24px;
}

.modalQuestion .modal-footer {
  justify-content: flex-end;
}
.modalQuestion .modal-content {
  background-color: var(--whiteColor);
}

.modalQuestion :global(.modal-footer) {
  justify-content: flex-start;
}

.modalQuestion .modal-body,
.modalQuestion .modal-header {
  padding: 0;
}

.modalQuestion .closeBtn {
  left: auto;
  right: 14px;
  top: 14px;
}

.askContentTitle {
  color: var(--neutral-dark-blue);
}

.askContentText {
  color: var(--neutral-gray-blue);
}

.navbar-toggler {
  z-index: 1039;
}

@media (max-width: 991px) {
  .navbar.navbar-expand-lg > .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .navbar {
    padding: 0;
  }
}

.collapsing {
  position: relative;
  left: 0;
  top: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.35s ease;
}

.navbar-collapse.collapse {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColor);
  transition: all 0.5s ease;
}

.navbar-collapse.collapse.show {
  position: relative;
  left: 0;
  top: 0;
  background-color: var(--whiteColor);
  transition-duration: 0.55s;
}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-light .navbar-nav .nav-link {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  transition: 0.3s;
  color: var(--neutral-dark-blue);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--button-default-pressed);
  font-weight: 500;
}

.pageSubtitle {
  color: var(--neutral-dark-blue);
  margin-bottom: 32px;
}

.pageText {
  font-family: 'museo-slab', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.pageTextMd {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
}

.dropdown-menu {
  border: none;
  padding: 0;
}

.dropdown-item {
  padding: 0 30px 0 0;
}

.imgContainer img {
  width: auto;
  height: 100%;
}

.demoVersion {
  position: absolute;
  top: -10px;
  right: 35%;
  z-index: 100;
  background: url(/static/media/demo-label.7dd2ba2f.svg) no-repeat 50% 50%;
  background-size: contain;
  height: 100px;
  width: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demoText {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--not-changine-black);
  max-width: 100%;
  text-align: center;
}

.card-title {
  font-size: 36px;
  line-height: 44px;
  color: var(--neutral-dark-blue);
}

.rsbtn {
  display: none !important;
}

.carousel-item .video-react .video-react-big-play-button.video-react-big-play-button-center {
  top: 50%;
  left: 50%;
  height: 1.5em;
  border: none;
  border-radius: 50%;
  width: 1.5em;
  margin-top: 0;
  margin-left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: all ease 0.3s;
}

.carousel-item .video-react:hover .video-react-big-play-button.video-react-big-play-button-center {
  background-color: rgba(43, 51, 63, 0.7);
}

.carousel-item .video-react .video-react-big-play-button.video-react-big-play-button-center:hover {
  background-color: #2b333f;
}

/*************************************MEDIA*****************************************/

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 1199px) {
  .pageSubtitle {
    margin-bottom: 16px;
  }

  .rulesListItemBg {
    width: 60px;
    height: 60px;
  }

  .pageSubtitle {
    text-align: center;
  }

  .promoItem:last-child {
    max-width: 45%;
  }

  .demoVersion {
    right: 20%;
    width: 60px;
    height: 50px;
  }

  .demoText {
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
  }
}

@media (max-width: 991px) {
  #root {
    position: relative;
  }

  .navbar {
    position: static;
  }

  .navbar-light .navbar-nav .nav-link {
    font-family: 'museo-slab', sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .demoVersion {
    display: none;
  }

  .navbar-collapse {
    padding: 0 65px;
  }

  .modalQuestion .modal-content {
    padding: 40px 24px 14px 24px;
  }
}

@media (max-width: 767px) {
  .pageSubtitle {
    text-align: left;
  }
}

@media screen and (orientation: landscape) and (max-device-width: 991px) {
  html {
    overflow-y: auto;
  }

  .navbar-collapse.collapse.show {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
    height: 100%;
  }

  .modal-content {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .modal-open .modal {
    overflow-y: hidden;
  }

  .modal.show .modal-dialog {
    height: 100%;
  }

  .modalLogin.modal.show .modal-dialog {
    height: unset;
  }

  .modal-content .modal-footer {
    overflow: visible;
  }
}

.notificationSWContainer {
  max-width: 1440px;
  position: fixed;
  z-index: 11;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
}

.notificationSWContainer button {
  margin-left: 15px;
}

@media (max-width: 450px) {
  .notificationSWContainer {
    font-size: 13px;
  }
}

#SWNotification {
  display: none;
}

body .retroFont {
  font-family: 'retro', serif;
  transition: all 0.5s ease;
}

.retroColorChangeClass {
  -webkit-animation: retroColorChange 20s infinite;
          animation: retroColorChange 20s infinite;
}

.retroTextChangeClass {
  -webkit-animation: retroTextColorChange 20s infinite;
          animation: retroTextColorChange 20s infinite;
}

@-webkit-keyframes retroColorChange {
  0% {
    border-color: blue;
  }
  10% {
    border-color: #8e44ad;
  }
  20% {
    border-color: #1abc9c;
  }
  30% {
    border-color: #d35400;
  }
  40% {
    border-color: blue;
  }
  50% {
    border-color: #34495e;
  }
  60% {
    border-color: blue;
  }
  70% {
    border-color: #2980b9;
  }
  80% {
    border-color: #f1c40f;
  }
  90% {
    border-color: #2980b9;
  }
  100% {
    border-color: pink;
  }
}

@keyframes retroColorChange {
  0% {
    border-color: blue;
  }
  10% {
    border-color: #8e44ad;
  }
  20% {
    border-color: #1abc9c;
  }
  30% {
    border-color: #d35400;
  }
  40% {
    border-color: blue;
  }
  50% {
    border-color: #34495e;
  }
  60% {
    border-color: blue;
  }
  70% {
    border-color: #2980b9;
  }
  80% {
    border-color: #f1c40f;
  }
  90% {
    border-color: #2980b9;
  }
  100% {
    border-color: pink;
  }
}

@-webkit-keyframes retroTextColorChange {
  0% {
    color: blue;
  }
  10% {
    color: #8e44ad;
  }
  20% {
    color: #1abc9c;
  }
  30% {
    color: #d35400;
  }
  40% {
    color: blue;
  }
  50% {
    color: #34495e;
  }
  60% {
    color: blue;
  }
  70% {
    color: #2980b9;
  }
  80% {
    color: #f1c40f;
  }
  90% {
    color: #2980b9;
  }
  100% {
    color: pink;
  }
}

@keyframes retroTextColorChange {
  0% {
    color: blue;
  }
  10% {
    color: #8e44ad;
  }
  20% {
    color: #1abc9c;
  }
  30% {
    color: #d35400;
  }
  40% {
    color: blue;
  }
  50% {
    color: #34495e;
  }
  60% {
    color: blue;
  }
  70% {
    color: #2980b9;
  }
  80% {
    color: #f1c40f;
  }
  90% {
    color: #2980b9;
  }
  100% {
    color: pink;
  }
}

.dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.Header_headerTop__33dcI {
  position: relative;
  background-color: var(--light-white);
  z-index: 10;
  transition: all 0.5s ease;
}

.Header_headerTop__33dcI .navbar-nav {
  align-items: center;
}

.Header_headerTop__33dcI .navbar-nav {
  align-items: center;
}

.Header_headerTopContent__3alrr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.Header_headerTopLinkList__kVW9c {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.Header_headerTopLinkList__kVW9c li:not(:last-child) {
  margin-right: 8px;
}

.Header_headerTopLinkItem__2Vs1_ {
  color: var(--theme-primary);
  transition: all ease-in-out 0.4s;
}

.Header_headerTopLinkItem__2Vs1_:hover {
  text-decoration: none;
  color: var(--theme-primary);
}

.Header_langBtnGroup__2j9-r {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.Header_langBtnGroup__2j9-r .Header_button__3kSo_:not(:first-child) {
  margin-left: 28px;
}

.Header_langBtnGroup__2j9-r .Header_button__3kSo_ {
  font-family: 'Hind', sans-serif;
  font-size: 13px;
  line-height: 21px;
  color: var(--neutral-dark-blue);
  transition: all ease-in-out 0.4s;
  border: none;
  background: transparent;
}

.Header_langBtnGroup__2j9-r .Header_button__3kSo_:hover {
  color: var(--theme-primary);
  cursor: pointer;
}

.Header_mobileButtons__wkA7r {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.Header_demoVersionMobile__NiKZa {
  position: relative;
  display: flex;
  margin-right: 23px;
  justify-content: flex-end;
  background: url(/static/media/demo-label.7dd2ba2f.svg) no-repeat 50% 50%;
  background-size: contain;
  height: 60px;
  width: 60px;
  align-items: center;
  padding: 0;
}

.Header_demoVersionMobile__NiKZa:before {
  position: absolute;
  content: '';
  left: 0;
  top: -16px;
  width: 100%;
  height: 30px;
  background-color: var(--theme-accent);
  z-index: 1;
}

.Header_demoText__1xTep {
  font-family: 'Hind', sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--blackColor);
  max-width: 100%;
  text-align: center;
}

.navbar {
  padding: 0;
  z-index: 10;
}

.Header_accountBtn__1rLih i {
  color: var(--theme-primary);
  font-size: 24px;
}

.Header_accountBtn__1rLih {
  display: none;
}

@media (max-width: 991px) {
  .Header_headerTop__33dcI {
    display: none;
  }

  .Header_accountBtn__1rLih {
    display: flex;
  }
}

@media (min-width: 992px) {
  .Header_demoVersionMobile__NiKZa {
    display: none;
  }
}

.ThenToggle_reactSwitchCheckbox__2-GWi {
  display: none;
}

.ThenToggle_reactSwitchLabel__18lXP {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /*TODO return this for lady-mode*/
  /*width: 105px;*/
  width: 70px;
  height: 35px;
  background: grey;
  border-radius: 48px;
  position: relative;
  background: linear-gradient(#39598a, #79d7ed);
  transition: background-color 0.2s;
}

.ThenToggle_reactSwitchLabel__18lXP .ThenToggle_reactSwitchButton__3vvpm {
  content: '';
  position: absolute;
  z-index: 50;
  top: 5px;
  /*TODO return this for lady-mode*/
  /*left: calc(50% - 13px); */
  left: calc(25% - 13px);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--white);
  transition: 0.2s;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.ThenToggle_reactSwitchLabel__18lXP button {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.ThenToggle_reactSwitchLabel__18lXP img {
  width: 20px;
}

/*switcher span position*/
body.dark-theme .ThenToggle_reactSwitchLabel__18lXP .ThenToggle_reactSwitchButton__3vvpm {
  left: calc(100% - 5px);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

body.lady-theme .ThenToggle_reactSwitchLabel__18lXP .ThenToggle_reactSwitchButton__3vvpm {
  left: calc(0% + 5px);
}

/*switcher  backgrounds */
body.dark-theme .ThenToggle_reactSwitchLabel__18lXP {
  background: linear-gradient(#091236, #1e215d);
}

body.lady-theme .ThenToggle_reactSwitchLabel__18lXP {
  background: linear-gradient(#ffadfa, rgb(190, 143, 244));
}

@media (max-width: 780px) {
  .ThenToggle_reactSwitchLabel__18lXP .ThenToggle_reactSwitchButton__3vvpm {
    transition: 0.4s;
  }
}

.HeaderMenu_headerMenu__4c3wx a {
  font-weight: bold !important;
}

.HeaderMenu_loginBtnMobile__1I4pu,
.HeaderMenu_logoutBtnMobile__1won1 {
  display: none;
  color: var(--neutral-dark-blue);
  font-family: 'museo-slab', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.HeaderMenu_loginBtn__O2Qqo,
.HeaderMenu_logoutBtn__3cwYF {
  display: block;
}

.HeaderMenu_langIconGroup__1mE3U {
  display: none;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 44px;
}

.HeaderMenu_navbar-nav__11zqn .HeaderMenu_nav-link__2G1W- {
  color: var(--neutral-dark-blue);
  transition: all ease 0.3s;
}

.HeaderMenu_navbar-nav__11zqn .HeaderMenu_nav-link__2G1W-.HeaderMenu_active__3GGdE,
.HeaderMenu_navbar-nav__11zqn .HeaderMenu_nav-link__2G1W-:hover {
  color: var(--button-default-pressed);
}

.HeaderMenu_loginBtnGroup__2aQB1 {
  display: flex;
  align-items: center;
}

.HeaderMenu_loginBtnItem__21PWV {
  margin-right: 35px;
}

.HeaderMenu_themSwiperContainerDesktop__3yvbD {
  margin-right: 20px;
  padding-top: 10px;
  transition: all ease-in 0.5s;
}

.HeaderMenu_themSwiperContainerMobile__1R4DA {
  display: none;
}

.HeaderMenu_isGamePath__3fsjB {
  opacity: 0;
}

@media (max-width: 1199px) {
  .HeaderMenu_loginBtnItem__21PWV {
    margin-right: 10px;
  }
}

@media (max-width: 991px) {
  .HeaderMenu_loginBtnMobile__1I4pu,
  .HeaderMenu_logoutBtnMobile__1won1 {
    display: block;
    font-weight: bold;
  }

  .HeaderMenu_themSwiperContainerDesktop__3yvbD {
    display: none;
  }

  .HeaderMenu_themSwiperContainerMobile__1R4DA {
    display: block;
  }

  .HeaderMenu_loginBtnItem__21PWV.HeaderMenu_desktop__35J69 {
    display: none;
  }

  .HeaderMenu_loginBtnGroup__2aQB1 {
    align-items: flex-start;
  }

  .HeaderMenu_loginBtn__O2Qqo,
  .HeaderMenu_logoutBtn__3cwYF {
    display: none;
  }

  .HeaderMenu_langIconGroup__1mE3U {
    display: flex;
  }
}

@media (max-width: 500px) {
  .HeaderMenu_langIconGroup__1mE3U button {
    background-color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
  }

  .HeaderMenu_langIconGroup__1mE3U img {
    width: 43px;
  }
}

.Loader_loader__2J6fK {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  transition: all 0.3s ease;
}

.Loader_showLoader__QlAML {
  display: flex;
}

.Loader_blockLoader__3cPQl {
  position: absolute;
}

.Loader_loader__2J6fK > i {
  font-size: 100px;
  width: 100px;
  height: 100px;
  color: var(--button-default-enabled);
  text-align: center;
  -webkit-animation: Loader_rotate__2usO4 2s infinite;
          animation: Loader_rotate__2usO4 2s infinite;
}

@-webkit-keyframes Loader_rotate__2usO4 {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes Loader_rotate__2usO4 {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.Counter_counter__3VqRh {
  width: 55px;
  padding: 8px 6px;
  text-align: center;
}

.Checkbox_label__kbrG4 {
  display: flex;
  align-items: flex-start;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}

.Checkbox_inputField__30gaE {
  display: none;
}

.Checkbox_labelBox__2pJvi {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(119, 140, 163, 0.4);
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  padding: 0;
  margin-right: 16px;
  transition: all ease-in 0.3s;
  background-color: var(--whiteColor);
}

.dark-theme .Checkbox_labelBox__2pJvi {
  border-color: var(--not-changine-white);
}

.Checkbox_checkboxField__1yYEw {
  display: flex;
  margin-bottom: 6px;
  padding: 6px;
  border-radius: 6px;
  min-height: 60px;
}

.Checkbox_labelBox__2pJvi.Checkbox_checked__2w8Ak {
  border-color: var(--neutral-dark-blue-70);
}

.Checkbox_labelBox__2pJvi.Checkbox_correct___n2sY {
  border-color: var(--functional-success-bold);
  border-width: 2px;
}

.Checkbox_labelBox__2pJvi.Checkbox_incorrect__3GKdM {
  border-color: var(--functional-warning-bold);
  border-width: 2px;
}

.Checkbox_labelBox__2pJvi.Checkbox_checked__2w8Ak,
.Checkbox_labelBox__2pJvi.Checkbox_correct___n2sY,
.Checkbox_labelBox__2pJvi.Checkbox_incorrect__3GKdM {
  position: relative;
}

span.Checkbox_checked__2w8Ak:before,
span.Checkbox_correct___n2sY:before,
span.Checkbox_incorrect__3GKdM:before {
  position: absolute;
  font-family: 'icomoon';
  font-size: 25px;
  font-weight: 800;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

span.Checkbox_checked__2w8Ak:before {
  content: '\e902';
  color: var(--theme-primary);
}

span.Checkbox_correct___n2sY:before {
  color: var(--functional-success-bold);
}

span.Checkbox_incorrect__3GKdM:before {
  color: var(--functional-warning-bold);
}

p.Checkbox_correct___n2sY {
  background-color: var(--functional-success-10);
}

p.Checkbox_incorrect__3GKdM {
  background-color: var(--whiteColor);
}

p.Checkbox_checked__2w8Ak {
  background-color: var(--functional-error-10);
}

p.Checkbox_checked__2w8Ak .Checkbox_labelBox__2pJvi.Checkbox_checked__2w8Ak:before {
  content: '';
}

p.Checkbox_checked__2w8Ak .Checkbox_labelBox__2pJvi.Checkbox_checked__2w8Ak {
  border-color: var(--functional-warning);
}

.Checkbox_showCorrect__2ELPp {
  background-color: var(--functional-success-10);
}

.QuestionView_questionContainer__2-h2M {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  padding: 24px 24px 32px;
  margin-bottom: 30px;
  border-radius: 6px;
  align-items: stretch;
  border: 1px solid transparent;
}

.QuestionView_videoImage__1a0S7 span {
  display: none;
}

.QuestionView_videoImage__1a0S7 img {
  display: block !important;
}

.QuestionView_questionContent__3n0UR {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100%;
  max-width: 33.3%;
  width: 100%;
  margin-left: 60px;
  align-self: stretch;
}

.QuestionView_questionList__3dXE7 {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.QuestionView_questionTitle__3HgRM {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-dark-blue-70);
  min-height: 72px;
}

.QuestionView_questionPaginator__2tIoI {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.QuestionView_btnPrev__6WuvG,
.QuestionView_btnNext__YKZ97,
.QuestionView_btnExit__11GMh {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}

.QuestionView_btnExit__11GMh {
  background-color: #28a745 !important;
}

.QuestionView_btnCenter__5j_P2 {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02px;
  color: var(--whiteColor);
  flex-grow: 1;
  border-radius: 4px;
  padding: 0;
  height: 48px;
  opacity: 1 !important;
}

.QuestionView_btnCorrectAnswer__1URwq {
  border-color: #28a745 !important;
  background-color: #28a745 !important;
}

.QuestionView_btnWrongAnswer__2TkIw {
  border-color: #e20000 !important;
  background-color: #e20000 !important;
}

.QuestionView_btnNext__YKZ97,
.QuestionView_btnExit__11GMh {
  margin-left: 10px;
}

.QuestionView_btnPrev__6WuvG {
  margin-right: 10px;
}

.QuestionView_btnPrev__6WuvG > i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.QuestionView_btnPrev__6WuvG > i,
.QuestionView_btnNext__YKZ97 > i {
  font-size: 20px;
}

.QuestionView_questionListItem__220i2 {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  margin-bottom: 12px;
}

.QuestionView_trueAnswer__MjwaR {
  border: 1px solid var(--functional-success);
}

.QuestionView_falseAnswer__3g4zZ {
  border: 1px solid var(--functional-warning);
}

@media (max-width: 991px) {
  .QuestionView_questionContainer__2-h2M {
    flex-flow: row wrap;
  }

  .QuestionView_questionContent__3n0UR {
    max-width: none;
    width: 100%;
    margin-left: 0;
    padding: 0 10px;
  }

  .QuestionView_questionPaginator__2tIoI {
    max-width: 50%;
    width: 100%;
    margin-top: 18px;
  }

  .QuestionView_questionList__3dXE7 {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .QuestionView_questionPaginator__2tIoI {
    max-width: 100%;
  }

  .QuestionView_questionContainer__2-h2M {
    padding: 0 0 20px;
  }

  .QuestionView_originLink__a5Tg1 {
    display: none;
  }

  .QuestionView_originLinkMobile__Nc7Gf {
    display: inline-block;
  }
}

.ZoomMultimedia_imgContainer-main__2sMLj {
  position: relative;
  background: #f4efef url(/static/media/img-placeholder.e4ae5da5.svg) no-repeat 50% -44px;
  background-size: contain;
  border-radius: 6px;
  padding-top: 40.25%;
  width: 100%;
}

.ZoomMultimedia_imgContainer-sidebar__1n9bc {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.ZoomMultimedia_imgContainer-sidebar__1n9bc .ZoomMultimedia_questionImgContent__2S9tZ {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.ZoomMultimedia_imgContainer-main__2sMLj .ZoomMultimedia_questionImgContent__2S9tZ {
  position: absolute;
  left: 0;
  top: 50%;
  height: 100%;
  border-radius: 6px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  object-fit: cover;
  width: 100%;
}

.ZoomMultimedia_videoContainer__xvlgS {
  position: relative;
  flex-flow: column;
  justify-content: flex-start;
  padding-top: 47.75%;
  overflow: hidden;
  border-radius: 6px;
  width: 100%;
  height: auto;
}

.ZoomMultimedia_videoContainer__xvlgS video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.ZoomMultimedia_videoContainer__xvlgS .ZoomMultimedia_zoomBtn__1Jyel {
  position: absolute;
  right: 10px;
  top: 6px;
  transition: all ease 0.3s;
}

.ZoomMultimedia_sidebarZoomBtn__2U0E3 {
  position: absolute;
  right: 10px;
  top: 6px;
}

@media (max-width: 991px) {
  .ZoomMultimedia_videoContainer__xvlgS {
    padding-top: 56.25%;
  }

  .ZoomMultimedia_imgContainer-main__2sMLj .ZoomMultimedia_questionImgContent__2S9tZ {
    width: 100%;
    height: 100%;
  }

  .ZoomMultimedia_imgContainer-main__2sMLj {
    max-width: none;
    margin-bottom: 14px;
    padding-top: 56.25%;
  }

  .ZoomMultimedia_imgContainer-sidebar__1n9bc img,
  .ZoomMultimedia_imgContainer-sidebar__1n9bc .ZoomMultimedia_sidebarZoomBtn__2U0E3 {
    display: none;
  }

  .ZoomMultimedia_videoContainer__xvlgS .ZoomMultimedia_zoomBtn__1Jyel {
    display: none;
  }
}

.MultimediaModal_multiModal__1zW6b .modal-content {
  background-color: transparent;
  position: static;
}

.MultimediaModal_multiModal__1zW6b .modal-header button.close {
  position: fixed;
  top: 0;
  right: 0;
}

.MultimediaModal_multiModal__1zW6b .modal-header button.close:before {
  color: var(--whiteColor);
}

.MultimediaModal_multiModal__1zW6b .modal-body {
  padding: 0;
}

.MultimediaModal_multiModal__1zW6b .modal-body img {
  border-radius: 10px;
}

.MultimediaModal_zoomImg__1sJ6r {
  width: 100%;
}

.MultimediaModal_videoContent__GgjRE {
  width: 100%;
}

.ZoomBtn_zoomBtn__2uD_8 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: var(--whiteColor);
  border-radius: 6px;
  cursor: pointer;
  color: var(--theme-primary);
  transition: all ease 0.3s;
  border: 2px solid transparent;
}

.ZoomBtn_zoomBtn__2uD_8 i {
  font-size: 26px;
}

.ZoomBtn_zoomBtn__2uD_8:hover {
  border: 2px solid var(--theme-primary);
}

@media (max-width: 991px) {
  .ZoomBtn_zoomBtn__2uD_8 {
    width: 32px;
    height: 32px;
    right: 10px;
    bottom: 10px;
  }

  .ZoomBtn_zoomBtn__2uD_8 i {
    font-size: 20px;
  }
}

.ZoomLink_zoomLink__3H3-L {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue-70);
  pointer-events: none;
}

@media (max-width: 991px) {
  .ZoomLink_zoomLink__3H3-L {
    color: var(--button-default-focused);
    pointer-events: auto;
    text-decoration: underline;
    transition: all ease 0.3s;
  }

  .ZoomLink_zoomLink__3H3-L:hover {
    cursor: pointer;
  }
}

.LngDropDown_dropDownToggle__1gsez {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  min-width: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.LngDropDown_dropDownToggle__1gsez:hover,
.LngDropDown_dropDownToggle__1gsez:focus {
  background-color: transparent;
  border-color: transparent;
}

.LngDropDown_dropdown-toggle__3A8-b::after {
  border-top: 6px solid;
  border-color: var(--theme-primary);
}

.LngDropDown_barBtn__2oIuo .LngDropDown_dropMenu__396Ii {
  max-width: 70px;
  width: 100%;
  min-width: auto;
  -webkit-transform: none !important;
          transform: none !important;
  top: 32px;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  margin: 0;
}

.flag {
  width: 36px;
  height: 36px;
  margin: 6px;
  border-radius: 50%;
}

.show > .btn-primary.dropdown-toggle {
  position: relative;
  z-index: 9999;
}

@media (max-width: 991px) {
  .flag {
    width: 24px;
    height: 24px;
    margin: 4px;
  }
}

.QuestionLinkList_gameModalContainer__39_gU .modal-content {
  background-color: var(--whiteColor);
  border-color: var(--blackColor);
  border-style: solid;
}

.QuestionLinkList_gameModalContainer__39_gU .modal-content button {
  color: var(--text-color);
  font-family: 'retro', serif;
  border-radius: 0;
}

.QuestionLinkList_examList__IEAso {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  max-height: 364px;
  overflow-y: auto;
}

.QuestionLinkList_examList__IEAso::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.QuestionLinkList_examList__IEAso::-webkit-scrollbar {
  width: 12px;
}

.QuestionLinkList_examList__IEAso::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.QuestionLinkList_examListItem__1nbOT {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid #e2eaf6;
  background: var(--not-changine-white);
}

.dark-theme .QuestionLinkList_examListItem__1nbOT {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid #e2eaf6;
  /*TODO RETURN IF NEED */
  background: var(--neutral-light-gray);
  color: var(--text-color);
}

.examListItemCorrect {
  border-color: var(--functional-success) !important;
  border-width: 2px;
  background-color: var(--functional-success-10);
}

.examListItemCorrect::after {
  content: ' ';
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 19px;
  width: 11px;
  border-bottom: 4px solid var(--functional-success);
  border-right: 4px solid var(--functional-success);
  top: -5px;
  position: absolute;
  right: 0;
  z-index: 9;
}

.examListItemCorrect span::before {
  content: ' ';
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 19px;
  width: 12px;
  border-bottom: 5px solid var(--whiteColor);
  border-right: 5px solid var(--whiteColor);
  top: -5px;
  position: absolute;
  right: 0;
}

.examListItemIncorrect {
  border-color: var(--functional-warning) !important;
  border-width: 2px;
}

.examListItemIncorrect::after {
  content: 'x';
  display: inline-block;
  height: 21px;
  width: 11px;
  color: var(--functional-warning);
  font-size: 26px;
  font-weight: 700;
  top: -15px;
  position: absolute;
  right: 0;
  z-index: 9;
}

.examListItemIncorrect span::before {
  content: 'x';
  display: inline-block;
  height: 21px;
  width: 11px;
  color: var(--whiteColor);
  font-size: 29px;
  font-weight: 700;
  top: -17px;
  position: absolute;
  right: 1px;
}

.QuestionLinkList_favorite__1lKhO:before,
.QuestionLinkList_marked__L2r3y:before {
  position: absolute;
  content: '';
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--button-primary-pressed);
  z-index: 1;
}

.QuestionLinkList_checked__3P_Qy {
  background-color: var(--button-secondary-pressed);
}

.QuestionLinkList_questionsNumbers__NdqRi {
  /*position: relative;*/
  z-index: 2;
}

@media (max-width: 1200px) {
  body .QuestionLinkList_examList__IEAso {
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .QuestionLinkList_examListItem__1nbOT {
    margin: 5px;
  }

  .QuestionLinkList_examList__IEAso::-webkit-scrollbar {
    width: 8px;
  }

  .QuestionLinkList_examList__IEAso {
    max-height: 364px;
  }
}

@media (max-width: 600px) {
  body .QuestionLinkList_examList__IEAso {
    max-width: 440px;
  }
}

.ReadSpeaker_disabledButton__MNMr0 {
  pointer-events: none;
  opacity: 0.4;
}

.ReadSpeaker_stopBtn__2Obby {
  color: var(--whiteColor);
  background-color: var(--theme-primary);
}

.P3_p3__1_bI8 {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.Image_imgContainer__1GjYu img {
  height: auto;
}

.PlaceholderBox_root__1Dtr3 {
  height: 300px;
  width: 100%;
  background-color: var(--lavender-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
  margin-bottom: 15px;
}

.PlaceholderBox_root__1Dtr3 > * {
  max-width: 60%;
  max-height: 70%;
}

.TheoryBook_questionBtnListItem__7FZK0 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  cursor: pointer;
  color: var(--theme-primary);
  border-radius: 2px;
  margin-bottom: 12px;
}

.TheoryBook_glossaryHeader__2-8Pu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.TheoryBook_glossaryHeader__2-8Pu .glossary-title {
  margin-right: 10px;
}

.TheoryBook_questionBtnListItem__7FZK0 > i {
  font-size: 48px;
}

.TheoryBook_glossaryFooter__3DPP8 {
  position: absolute;
  bottom: 0;
  left: 44px;
  right: 44px;
  padding: 38px 44px 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
}

.TheoryBook_footerLink__2egRD {
  display: none;
}

.TheoryBook_btnPrev__3a6iB,
.TheoryBook_btnNext__2ycYk {
  position: absolute;
  top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: var(--additional-gray-blue);
  border-radius: 6px;
  transition: all ease 0.3s;
  min-width: auto;
}

.TheoryBook_btnPrev__3a6iB:disabled,
.TheoryBook_btnNext__2ycYk:disabled {
  opacity: 0;
  pointer-events: none;
}

.TheoryBook_btnPrev__3a6iB > i,
.TheoryBook_btnNext__2ycYk > i {
  font-size: 20px;
  color: var(--neutral-gray-blue);
}

.TheoryBook_btnPrev__3a6iB {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  left: 0;
}

.TheoryBook_btnNext__2ycYk {
  right: 0;
}

.TheoryBook_btnPrev__3a6iB:hover,
.TheoryBook_btnNext__2ycYk:hover,
.TheoryBook_btnPrev__3a6iB:focus,
.TheoryBook_btnNext__2ycYk:focus,
.TheoryBook_btnPrev__3a6iB:not(:disabled):not(.TheoryBook_disabled__1n3yw):active,
.TheoryBook_btnNext__2ycYk:not(:disabled):not(.TheoryBook_disabled__1n3yw):active,
.TheoryBook_btnPrev__3a6iB:not(:disabled):not(.TheoryBook_disabled__1n3yw).TheoryBook_active__1WX4b,
.TheoryBook_btnNext__2ycYk:not(:disabled):not(.TheoryBook_disabled__1n3yw).TheoryBook_active__1WX4b,
.TheoryBook_btnPrev__3a6iB:not(:disabled):not(.TheoryBook_disabled__1n3yw):active:focus,
.TheoryBook_btnNext__2ycYk:not(:disabled):not(.TheoryBook_disabled__1n3yw):active:focus {
  background-color: var(--button-secondary-enable);
  box-shadow: none;
}

.glossary-subtitle {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
}

.glossary-subtitle, .glossary-text {
  margin-bottom: 12px;
  display: block;
  width: 100%;
}

.glossary-title {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 20px;
}

.TheoryBook_glossaryImgList__b8htd {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.TheoryBook_theoryText__mx6LO {
  color: var(--text-color);
}

.TheoryBook_imgContent__2VyPb,
.TheoryBook_imgPlaceholderContent__2LZwF {
  display: inline-flex;
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-right: 24px;
}

.TheoryBook_imgPlaceholderContent__2LZwF {
  width: 160px;
  height: 120px;
}

.TheoryBook_glossaryScrollBox__20HmE {
  max-height: calc(100% - 100px);
  height: 100%;
  overflow-y: auto;
}

.TheoryBook_glossaryScrollBox__20HmE::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TheoryBook_glossaryScrollBox__20HmE::-webkit-scrollbar {
  width: 12px;
}

.TheoryBook_glossaryScrollBox__20HmE::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.TheoryBook_modal__kjcJ0 .modal-content {
  background-color: var(--whiteColor);
}

.TheoryBook_modal__kjcJ0,
.TheoryBook_backdrop__3mipi,
.TheoryBook_topicLink__157Kq {
  display: none !important;
}

.TheoryBook_topicNumberWrapper__1dUfR {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TheoryBook_topicNumberWrapper__1dUfR svg {
  width: 30px;
  height: 30px;
}

.TheoryBook_topicNumber__dSgmv {
  color: var(--text-color);
}

@media (max-width: 991px) {
  .TheoryBook_questionBtnListItem__7FZK0 {
    width: 32px;
    height: 32px;
  }

  .TheoryBook_questionBtnListItem__7FZK0 > i {
    font-size: 32px;
  }

  .TheoryBook_glossaryScrollBox__20HmE {
    max-height: none;
    overflow-y: inherit;
  }

  .TheoryBook_imgPlaceholderContent__2LZwF {
    width: 120px;
    height: 80px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .TheoryBook_glossaryScrollBox__20HmE.TheoryBook_withModal__1QTsq {
    display: none;
  }

  .TheoryBook_modal__kjcJ0,
  .TheoryBook_backdrop__3mipi,
  .TheoryBook_topicLink__157Kq {
    display: block !important;
  }

  .TheoryBook_topicNumber__dSgmv {
    margin-bottom: 0;
  }

  .TheoryBook_glossaryFooter__3DPP8 {
    position: relative;
    left: 0;
    right: 0;
    padding: 0;
    height: 48px;
  }

  .TheoryBook_btnPrev__3a6iB,
  .TheoryBook_btnNext__2ycYk {
    top: 0;
  }
}

.TheoryBookContainer_glossaryContent__1yiZW {
  position: relative;
  padding: 40px 44px 44px;
  background-color: var(--whiteColor);
  border-radius: 6px;
  flex-grow: 1;
  width: 100%;
}

.TheoryBookContainer_glossaryScrollBox__2uovs::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TheoryBookContainer_glossaryScrollBox__2uovs::-webkit-scrollbar {
  width: 12px;
}

.TheoryBookContainer_glossaryScrollBox__2uovs::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

@media (max-width: 991px) {
  .TheoryBookContainer_glossaryContent__1yiZW {
    padding: 0;
  }
}

.TheoryModal_theoryModal__3ZnX0 .modal-dialog {
  max-width: 1200px;
  max-height: 750px;
  height: 100%;
  overflow: hidden;
}

.TheoryModal_theoryModal__3ZnX0 .modal-body > div {
  height: 100%;
}

.TheoryModal_theoryModal__3ZnX0 .modal-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--whiteColor);
}

.QuestionBar_questionBtnList__1O9l4 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}

.QuestionBar_btnPrev__2tyfF {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.QuestionBar_questionBtnListItem__1Nq0j {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--theme-primary);
  border: none;
  padding: 0;
}

.QuestionBar_questionBtnListItem__1Nq0j > i {
  font-size: 36px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.QuestionBar_questionBtnListItem__1Nq0j:not(:last-child) {
  margin-right: 10px;
}

.QuestionBar_lngBtn__29HdW {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 5px;
  padding: 3px;
}

@media (max-width: 991px) {
  .QuestionBar_questionBtnListItem__1Nq0j {
    width: 32px;
    height: 32px;
  }

  .QuestionBar_questionBtnListItem__1Nq0j > i {
    font-size: 26px;
  }
}

.Timer_timer__1_mjp {
  width: 54px;
  padding: 8px 6px;
  text-align: center;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-body {
  padding: 0 20px;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-header {
  padding: 20px;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-body {
  padding: 0 20px;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-title {
  font-family: 'museo-slab', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.FinishExamModal_finishExamModal__2Qh3S .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-body {
  padding: 0 20px;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-header {
  padding: 20px;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-body {
  padding: 0 20px;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-content {
  background-color: var(--whiteColor);
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-title {
  font-family: 'museo-slab', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.LeaveExamModal_leaveExamModal__2w5JY .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*TODO: update after add loader in project*/

.ExamCardContainer_loader__2UNcL {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 30px;
  font-family: 'museo-slab', sans-serif;
  color: var(--whiteColor);
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.ExamCardContainer_questionPage__3k6Ce {
  background-color: var(--lavender-grey);
}

.ExamCardContainer_questionLinkBottom__3q0DG {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 88px;
}

.ExamCardContainer_loaderContainer__mo2vC {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

.ExamCardContainer_questionLinkBottom__3q0DG .btnLabel:hover,
.ExamCardContainer_questionLinkBottom__3q0DG .btnLabel:focus {
  background-color: var(--button-default-focused-10);
}

.ExamCardContainer_questionBar__3_lgk {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.ExamCardContainer_doubleCounter__37IAd {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 6px;
  margin: 10px 32px 10px 0;
  border: 1px solid var(--additional-gray-blue);
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--neutral-gray-blue);
}

.ExamCardContainer_doubleCounter__37IAd > div:nth-child(2) {
  border-left: 1px solid var(--additional-gray-blue);
}

.ExamCardContainer_headerQuestionBox__1eOLB {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.ExamCardContainer_headerQuestionBtn__3DieI {
  border: 1px solid var(--button-secondary-enable);
  background-color: var(--whiteColor);
  padding: 5px 24px;
  border-radius: 6px;
  color: var(--neutral-dark-blue);
  max-width: 346px;
  width: 100%;
}

.ExamCardContainer_headerQuestionBtn__3DieI:hover,
.ExamCardContainer_headerQuestionBtn__3DieI:focus {
  text-decoration: none;
  color: var(--neutral-dark-blue);
}

.ExamCardContainer_headerQuestionBtn__3DieI > i {
  font-size: 16px;
  line-height: 1;
}

@media (max-width: 991px) {
  .ExamCardContainer_questionLinkBottom__3q0DG {
    padding-bottom: 40px;
  }

  .ExamCardContainer_questionLinkBottom__3q0DG .btnLabel {
    border: 2px solid var(--theme-primary);
  }
}

@media (max-width: 768px) {
  .ExamCardContainer_questionLinkBottom__3q0DG {
    justify-content: center;
  }
}

.PageTitle_pageTitle__1Iuxc {
  font-size: 40px;
  line-height: 60px;
  color: var(--neutral-dark-blue);
  margin-bottom: 12px;
}

@media (max-width: 1199px) {
  .PageTitle_pageTitle__1Iuxc {
    font-size: 32px;
    line-height: 43px;
  }
}

@media (max-width: 991px) {
  .PageTitle_pageTitle__1Iuxc {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 16px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .PageTitle_pageTitle__1Iuxc {
    text-align: left;
  }
}

.ExamResultInfo_resultPageItem__1EfkY .jumbotron {
  max-width: 440px;
  margin-bottom: 36px;
}

.ExamResultInfo_resultList__10GC6 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
  padding-right: 20px;
}

.ExamResultInfo_resultLabel__aZ7yM {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--neutral-gray-blue-50);
}

.ExamResultInfo_resultValue__2QgW7 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.ExamResultInfo_resultShareTitle__26X-e {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

@media (max-width: 1199px) {
  .ExamResultInfo_resultPageItem__1EfkY {
    max-width: none;
    width: 100%;
  }
}

.Congratulation_resultPageItem__dgOXC {
  max-width: 400px;
  width: 100%;
}

.Congratulation_resultPageItem__dgOXC h1 {
  margin-bottom: 32px;
}

.Congratulation_resultPageItem__dgOXC p {
  margin-top: 24px;
}

@media (max-width: 1199px) {
  .Congratulation_resultPageItem__dgOXC {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 650px) {
  .Congratulation_resultPageItem__dgOXC img {
    width: 100%;
    max-width: 400px;
  }
}

.ExamResultContainer_resultPage__2ebDJ {
  padding: 88px 0 140px;
}

.ExamResultContainer_resultPageContent__3J8ym {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-right: 64px;
}

.ExamResultContainer_resultButton__3pqEj {
  width: 100%;
}

.ExamResultContainer_resultPageRateContainer__24z1s {
  max-width: 320px;
  text-align: center;
  margin: -15px 0 20px 0;
}

.ExamResultContainer_resultPageRateContainer__24z1s p {
  margin: 0;
  border-radius: 6px 6px 0 0;
  background-color: var(--button-primary-disabled);
  padding: 8px 21px;
  font-size: 17px;
  font-weight: bold;
}

.ExamResultContainer_resultPageRateContainer__24z1s a {
  display: flex;
  color: var(--neutral-dark-blue);
  justify-content: center;
  align-items: center;
  background-color: var(--theme-accent);
  height: 35px;
  border-radius: 0 0 6px 6px;
}

.ExamResultContainer_questionLinkLinkContainer__1wvcI {
  margin-top: 10px;
  max-width: 440px;
}

.ExamResultContainer_questionLinkLinkContainer__1wvcI .singleQuestionLink,
.ExamResultContainer_questionLinkLinkContainer__1wvcI .examListItem {
  height: 35px;
  width: 35px;
  font-size: 12px;
}

.ExamResultContainer_questionLinkLinkContainer__1wvcI .examListItemIncorrect::after {
  height: 12px;
  width: 6px;
  font-size: 14px;
  top: -7px;
  right: 0;
}

.ExamResultContainer_questionLinkLinkContainer__1wvcI .examListItemIncorrect span::before {
  height: 12px;
  width: 6px;
  font-size: 16px;
  top: -8px;
  right: 1px;
}
.ExamResultContainer_questionLinkLinkContainer__1wvcI .examListItemCorrect {
  border-color: var(--functional-success);
  border-width: 2px;
  background-color: var(--functional-success-10);
}

.ExamResultContainer_questionLinkLinkContainer__1wvcI .examListItemCorrect::after {
  height: 14px;
  width: 8px;
  border-bottom: 2px solid var(--functional-success);
  border-right: 2px solid var(--functional-success);
  top: -5px;
  right: 0;
}

.ExamResultContainer_questionLinkLinkContainer__1wvcI .examListItemCorrect span::before {
  height: 14px;
  width: 10px;
  border-bottom: 3px solid var(--whiteColor);
  border-right: 3px solid var(--whiteColor);
  top: -5px;
  right: 0;
}

.ExamResultContainer_resultPage__2ebDJ .questionLinkList {
  max-height: 100%;
}

.ExamResultContainer_resultOverviewLink__o22b8 {
  margin: 0 auto;
  padding-top: 20px;
  font-weight: 500;
  display: block;
  text-align: center;
}

.ExamResultContainer_resultPageContentItem__Ql_SI .text-center {
  color: var(--text-color);
}

@media (max-width: 1199px) {
  .ExamResultContainer_resultPage__2ebDJ {
    padding: 24px 0 0;
  }

  .ExamResultContainer_resultPageContent__3J8ym {
    flex-flow: row wrap;
    padding-right: 0;
  }

  .ExamResultContainer_resultPageContentItem__Ql_SI {
    width: 100%;
  }

  .ExamResultContainer_examButtonList__1aDPH {
    flex-wrap: wrap;
    max-width: 320px;
  }

  .ExamResultContainer_examButtonList__1aDPH button {
    margin-top: 10px;
    width: 100%;
    margin-right: 0 !important;
  }
}

@media (max-width: 600px) {
  .ExamResultContainer_resultPageRateContainer__24z1s {
    max-width: 440px;
  }

  .ExamResultContainer_examButtonList__1aDPH {
    max-width: 440px;
  }

  .ExamResultContainer_examButtonList__1aDPH button {
    max-width: 440px !important;
  }

  .ExamResultContainer_questionLinkLinkContainer__1wvcI {
    justify-content: center;
    max-height: unset;
  }
}

.Ads_ads__1ytNK {
  background-color: var(--gray-6);
}

.Ads_ads__1ytNK a {
  display: flex;
  justify-content: center;
}

.Ads_ads__1ytNK img {
  max-width: 100%;
  height: auto;
}

.Avatar_avatar__2zivk {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 112px;
  width: 112px;
  margin-right: 20px;
  background-color: var(--theme-primary-80);
  border-radius: 4px;
}

.Avatar_avatarImg__9ZerX {
  max-width: 100%;
  height: auto;
}

.Avatar_avatarText__2yH-x {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 60px;
  color: var(--additional-light-blue);
}

@media (max-width: 991px) {
  .Avatar_avatar__2zivk {
    width: 80px;
    height: 80px;
  }

  .Avatar_avatarText__2yH-x {
    font-size: 40px;
  }
}

.SchoolLogo_imageContainer__3EAEa {
  width: 60%;
  padding-right: 20px;
  min-height: 20px;
}

.InstructorCard_instructorInfo__3QdN1 {
  position: relative;
  background-color: var(--lavender-grey);
  border-radius: 10px;
  margin: 10% 0 10% auto;
  width: 100%;
  max-width: 500px;
  z-index: 5;
}

.InstructorCard_instructorContent__33M2v {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 40px 20px;
}

.InstructorCard_instructorSchoolInfo__5n97- {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.InstructorCard_instructorContact__x1R5j {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 40px;
  border-top: 1px solid var(--additional-gray-blue);
}

.InstructorCard_instructorContact__x1R5j:last-child,
.InstructorCard_instructorContact__x1R5j:nth-child(3) {
  display: none;
}

.InstructorCard_contactItem__5sZWB {
  flex-grow: 1;
  width: 50%;
}

.InstructorCard_userName__TV-Gh {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: var(--neutral-dark-blue);
}

.InstructorCard_userInfo__gAtkJ {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.InstructorCard_instructorLabel__229fP {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 42px;
  color: var(--neutral-gray-blue-50);
}

.InstructorCard_instructorValue__7ZbEJ {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.InstructorCard_instructorText__30BVt {
  margin-top: 65px;
}

@media (max-width: 991px) {
  .InstructorCard_instructorInfo__3QdN1 {
    margin-bottom: 0;
  }

  .InstructorCard_instructorContent__33M2v,
  .InstructorCard_instructorContact__x1R5j {
    padding: 20px;
  }

  .InstructorCard_userName__TV-Gh {
    font-size: 18px;
    line-height: 24px;
  }

  .InstructorCard_userInfo__gAtkJ {
    font-size: 14px;
    line-height: 22px;
  }

  .InstructorCard_instructorValue__7ZbEJ {
    font-size: 18px;
    line-height: 22px;
  }

  .InstructorCard_instructorContact__x1R5j:last-child,
  .InstructorCard_instructorContact__x1R5j:nth-child(3) {
    display: flex;
  }

  .InstructorCard_instructorContact__x1R5j:nth-child(2) {
    display: none;
  }

  .InstructorCard_instructorInfo__3QdN1::after {
    display: none;
  }
}

@media (max-width: 480px) {
  .InstructorCard_instructorContent__33M2v {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .InstructorCard_instructorContent__33M2v .schoolLogo {
    max-width: unset;
    width: 100%;
  }

  .InstructorCard_instructorSchoolInfo__5n97- {
    margin-top: 20px;
    width: 100%;
  }
}

.InstructorContainer_instructorInfo__214xP {
  background-color: var(--lavender-grey);
  border-radius: 10px;
  margin: 10% 0;
  width: 100%;
}

.InstructorContainer_instructorInfo__214xP:after {
  position: absolute;
  content: '';
  top: 0;
  left: 25%;
  width: 100%;
  height: 100%;
  max-width: 650px;
  background: url(/static/media/il-2.7e8393f5.svg) no-repeat 50% 50%;
  background-size: contain;
}

.InstructorContainer_instructorContent__3iWI5 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 40px 40px 20px;
}

.InstructorContainer_instructorSchoolInfo__2_r01 {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.InstructorContainer_instructorContact__1yHhy {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 40px;
  border-top: 1px solid var(--additional-gray-blue);
}

.InstructorContainer_instructorContact__1yHhy:last-child,
.InstructorContainer_instructorContact__1yHhy:nth-child(3) {
  display: none;
}

.InstructorContainer_contactItem__hoorR {
  max-width: calc(50% - 10px);
  width: 100%;
}

.InstructorContainer_userName__1EQZh {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
}

.InstructorContainer_userInfo__23P13 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.InstructorContainer_instructorLabel__RgQ3I {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.InstructorContainer_instructorValue__ZRBJD {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.InstructorContainer_instructorText__2-T-Y {
  margin-top: 65px;
}

.InstructorContainer_imgContainer__1UKRC {
  width: 100%;
  max-width: calc(50% - 10px);
  transition: 0.5s all;
}

.InstructorContainer_imgContainer__1UKRC img {
  height: 100%;
  width: auto;
  /*width: 100%;*/
  /*height: auto;*/
}

.dark-theme .InstructorContainer_imgContainer__1UKRC img {
  height: 776px;
}

@media (max-width: 1200px) {
  .InstructorContainer_imgContainer__1UKRC img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1450px) {
  .InstructorContainer_imgContainer__1UKRC img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .InstructorContainer_imgContainer__1UKRC {
    display: none;
  }

  .InstructorContainer_instructorInfo__214xP {
    margin-bottom: 0;
  }

  .InstructorContainer_instructorInfo__214xP:after {
    content: none;
  }

  .InstructorContainer_instructorContent__3iWI5,
  .InstructorContainer_instructorContact__1yHhy {
    padding: 20px;
  }

  .InstructorContainer_userName__1EQZh {
    font-size: 18px;
    line-height: 24px;
  }

  .InstructorContainer_userInfo__23P13 {
    font-size: 14px;
    line-height: 22px;
  }

  .InstructorContainer_instructorValue__ZRBJD {
    font-size: 18px;
    line-height: 22px;
  }

  .InstructorContainer_instructorContact__1yHhy:last-child,
  .InstructorContainer_instructorContact__1yHhy:nth-child(3) {
    display: flex;
  }

  .InstructorContainer_instructorContact__1yHhy:nth-child(2) {
    display: none;
  }
}

.BottomDetails_bottomDescription__3q3vR {
  -webkit-column-count: 3;
          column-count: 3;
  -webkit-column-width: 390px;
          column-width: 390px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

@media (max-width: 991px) {
  .BottomDetails_bottomDescription__3q3vR {
    -webkit-column-width: 320px;
            column-width: 320px;
  }
}

.BottomDetailsColumn_bottomDetailsColumn__dVDA5 {
  color: var(--neutral-gray-blue);
}

.ExamPageContainer_examPage__oKgmU {
  /*background: url('../../img/il-2.svg') no-repeat center center,*/
  /*  linear-gradient(359.1deg, var(--whiteColor) -14.55%, var(--whiteColor) 18.5%, rgba(255, 255, 255, 0) 56.75%);*/
  /*background-size: 40% auto;*/
}

.ExamPageContainer_pcInstructor__2k-GB,
.ExamPageContainer_mobileInstructor__2Q4on {
  max-width: calc(50% - 10px);
  width: 100%;
  margin: 0;
}

.ExamPageContainer_examPage__oKgmU .pageTitle {
  margin-bottom: 12px;
}

.ExamPageContainer_examDetails__36W3Y {
  background-color: var(--additional-gray-blue-20);
  padding: 54px 0 80px;
}

.ExamPageContainer_mobileInstructor__2Q4on {
  display: none;
}

.ExamPageContainer_pcInstructor__2k-GB {
  display: block;
}

@media (max-width: 991px) {
  .ExamPageContainer_examPage__oKgmU {
    padding-top: 24px;
    background: none;
  }

  .ExamPageContainer_pcInstructor__2k-GB {
    display: none;
  }

  .ExamPageContainer_mobileInstructor__2Q4on {
    display: block;
    margin-bottom: 40px;
    max-width: none;
  }

  .ExamPageContainer_examDetails__36W3Y {
    background-color: var(--whiteColor);
    padding: 40px 0 0;
  }
}

.ExamBgContainer_examBgContainer__3jEjJ {
  background: url(/static/media/examBg.6dee1690.svg) no-repeat 50% 50%;
  background-size: cover;
}

.ExamBgContainer_pcInstructor__1dLnK {
  max-width: calc(50% - 10px);
  width: 100%;
}

.ExamBgContainer_pcInstructor__1dLnK .ExamBgContainer_instructorInfo__zwiuV {
  margin-right: 0;
}

@media (max-width: 1450px) {
  .ExamBgContainer_pcInstructor__1dLnK > div {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .ExamBgContainer_pcInstructor__1dLnK {
    display: none;
  }

  .ExamBgContainer_examBgContainer__3jEjJ {
    background: none;
    margin-top: 0;
  }
}

.RulesGroupContent_rulesGroupContent__Ic4X6 {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .RulesGroupContent_rulesGroupContent__Ic4X6 {
    flex-wrap: wrap;
  }
}

.RulesGroupItem_rulesGroupItem__34fE_ {
  max-width: calc(50% - 10px);
  width: 100%;
  z-index: 5;
  /*margin-bottom: 100px;*/
}

.rulesGroupContent h1 {
  margin-top: 92px;
}

@media (max-width: 1199px) {
  .RulesGroupItem_rulesGroupItem__34fE_ {
    max-width: 720px;
    width: auto;
    margin: 0 auto;
    text-align: center;
  }

  .RulesGroupItem_rulesGroupItem__34fE_ > .RulesGroupItem_imgContainer__2AyY4,
  .RulesGroupItem_rulesGroupItem__34fE_:first-child {
    display: none;
  }
}

@media (max-width: 991px) {
  .RulesGroupItem_rulesGroupItem__34fE_ {
    max-width: none;
    width: auto;
    margin: 0 auto;
  }

  .RulesGroupItem_rulesGroupItem__34fE_ h1 {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .RulesGroupItem_rulesGroupItem__34fE_ h1 {
    text-align: left;
  }
}

.CategoryList_rulesList__1WQ6p {
  display: flex;
  margin: 20px 0 40px;
}

.CategoryList_rulesListItem__1E9Nu {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.CategoryList_rulesListItem__1E9Nu:not(:last-child) {
  margin-right: 26px;
}

.CategoryList_rulesListItemBg__2-A-i {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--whiteColor);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: var(--theme-primary-80);
  transition: all ease-in 0.3s;
}

.CategoryList_rulesListItemBg__2-A-i > i {
  font-size: 48px;
}

.CategoryList_rulesListItemText__1dosq {
  font-family: 'museo-slab', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.CategoryList_rulesListItemBadge__3wH8M {
  position: absolute;
  left: 50%;
  bottom: -8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-transform: uppercase;
  color: var(--whiteColor);
  background-color: var(--badge-color);
  text-align: center;
  padding: 8px;
  border-radius: 21px;
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.04px;
  width: 55px;
}

@media (max-width: 1199px) {
  .CategoryList_rulesList__1WQ6p {
    justify-content: center;
  }

  .CategoryList_rulesListItemBg__2-A-i {
    background-color: var(--additional-light-blue);
  }

  .CategoryList_rulesListItemBadge__3wH8M {
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .CategoryList_rulesListItemBg__2-A-i {
    width: 60px;
    height: 60px;
  }

  .CategoryList_rulesListItemBg__2-A-i > i {
    font-size: 36px;
  }
}

.ButtonIcon_rulesList__1byZO {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 42px;
}

.ButtonIcon_rulesListItem__n3byC {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ButtonIcon_rulesListItem__n3byC:not(:last-child) {
  margin-right: 35px;
}

.ButtonIcon_rulesListItemBg__3WEO7 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-icon-enabled);
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 50%;
  color: var(--theme-primary-80);
  min-width: auto;
  border-width: 0;
  transition: all ease 0.3s;
}

.ButtonIcon_rulesListItemBg__3WEO7:hover {
  background-color: var(--theme-primary);
  color: var(--whiteColor);
}

.ButtonIcon_rulesListItemText__kc59a {
  font-family: 'museo-slab', sans-serif;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
  color: var(--text-color);
  margin-top: 8px;
}

.ButtonIcon_rulesListItemBg__3WEO7.ButtonIcon_active__1MEEj {
  background-color: var(--theme-primary);
  color: var(--whiteColor);
}

@media (max-width: 1300px) {
  .ButtonIcon_rulesListItem__n3byC:not(:last-child) {
    margin-right: 15px;
  }
}

.BtnList_btnList__1k6fn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.BtnList_btnList__1k6fn > .btn:first-child {
  margin-right: 20px;
}

.dark-theme .BtnList_btnList__1k6fn .btn.btn-primary:hover {
  background-color: var(--theme-accent);
  color: var(--not-changine-black);
}

@media (max-width: 1199px) {
  .promoItem .btnList {
    justify-content: center;
  }

  .BtnList_btnList__1k6fn {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .BtnList_btnList__1k6fn {
    flex-wrap: wrap;
  }

  .BtnList_btnList__1k6fn > button,
  .BtnList_btnList__1k6fn > .btn {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .BtnList_btnList__1k6fn > .btn:first-child {
    margin-right: 0;
  }
}

.QuestionBtn_buttonDisable__1OdF5 {
  pointer-events: none;
}

.QuestionBtn_learnButtonLinkColor__bx3CT,
.QuestionBtn_learnButtonLinkColor__bx3CT:hover {
  color: var(--not-changine-white);
}

.ExamInfo_examInfoCard__XjfTt {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background-color: var(--lavender-grey);
  border-radius: 6px;
  padding: 24px 24px 8px;
  max-width: 346px;
  width: 100%;
}

.ExamInfo_examCardItem__3C2Oe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(50% - 10px);
  width: 100%;
  margin-bottom: 12px;
}

.ExamInfo_cardLabel__unsUk {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.ExamInfo_cardValue__35pRX {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.ExamInfo_examPage__15BN3 .ExamInfo_rulesList__oy9Pn {
  margin: 20px 0 44px;
}

.PageText_pageText__1yEAv {
  font-family: 'museo-slab', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .PageText_pageText__1yEAv {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .PageText_pageText__1yEAv {
    text-align: left;
  }
}

.PageText_p3__36Hl- {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.PageDescription_pageDescription__3aTVm {
  max-width: 310px;
  width: 100%;
}

.CommonTable_tableContainer__3Yhti th,
.CommonTable_tableContainer__3Yhti td {
  text-align: center;
}

.CommonTable_tableContainer__3Yhti {
  color: var(--text-color);
}

.CommonTable_loaderWrapper__1n-h_ {
  width: 100%;
  min-width: 100%;
  position: absolute;
  min-height: 180px;
}

.CommonTable_noResultContainer__1G4ql {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
}

.CommonTable_noResultContainer__1G4ql p {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue);
}

body .ExamHistoryTable_progressBarContainer__3HlAh {
  min-width: 200px;
  position: relative;
  padding: 18px 0;
}

.ExamHistoryTable_progressBar__fjNUf.progress {
  max-width: unset;
}

.ExamHistoryTable_progressBar__fjNUf .progress-bar {
  background-color: var(--functional-warning-bold);
}

body .ExamHistoryTable_isExamPassed__3Ctn9 .progress-bar {
  background-color: var(--functional-success);
}

.ExamHistoryTable_progressBarContainer__3HlAh .progress-bar:after {
  content: '';
  position: absolute;
  right: 10%;
  height: 25px;
  width: 5px;
  background-color: var(--theme-primary);
}

.LoaderWrapper_loader__39mJO {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  transition: all 0.3s ease;
}

.LoaderWrapper_showLoader__3rqR- {
  display: flex;
}

.LoaderWrapper_loader__39mJO > i {
  font-size: 100px;
  width: 100px;
  height: 100px;
  color: var(--button-default-enabled);
  text-align: center;
  -webkit-animation: LoaderWrapper_rotate__1Tvf4 2s infinite;
          animation: LoaderWrapper_rotate__1Tvf4 2s infinite;
}

@-webkit-keyframes LoaderWrapper_rotate__1Tvf4 {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes LoaderWrapper_rotate__1Tvf4 {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.ExamResultHistoryContainer_container__2ywud {
  padding: 40px 0 180px 0;
  overflow: hidden;
}

.ExamResultHistoryContainer_loaderWrapper__3BGBq {
  height: 150px;
}

.ExamResultHistoryContainer_bestScopeContainer__2_cyh {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.ExamResultHistoryContainer_bestScopeContainer__2_cyh .jumbotron {
  margin-right: 0;
}

.ExamResultHistoryContainer_scoreText__1lCOh {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-left: 10px;
}

.ExamResultHistoryContainer_scoreCategory__1R1ix {
  font-family: 'Ubuntu', sans-serif;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  color: var(--neutral-dark-blue);
}

.ExamResultHistoryContainer_backButtonWrapper__2GmL_ {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

@media (max-width: 991px) {
  .ExamResultHistoryContainer_container__2ywud {
    padding: 40px 0 40px 0;
  }

  .ExamResultHistoryContainer_scoreContainer__2QO9S {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }

  .ExamResultHistoryContainer_scoreContainer__2QO9S .jumbotron {
    margin: 0;
  }
}

@media (max-width: 550px) {
  .ExamResultHistoryContainer_container__2ywud table {
    min-width: 600px;
  }
}

.Label_scoreTitle__IDKOM {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue);
}

.ScoreContainer_scoreContent__2CKmr {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin-bottom: 14px;
}
.ScoreContainer_scoreValue__28ECd {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 60px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-bottom: 14px;
}

.ScoreContainer_loaderWrapper__2bBIT {
  height: 150px;
}

.ScoreCard_scoreContainer__ivEAg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jumbotron {
  padding: 24px 20px 18px;
  background-color: var(--lavender-grey);
  margin-right: 0;
}

.TheoryCard_theoryCard__3KzCG {
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.topicListLogged .jumbotron {
  max-width: 350px;
  background-color: var(--whiteColor);
}

.topicListNotLogged .jumbotron {
  max-width: 350px;
  background-color: var(--lavender-grey);
}

.TheoryCard_theoryCardTitle__24Vu7 {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.TheoryCard_theoryCardText__3T8bs {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  margin-bottom: 28px;
}

.TheoryCard_linkBtn__1AVOY {
  font-family: 'museo-slab', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  padding: 0;
  border: none;
  transition: all ease 0.3s;
}

.TheoryCard_linkBtn__1AVOY i {
  font-size: 14px;
  color: currentColor;
}

.TheoryCard_linkBtn__1AVOY:hover,
.TheoryCard_linkBtn__1AVOY:focus {
  text-decoration: none;
}

@media (max-width: 991px) {
  .topicListLogged .jumbotron {
    background-color: var(--neutral-light-gray);
    margin-bottom: 20px;
  }
}

.TheoryTopic_topicListItem__2GDVD {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.TheoryTopic_topicListItem__2GDVD i {
  display: none;
}

.TheoryTopic_topicListItem__2GDVD .TheoryTopic_topicsListItemBtn__2cuUV {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
  padding: 16px 30px 16px 16px;
  border: none;
  text-align: left;
  white-space: nowrap;
}

.TheoryTopic_topicListItem__2GDVD .TheoryTopic_topicsListItemBtn__2cuUV:hover,
.TheoryTopic_topicListItem__2GDVD .TheoryTopic_topicsListItemBtn__2cuUV:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

.TheoryModalNotLogged_modalLg__1xoYh {
  position: relative;
  max-width: 720px;
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-content {
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-body,
.TheoryModalNotLogged_modalLg__1xoYh .modal-header {
  padding: 0;
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-header button.close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 200;
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-header button.close:before {
  color: var(--whiteColor);
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-header button.close > span {
  display: none;
}

.TheoryModalNotLogged_modalContent__NhSMr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.TheoryModalNotLogged_modalImg__2L9AY {
  max-width: 100%;
  height: auto;
  border-radius: 0 6px 6px 0;
}

.TheoryModalNotLogged_modalContentText__3uplY {
  padding: 0 22px 0 36px;
}

.TheoryModalNotLogged_modalLg__1xoYh .modal-title {
  margin-bottom: 10px;
}

.TheoryModalNotLogged_modalText__3_HYP {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--not-changine-black);
  margin-bottom: 26px;
}

@media (max-width: 991px) {
  .TheoryModalNotLogged_modalLg__1xoYh {
    max-width: 350px;
  }

  .TheoryModalNotLogged_modalContent__NhSMr {
    flex-flow: row wrap-reverse;
  }

  .TheoryModalNotLogged_modalContentText__3uplY {
    padding: 26px 0 0;
  }

  .TheoryModalNotLogged_modalLg__1xoYh .modal-title {
    margin: 0 20px 16px;
  }

  .TheoryModalNotLogged_modalText__3_HYP {
    margin: 0 20px 26px;
  }

  .TheoryModalNotLogged_modalImg__2L9AY {
    border-radius: 6px 6px 0 0;
  }

  .TheoryModalNotLogged_modalBtn__1Vvab {
    max-width: none;
    width: 100%;
  }
}

.topicListLogged .TheoryByTopicContainer_topicList__1XMC2 {
  background-color: var(--neutral-light-gray);
}

.topicListNotLogged .TheoryByTopicContainer_topicList__1XMC2 {
  background-color: var(--whiteColor);
}

.TheoryByTopicContainer_topicList__1XMC2 {
  padding: 80px 0 20px;
}

.jumbotron {
  max-width: 350px;
  width: 100%;
  margin-right: 177px;
  border-radius: 10px;
}

.TheoryByTopicContainer_topicListContent__2JUn9 {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.TheoryByTopicContainer_theoryTopicList__1OeVO {
  -webkit-column-count: 2;
          column-count: 2;
  -webkit-column-width: 250px;
          column-width: 250px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.TheoryByTopicContainer_bookMarkContainer__15yQQ {
  display: none;
}

@media (max-width: 991px) {
  .TheoryByTopicContainer_topicListContent__2JUn9 {
    flex-wrap: wrap;
  }

  .topicListLogged .TheoryByTopicContainer_topicList__1XMC2 {
    background-color: var(--whiteColor);
  }

  .TheoryByTopicContainer_theoryTopicList__1OeVO {
    -webkit-column-count: 1;
            column-count: 1;
    -webkit-column-width: auto;
            column-width: auto;
    grid-column-gap: inherit;
    -webkit-column-gap: inherit;
            column-gap: inherit;
    order: 1;
    padding-bottom: 20px;
  }

  .TheoryByTopicContainer_bookMarkContainer__15yQQ {
    display: block;
    order: 2;
    width: 100%;
  }

  .TheoryByTopicContainer_bookMarkText__3upx6 {
    font-family: 'Hind', sans-serif;
    max-width: 350px;
    font-size: 15px;
    line-height: 24px;
    padding-left: 10px;
    text-align: left;
    margin: 0 0 40px 0;
    color: var(--text-color);
  }

  .jumbotron {
    order: 3;
    margin-bottom: 0;
  }

  .TheoryByTopicContainer_topicList__1XMC2 {
    padding: 40px 0 20px;
  }
}

@media (max-width: 767px) {
  .jumbotron {
    margin-right: 0;
  }
}

.CategoryIcon_rulesListItem__3Dk0e {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.CategoryIcon_rulesListItem__3Dk0e:not(:last-child) {
  margin-right: 26px;
}

.CategoryIcon_rulesListItemBg__1wVri {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--additional-light-blue);
  color: var(--theme-primary-80);
  transition: all ease-in 0.3s;
}

.dark-theme .CategoryIcon_rulesListItemBg__1wVri {
  background-color: var(--neutral-light-gray);
  border: 1px solid var(--not-changine-white);
}

.categoryIconBarHome .CategoryIcon_rulesListItemBg__1wVri {
  background-color: var(--whiteColor);
}

.CategoryIcon_rulesListItemBg__1wVri > i {
  font-size: 48px;
}

.CategoryIcon_rulesListItemText__3b1Lj {
  font-family: 'museo-slab', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.CategoryIcon_rulesListItemBadge__1f_UN {
  position: absolute;
  left: 50%;
  bottom: -8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-transform: uppercase;
  color: var(--whiteColor);
  background-color: var(--badge-color);
  text-align: center;
  padding: 8px;
  border-radius: 21px;
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.04px;
  width: 55px;
}

@media (max-width: 1199px) {
  .CategoryIcon_rulesListItemBg__1wVri,
  .categoryIconBarHome .CategoryIcon_rulesListItemBg__1wVri {
    background-color: var(--additional-light-blue);
  }

  .categoryIconBarHome .CategoryIcon_rulesListItemText__3b1Lj {
    color: var(--text-color);
  }

  .CategoryIcon_rulesListItemBadge__1f_UN {
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .CategoryIcon_rulesListItemBg__1wVri {
    width: 60px;
    height: 60px;
  }

  .CategoryIcon_rulesListItemBg__1wVri > i {
    font-size: 36px;
  }
}

@media (max-width: 600px) {
  .CategoryIcon_rulesListItem__3Dk0e:not(:last-child) {
    margin-right: 16px;
  }
}

.notLoggedPageText .TheoryContainer_theoryPageText__2GbY_,
.loggedPageText .TheoryContainer_theoryPageText__2GbY_ {
  margin-bottom: 112px;
  max-width: 310px;
  width: 100%;
}

.TheoryContainer_theoryPageText__2GbY_ h1 {
  margin-bottom: 17px;
}

.TheoryContainer_rulesList__31L6W {
  display: flex;
  margin: 20px 0 40px;
}

.TheoryContainer_rulesListItem__NX40f {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.TheoryContainer_rulesListItem__NX40f:not(:last-child) {
  margin-right: 26px;
}

.rulesListItemBg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: var(--theme-primary-80);
  transition: all ease-in 0.3s;
}

.TheoryContainer_rulesListItemBg__3ptmX > i {
  font-size: 48px;
}

.TheoryContainer_rulesListItemText__1Oqjj {
  font-family: 'museo-slab', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.TheoryContainer_rulesListItemBadge__3SgLN {
  position: absolute;
  left: 50%;
  bottom: -8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-transform: uppercase;
  color: var(--whiteColor);
  background-color: var(--badge-color);
  text-align: center;
  padding: 8px;
  border-radius: 21px;
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.04px;
  width: 55px;
}

.TheoryContainer_linkBtn__17cKp {
  display: flex;
  align-items: center;
  font-family: 'museo-slab', sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: var(--theme-primary);
}

.TheoryContainer_linkBtn__17cKp > .TheoryContainer_icon-arrow__eztGK {
  color: currentColor;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 2px;
}

.TheoryContainer_bookMarkText__2qXa7 {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  max-width: 470px;
  padding-left: 10px;
  text-align: left;
  color: var(--text-color);
}

@media (max-width: 1199px) {
  .TheoryContainer_bookMarkText__2qXa7 {
    margin-bottom: 40px;
  }

  .TheoryContainer_rulesList__31L6W {
    margin: 0 90px 20px 0;
  }

  .TheoryContainer_rulesList__31L6W {
    justify-content: center;
  }

  .TheoryContainer_rulesListItemBg__3ptmX {
    background-color: var(--additional-light-blue);
  }

  .TheoryContainer_rulesListItemBadge__3SgLN {
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .TheoryContainer_bookMarkText__2qXa7 {
    display: none;
  }

  .TheoryContainer_rulesList__31L6W {
    margin: 20px 0 40px;
  }

  .TheoryContainer_rulesListItemBg__3ptmX {
    width: 60px;
    height: 60px;
  }

  .TheoryContainer_rulesListItemBg__3ptmX > i {
    font-size: 36px;
  }

  .theoryBtn {
    max-width: 350px;
    width: 100%;
  }

  .notLoggedPageText .TheoryContainer_theoryPageText__2GbY_ {
    margin-bottom: 302px;
  }

  .loggedPageText.TheoryContainer_theoryPageText__2GbY_ {
    margin-bottom: 52px;
  }
}

.TheoryPage_mobileView__3rhbC {
  display: none;
}

.TheoryPage_imageBox__37u_T {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 1450px) {
  .TheoryPage_imageBox__37u_T > div {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .TheoryPage_theoryPageTopContent__3muLj {
    padding: 24px 0 40px;
  }

  .TheoryPage_mobileView__3rhbC {
    display: block;
  }
}

.ImprintPage_imprintPage__3Nzke {
  padding: 60px 0 120px;
}

.ImprintPage_imprintList__3ydvh {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 28px;
}

.ImprintPage_imprintPage__3Nzke p {
  margin-bottom: 12px;
  max-width: 400px;
}

@media (max-width: 991px) {
  .ImprintPage_imprintPage__3Nzke {
    padding: 24px 0 0;
  }

  .ImprintPage_imprintPage__3Nzke h1 {
    text-align: left;
  }

  .ImprintPage_imprintList__3ydvh {
    margin-bottom: 28px;
  }
}

.CollapseItem_root__3jezy {
  position: relative;
  margin-right: 17px;
  color: var(--theme-primary);
  padding-left: 30px;
  margin-bottom: 16px;
  border: none;
  background: var(--whiteColor);
  text-align: inherit;
  min-height: 75px;
}

.CollapseItem_root__3jezy:before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
  width: 14px;
  height: 14px;
  font-family: 'icomoon', 'serif';
  font-size: 12px;
  transition: all ease 0.3s;
  line-height: 1;
}

.CollapseItem_open__3pC_8.CollapseItem_root__3jezy:before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.CollapseItem_root__3jezy:hover {
  cursor: pointer;
}

.CollapseItem_faqItem__2jTsj {
  margin-bottom: 21px;
  width: 100%;
  overflow: hidden;
}

.CollapseItem_faqHeader__2ZkwP {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-bottom: 7px;
}

.CollapseItem_faqText__2mxLe {
  display: block;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  padding-left: 30px;
  text-transform: none;
}

.CollapseItem_faqItem__2jTsj .collapse:not(.show) {
  display: inherit;
  height: 0;
  transition: all ease 0.4s;
}

.CollapseItem_faqItem__2jTsj .collapse.show {
  height: auto;
}

@media (max-width: 991px) {
  .CollapseItem_questionsByTopicList__1cCug {
    -webkit-column-width: 320px;
            column-width: 320px;
  }

  .CollapseItem_root__3jezy {
    min-height: unset;
  }
}

.TermPage_termPage__39Gc4 {
  padding: 60px 0;
}

.TermPage_termPageContent__3msWJ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.TermPage_termPageContentMobile__1ddGh {
  display: none;
}

.TermPage_termPageContentMobile__1ddGh .faqHeader {
  text-transform: lowercase;
}

.TermPage_termPageContentMobile__1ddGh .collapsibleItem:first-letter {
  text-transform: uppercase;
}

.TermPage_termPage__39Gc4 .list-group {
  min-width: 280px;
  margin-right: 33px;
  flex-grow: 1;
}

.TermPage_termPage__39Gc4 .list-group-item {
  border: none;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  padding: 5px 0;
  margin-bottom: 18px;
}

.dark-theme .TermPage_termPage__39Gc4 .list-group-item {
  color: var(--text-color);
  background-color: var(--whiteColor);
}

.TermPage_termPage__39Gc4 .list-group-item:hover {
  color: var(--theme-primary);
  cursor: pointer;
}

.TermPage_termPageText__1JBgt {
  -webkit-column-count: 2;
          column-count: 2;
  -webkit-column-width: 350px;
          column-width: 350px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.TermPage_mobileText__2VYPH {
  display: none;
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .TermPage_mobileText__2VYPH {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .TermPage_termPage__39Gc4 {
    padding: 0;
  }

  .TermPage_termPageContent__3msWJ {
    display: none;
  }

  .TermPage_termPageContentMobile__1ddGh {
    display: block;
  }
}

.AsaPage_asaPage__ArP-s {
  padding: 40px 0 150px;
}

.AsaPage_asaPageTitle__UhNk- {
  margin-bottom: 32px;
}

.AsaPage_asaPageContent__DOqLc {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.AsaPage_pageText__A7wVd {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.AsaPage_asaPageItem__3tk3w {
  max-width: 33%;
  width: 100%;
}

.AsaPage_asaPageItem__3tk3w:not(last-child) {
  margin-right: 18px;
}

@media (max-width: 991px) {
  .AsaPage_asaPage__ArP-s {
    padding: 0;
  }

  .AsaPage_asaPageContent__DOqLc {
    flex-flow: row wrap;
  }

  .AsaPage_asaPageItem__3tk3w {
    max-width: none;
    width: 100%;
  }
}

.ModalByTopic_modalTopic__1HbfW .modal-body {
  padding: 0 44px 28px;
}

.ModalByTopic_modalTopic__1HbfW .modal-header {
  padding: 40px 44px 70px;
}

.ModalByTopic_modalTopic__1HbfW .modal-title {
  font-family: 'museo-slab', sans-serif;
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.ModalByTopic_modalTopic__1HbfW .modal-content {
  background-color: var(--whiteColor);
}

.ModalByTopic_topicListLink__3TzcG {
  display: block;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 32px;
}

.ModalByTopic_topicList__kVEPB {
  -webkit-column-count: 3;
          column-count: 3;
  -webkit-column-width: 200px;
          column-width: 200px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

@media (max-width: 991px) {
  .ModalByTopic_modalTopic__1HbfW {
    max-width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    border-radius: 6px;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-body {
    padding: 0 20px;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-dialog {
    height: 100%;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-header {
    padding: 20px;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-body {
    padding: 0 20px;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-title {
    font-family: 'museo-slab', sans-serif;
    font-size: 26px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: var(--neutral-dark-blue);
    margin-top: 0;
  }

  .ModalByTopic_modalTopic__1HbfW .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.QuestionCardContainer_questionPage__1sn0Z {
  background-color: var(--lavender-grey);
}

.QuestionCardContainer_questionLinkBottom__2KJR9 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 88px;
  padding-bottom: 30px;
}

.QuestionCardContainer_loaderContainer__2Zvg- {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

.QuestionCardContainer_questionBar__3Cbly {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.QuestionCardContainer_questionBtnListItem__11xdm {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--theme-primary);
  border: none;
  padding: 0;
}

.QuestionCardContainer_emptyMarkedQuestionsContainer__D7KAJ {
  padding: 25px 10px;
  color: var(--text-color);
}

.QuestionCardContainer_emptyMarkedQuestionsTitle__Rm-iK {
  font-size: 24px;
  font-weight: 700;
}

.QuestionCardContainer_emptyMarkedQuestionsDescriptionWrapper__3MWC1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.QuestionCardContainer_emptyMarkedQuestionsDescriptionWrapper__3MWC1 p {
  margin: 0 15px 0 0;
  font-size: 18px;
}

.QuestionCardContainer_doubleCounter__1ZQbe {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 6px;
  margin: 10px 32px 10px 0;
  border: 1px solid var(--additional-gray-blue);
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--neutral-gray-blue);
}

.QuestionCardContainer_headerQuestionBox__1S5uo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.QuestionCardContainer_questionCustomBtn__1Hu1p {
  max-width: 350px;
  width: 100%;
  border: 1px solid var(--additional-gray-blue);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: var(--whiteColor);
}

.QuestionCardContainer_questionCustomBtn__1Hu1p button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  padding: 8px 16px;
  border: none;
}

.QuestionCardContainer_questionCustomBtn__1Hu1p button:hover,
.QuestionCardContainer_questionCustomBtn__1Hu1p button:focus {
  text-decoration: none;
}

.QuestionCardContainer_emptyQuestionContainer__3PwTd {
  padding-top: 18px;
}

.QuestionCardContainer_questionCustomBtn__1Hu1p i {
  font-size: 16px;
  color: var(--theme-primary);
}

.QuestionCardContainer_mobileTopic__3Cqum {
  display: none;
}

.QuestionCardContainer_theoryMobile__2CCV1 {
  display: none;
  margin-bottom: 40px;
  margin-top: -48px;
}

.QuestionCardContainer_theoryMobileFooter__32HsD {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .QuestionCardContainer_questionPage__1sn0Z {
    background-color: var(--whiteColor);
  }

  .QuestionCardContainer_questionLinkBottom__2KJR9 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .QuestionCardContainer_emptyQuestionContainer__3PwTd {
    padding-top: unset;
  }
}

@media (max-width: 767px) {
  .QuestionCardContainer_headerQuestionBox__1S5uo .QuestionCardContainer_questionCustomBtn__1Hu1p {
    display: none;
  }

  .QuestionCardContainer_mobileTopic__3Cqum {
    display: block;
  }

  .QuestionCardContainer_theoryMobile__2CCV1 {
    display: block;
    max-width: none;
    margin-top: 30px;
  }
}

@media (max-width: 500px) {
  .QuestionCardContainer_emptyMarkedQuestionsTitle__Rm-iK {
    font-size: 18px;
    font-weight: 700;
  }

  .QuestionCardContainer_emptyMarkedQuestionsDescriptionWrapper__3MWC1 {
    flex-direction: column;
    align-items: flex-start;
  }

  .QuestionCardContainer_emptyMarkedQuestionsDescriptionWrapper__3MWC1 p {
    font-size: 16px;
    margin: 0 0 25px 0;
  }

  .QuestionCardContainer_emptyMarkedQuestionsDescriptionWrapper__3MWC1 button {
    align-self: center;
  }
}

@media (max-width: 430px) {
  .QuestionCardContainer_questionLinkBottom__2KJR9 button,
  .QuestionCardContainer_questionLinkBottom__2KJR9 a {
    padding: 5px 10px;
    font-size: 15px;
  }
}

.TopicProgress_progressContent__1XTaE {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.TopicProgress_progressContent__1XTaE:hover {
  cursor: pointer;
}

.TopicProgress_status__3u3jN {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
}

.TopicProgress_bad__8prnQ {
  background-color: var(--functional-warning);
}

.TopicProgress_good__3-04Y {
  background-color: var(--functional-success);
}

.TopicProgress_middle__2jP1C {
  background-color: var(--button-primary-pressed);
}

.TopicProgress_empty__1xHth {
  background-color: var(--additional-gray-blue);
}

.progress {
  max-width: 80px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--additional-gray-blue);
}

.TopicProgress_incorrectCountText__o3hcA {
  margin: 0;
  width: 100px;
  font-size: 18px;
  font-weight: bold;
}

.ViewTypeButton_buttonContainer__3z2LJ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background-color: var(--white);
  border: 1px solid var(--additional-blue);
  transition: all ease 0.3s;
  color: var(--neutral-dark-blue);
}

.ViewTypeButton_buttonContainer__3z2LJ:hover {
  border-color: var(--theme-primary);
  background-color: var(--button-outline-focused);
  width: 42px;
  height: 42px;
}

.CardWrapper_cardItem__zTZ4l {
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 20px var(--box-shadow);
  border-radius: 6px;
  width: 100%;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-width: 300px;
  color: var(--text-color);
}

.dark-theme .CardWrapper_cardItem__zTZ4l {
  background-color: var(--neutral-light-gray-no-opacity);
}

.ProgressPieChart_container__3HkRi {
  position: relative;
}

.ProgressPieChart_chartContainer__18WPd {
  margin-right: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  margin-top: -12px;
}

.ProgressPieChart_chartContainer__18WPd .recharts-default-legend {
  text-align: left !important;
}

.ProgressPieChart_percentInCenter__1doO3 {
  position: absolute;
  /*TODO fix in future*/
  left: 52%;
  top: 46%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 16px;
}

@media (max-width: 1199px) {
  .ProgressPieChart_chartContainer__18WPd {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    margin-top: -15px;
  }

  .ProgressPieChart_percentInCenter__1doO3 {
    left: 50%;
    top: 50%;
  }
}

.YourProgressSection_container__3bkIk {
  display: flex;
  align-items: center;
  grid-gap: 0 20px;
  gap: 0 20px;
}

.dark-theme .YourProgressSection_container__3bkIk #gauge-chart .text-group text {
  fill: var(--not-changine-white) !important;
}

.YourProgressSection_container__3bkIk > div {
  min-width: 200px;
}

.YourProgressSection_cardContainer__3oafG {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: 200px;
  max-width: 290px;
  max-height: 200px;
  position: relative;
}

.YourProgressSection_cardMarkedIcon__wbIG4 {
  font-size: 105px;
  margin-top: -10px;
  color: var(--button-primary-pressed);
  transition: 3s all;
}

.YourProgressSection_cardCenterIcon__3qmnZ {
  margin: 0 auto;
  transition: 3s all;
}

.YourProgressSection_resetIcon__2GBFg {
  margin: 0 auto;
}

.YourProgressSection_resetIcon__2GBFg path {
  stroke: #0080c9;
}

.YourProgressSection_cardTitleContainer__1aDlG {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 35px;
}

.YourProgressSection_cardTitleContainer__1aDlG h6 {
  white-space: nowrap;
  font-size: 18px;
}

.YourProgressSection_badge__23rjH {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 30px;
  padding-right: 15px;
  padding-left: 15px;
  color: var(--white);
}

.YourProgressSection_badgeWarning__3l41Z {
  background-color: var(--button-primary-pressed);
}

.YourProgressSection_badgeError__3_aic {
  background-color: rgb(224, 61, 36);
}

@media (max-width: 1199px) {
  .YourProgressSection_container__3bkIk {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px 20px;
    gap: 20px 20px;
  }
}

@media (max-width: 991px) {
  .YourProgressSection_container__3bkIk {
    margin-top: 50px;
    grid-gap: 20px 20px;
    gap: 20px 20px;
  }
}

@media (max-width: 767px) {
  .YourProgressSection_badge__23rjH {
    font-size: 20px;
  }

  .YourProgressSection_container__3bkIk {
    grid-gap: 25px 25px;
    gap: 25px 25px;
  }
}

.TopicSection_container__2LH97 {
  padding: 40px 0;
  display: flex;
  align-items: center;
}

.TopicSection_bannerContainer__HWL2s {
  display: flex;
  margin-top: 0;
  border-radius: 0.5rem;
  /*-webkit-box-shadow: 0 0 0.625rem -0.125rem #d6dee6;*/
  /*box-shadow: 0 0 0.625rem -0.125rem #d6dee6;*/
  /*background-color: var(--button-default-focused);*/
  padding: 40px 0 20px 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .TopicSection_container__2LH97 h1 {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .TopicSection_container__2LH97 h1 {
    text-align: left;
    max-width: 310px;
    margin: 0 auto;
  }
}

.TopicItem_container__3SHc0 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0.625rem -0.125rem var(--box-shadow);
  margin: 20px 0;
  width: 100%;
}

.accordionGrid .TopicItem_container__3SHc0 {
  max-width: 49%;
}

body .accordionGrid .TopicItem_isSelected__LSvRx {
  width: 100%;
  max-width: 100%;
}

.dark-theme .TopicItem_container__3SHc0 {
  background-color: var(--neutral-light-gray-no-opacity);
}

.TopicItem_toggleButton__2lfMF {
  border-radius: 15px;
  background-color: #fff;
  width: 100%;
  padding: 0;
  cursor: pointer;
  z-index: 999;
}

.TopicItem_toggleButton__2lfMF:hover {
  cursor: pointer;
}

.dark-theme .TopicItem_toggleButton__2lfMF {
  background-color: var(--neutral-light-gray-no-opacity);
}

.TopicItem_header__RKIO2 {
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  pointer-events: none;
  box-shadow: 0 0.125rem 0.5625rem -0.3125rem #d6dee6;
  color: var(--text-color);
  width: 100%;
}

.TopicItem_headerIcon___ImV_ {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  border: 1px solid var(--theme-primary-80);
  display: flex;
  justify-content: center;
  align-items: center;
}

.TopicItem_headerText__iM8wx {
  padding: 0 20px;
  display: flex;
  font-size: 20px;
  flex: 1 1;
}

.TopicItem_headerPercent__3u7gj {
  display: flex;
  padding: 0 20px;
  flex: 1 1;
  max-width: 222px;
  width: 100%;
}

.TopicItem_percentWrapper__3aryF {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.TopicItem_percentWrapper__3aryF .progress {
  width: 100%;
  max-width: 120px;
}

.TopicItem_percentWrapper__3aryF p {
  margin: 0 0 0 10px;
  font-size: 18px;
  white-space: nowrap;
}

.TopicItem_body__1Fg5D {
  padding: 15px;
  display: none;
}

.TopicItem_isOpen__3M7mi {
  opacity: 1;
  display: block;
}

.TopicItem_container__3SHc0 .collapsing {
  opacity: 1;
  overflow: hidden;
  transition: all 0s;
}

.TopicItem_status__jZ0pM {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
}

.TopicItem_container__3SHc0 .TopicItem_bad__2z-0J .progress-bar {
  background-color: var(--functional-warning);
}

.TopicItem_container__3SHc0 .TopicItem_good__334vE .progress-bar {
  background-color: var(--functional-success);
}

.TopicItem_container__3SHc0 .TopicItem_middle__1aIiH .progress-bar {
  background-color: var(--button-primary-pressed);
}

.TopicItem_container__3SHc0 .TopicItem_empty__1_N2s .progress-bar {
  background-color: var(--additional-gray-blue);
}

.TopicItem_footer__3yCVL {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.TopicItem_footer__3yCVL button {
  margin: 0 10px;
}

@media (max-width: 800px) {
  .TopicItem_percentWrapper__3aryF .progress {
    width: 100%;
    max-width: 90px;
  }

  .TopicItem_percentWrapper__3aryF {
    justify-content: flex-end;
  }
}

@media (max-width: 550px) {
  .TopicItem_header__RKIO2 {
    padding: 15px 0 15px 15px;
  }

  .TopicItem_headerPercent__3u7gj {
    padding: 0 10px;
    max-width: 222px;
  }

  .TopicItem_headerText__iM8wx {
    padding: 0 0 0 15px;
    font-size: 14px;
    text-align: left;
  }
}

@media (max-width: 450px) {
  .TopicItem_percentWrapper__3aryF .progress {
    max-width: unset;
  }
}

.QuestionsByTopicContainer_rulesGroupItem__30das {
  max-width: 50%;
  width: 100%;
}

.QuestionsByTopicContainer_questionsByTopic__2hQWx {
  padding-top: 80px;
  padding-bottom: 80px;
}

.QuestionsByTopicContainer_questionsByTopic__2hQWx .QuestionsByTopicContainer_pageTitle__Xfrz1 {
  padding-bottom: 70px;
}

.QuestionsByTopicContainer_questionsByTopicTitle__2dPqm {
  color: var(--neutral-dark-blue);
}

.QuestionsByTopicContainer_questionsByTopicList__2gnge {
  position: relative;
  padding: 0 0 80px;
  transition: all ease 0.7s;
}

.QuestionsByTopicContainer_questionsTopicText__33O10 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.QuestionsByTopicContainer_questionsTopicText__33O10 p {
  max-width: calc(100% / 3);
  width: 100%;
}

.QuestionsByTopicContainer_questionsTopicText__33O10 p:not(:last-child) {
  margin-right: 20px;
}

.QuestionsByTopicContainer_questionsByTopicList__2gnge.QuestionsByTopicContainer_mobileMode__2pLak::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 180px;
  background: linear-gradient(180deg, var(--whiteColor) 21.87%, rgba(255, 255, 255, 0) 100%);
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  transition: all ease 1s;
}

.QuestionsByTopicContainer_questionsByTopicList__2gnge.QuestionsByTopicContainer_mobileMode__2pLak.QuestionsByTopicContainer_expanded__ucwGG::after {
  content: none;
  height: 0;
}

.QuestionsByTopicContainer_extendControlContainer__IyGga {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}

.QuestionsByTopicContainer_extendControlContainer__IyGga button {
  padding: 0;
}

.QuestionsByTopicContainer_collapseWrapper__1b-4_ {
  -webkit-columns: auto 2;
          columns: auto 2;
  max-width: 70%;
}

.QuestionsByTopicContainer_expandBtn__22a4p {
  display: inline-flex;
  align-items: center;
  color: var(--neutral-dark-blue);
  text-decoration: none !important;
}

.QuestionsByTopicContainer_expandStatusIcon__3mRH2::after {
  display: block;
  margin-left: 13px;
  content: '\e918';
  font-family: 'icomoon';
  font-size: 18px;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all ease 0.3s;
  color: var(--button-default-enabled);
}

.QuestionsByTopicContainer_expandStatusIcon__3mRH2.QuestionsByTopicContainer_expanded__ucwGG::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.QuestionsByTopicContainer_questionsByTopicListItem__Vwk5d > div {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  max-width: 315px;
  line-height: 56px;
  width: 100%;
}

.QuestionsByTopicContainer_questionsLink__223Ux {
  display: inline-block;
  color: var(--neutral-dark-blue);
}

.QuestionsByTopicContainer_questionsLink__223Ux:first-letter {
  text-transform: uppercase;
}

.QuestionsByTopicContainer_questionsByTopicList__2gnge > p {
  color: var(--neutral-gray-blue);
}

.QuestionsByTopicContainer_accordion__CIDYZ {
  display: flex;
  flex-direction: column;
}

.QuestionsByTopicContainer_accordion__CIDYZ.accordionGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.QuestionsByTopicContainer_toggleViewButton__3SKdP {
  margin-left: auto;
  background-color: var(--whiteColor);
}

.QuestionsByTopicContainer_toggleViewButton__3SKdP:hover {
  background-color: var(--whiteColor);
}

@media (max-width: 991px) {
  .QuestionsByTopicContainer_questionSection__yb51z h1 {
    text-align: center;
    width: 100%;
  }

  .QuestionsByTopicContainer_questionsByTopicList__2gnge.QuestionsByTopicContainer_mobileMode__2pLak {
    max-height: 330px;
    height: 100%;
    overflow: hidden;
  }

  .QuestionsByTopicContainer_questionsByTopicList__2gnge.QuestionsByTopicContainer_mobileMode__2pLak.QuestionsByTopicContainer_expanded__ucwGG {
    max-height: none;
    overflow: visible;
    padding-bottom: 0;
    height: 100%;
    min-height: 100%;
  }

  .QuestionsByTopicContainer_questionsByTopicList__2gnge {
    /*column-width: 320px;*/
    margin: 32px 0 0;
  }

  .QuestionsByTopicContainer_questionsByTopic__2hQWx {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .QuestionsByTopicContainer_questionsByTopic__2hQWx .QuestionsByTopicContainer_pageTitle__Xfrz1 {
    padding-bottom: 0;
  }

  .QuestionsByTopicContainer_questionsByTopicList__2gnge {
    padding: 0;
  }

  .QuestionsByTopicContainer_questionSection__yb51z {
    background-color: var(--whiteColor);
  }

  .QuestionsByTopicContainer_extendControlContainer__IyGga {
    margin-bottom: 40px;
  }

  .QuestionsByTopicContainer_questionsTopicText__33O10 {
    flex-flow: row wrap;
  }

  .QuestionsByTopicContainer_questionsTopicText__33O10 p {
    max-width: none;
    margin-bottom: 20px;
  }

  .QuestionsByTopicContainer_questionsTopicText__33O10 p:not(:last-child) {
    margin-right: 0;
  }

  .QuestionsByTopicContainer_toggleViewButton__3SKdP {
    display: none;
  }
}

@media (max-width: 767px) {
  .QuestionsByTopicContainer_questionsByTopicList__2gnge {
    -webkit-columns: auto;
            columns: auto;
    -webkit-column-width: auto;
            column-width: auto;
  }

  .QuestionsByTopicContainer_questionSection__yb51z h1 {
    text-align: left;
    max-width: 310px;
    margin: 0 auto;
  }
}

.QuestionPage_questionPage__1blr7 .rulesGroupItem h1 {
  margin-bottom: 20px;
}

.QuestionPage_questionTopicBg__2vSE9 {
  background-color: var(--additional-gray-blue-20);
}

.QuestionPage_mobileView__2H-8y {
  display: none;
}

@media (max-width: 991px) {
  .QuestionPage_mobileView__2H-8y {
    display: block;
  }
}

.QuestionsBgContainer_questionsBgContainer__Hd0ux {
  background: url(/static/media/questionBg.e9f209ff.svg) no-repeat 50% 50%;
  background-size: cover;
}

.QuestionsBgContainer_imageBox__1cB7f {
  width: 100%;
  max-width: calc(50% - 10px);
  transition: 0.5s all;
}

.dark-theme .QuestionsBgContainer_questionsBgContainer__Hd0ux {
  background: unset;
}

.dark-theme .QuestionsBgContainer_imageBox__1cB7f img {
  height: 776px;
}

@media (max-width: 991px) {
  .QuestionsBgContainer_imageBox__1cB7f {
    display: none;
  }
}

.ContactCard_contactItem__3XvFl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--neutral-light-gray);
  border-radius: 6px;
  padding: 24px 26px 20px 24px;
  max-width: calc((100% / 3) - 10px);
  width: 100%;
  margin-bottom: 20px;
  margin-right: 0;
}

.ContactCard_contactItem__3XvFl:not(:last-child) {
  margin-right: 20px;
}

.ContactCard_contactTitle__2jnc1 {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.ContactCard_contactText__3BjJk {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}

.ContactCard_contactFooter__1vaGk {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .ContactCard_contactItem__3XvFl {
    max-width: none;
    width: 100%;
  }
}

.Map_loadingElement__2j8q7 {
    height: 100%;
}

.Map_container__3xh_q {
    height: 400px;
}

.Map_map__2bGnQ {
    height: 100%;
}

.ContactPage_jumbotron__356z4 {
  margin-bottom: 20px;
}

.ContactPage_contactPage__3X5KQ {
  padding-top: 60px;
}

.ContactPage_contactPageTitle__V9YKu {
  margin-bottom: 32px;
}

.ContactPage_contactList__3Z-Oi {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ContactPage_contactItemFly__1L8GB {
  position: absolute;
  left: 100px;
  top: 50px;
  padding: 20px 24px;
  background-color: var(--neutral-light-gray-no-opacity);
  border-radius: 6px;
  max-width: 350px;
  width: 100%;
  z-index: 999;
  margin-bottom: 20px;
}

.ContactPage_contactTitle__2kS6i {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.ContactPage_contactSubtitle__27XJr {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.ContactPage_contactText__2A7Hu {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}
.ContactPage_contactItemContent__2Wixu:not(:last-child) {
  margin-bottom: 10px;
}

.ContactPage_mapContainer__U62YL {
  position: relative;
  margin-bottom: 60px;
}

.ContactPage_mapElementContainer__2tL6N {
  height: 520px;
}

@media (max-width: 991px) {
  .ContactPage_contactPage__3X5KQ {
    padding-top: 24px;
  }

  .ContactPage_contactList__3Z-Oi {
    flex-flow: row wrap;
  }

  .ContactPage_contactItemFly__1L8GB {
    position: static;
    max-width: none;
  }

  .ContactPage_mapContainer__U62YL {
    margin-bottom: 24px;
  }

  .ContactPage_mapElementContainer__2tL6N {
    height: 350px;
  }
}

.Pagination_prevBtn__vAIru,
.Pagination_nextBtn__1wpro {
  position: relative;
}

.Pagination_prevBtn__vAIru {
  margin-right: 30px;
}

.Pagination_nextBtn__1wpro {
  margin-left: 30px;
}

.Pagination_prevBtn__vAIru:before,
.Pagination_nextBtn__1wpro:before {
  position: absolute;
  content: '\e917';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-family: 'icomoon', sans-serif;
  color: var(--neutral-dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: var(--button-secondary-enable);
}

.Pagination_prevBtn__vAIru:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Pagination_prevBtn__vAIru a,
.Pagination_nextBtn__1wpro a {
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.Pagination_prevBtn__vAIru span,
.Pagination_nextBtn__1wpro span {
  display: none;
}

.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item:first-child, .page-item:last-child {
  display: none;
}

.Pagination_paginationItem__PU1Qg a,
.Pagination_paginationItem__PU1Qg span {
  border: none;
  background-color: transparent;
  padding: 0 5px;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 48px;
  color: var(--neutral-gray-blue);
  transition: all 0.3s ease;
}

.page-link:hover {
  color: var(--button-default-focused);
  background-color: transparent;
}

.page-item.active .page-link {
  color: var(--button-default-focused);
  border: none;
  background-color: transparent;
}

.page-link:focus {
  box-shadow: none;
}

.Pagination_prevBtn__vAIru:before,
.Pagination_nextBtn__1wpro:before {
  color: var(--text-color);
}

.dark-theme .Pagination_prevBtn__vAIru:before,
.dark-theme .Pagination_nextBtn__1wpro:before {
  background-color: var(--whiteColor);
  color: var(--text-color);
  border: 1px solid var(--not-changine-white);
}

@media (max-width: 991px) {
  .Pagination_prevBtn__vAIru {
    margin-right: 15px;
  }

  .Pagination_nextBtn__1wpro {
    margin-left: 15px;
  }

  .Pagination_prevBtn__vAIru:before,
  .Pagination_nextBtn__1wpro:before {
    font-size: 16px;
  }

  .Pagination_prevBtn__vAIru a,
  .Pagination_nextBtn__1wpro a {
    width: 32px;
    height: 32px;
  }

  .Pagination_paginationItem__PU1Qg a,
  .Pagination_paginationItem__PU1Qg span {
    font-size: 16px;
    line-height: 32px;
  }
}

.SchoolTableContainer_schoolTable__2oL2J {
  margin-top: 36px;
}

.SchoolTableContainer_tableHeader__PzGVW,
.SchoolTableContainer_tableBodyRow__13Kqe {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
}

.SchoolTableContainer_tableHeaderItem__1RuG1 {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-gray-blue);
  width: 100%;
}

.SchoolTableContainer_tableBodyRow__13Kqe {
  background-color: var(--neutral-light-gray);
  border-radius: 6px;
  margin-bottom: 16px;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  text-align: left;
  width: 100%;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:not(:last-child) {
  margin-right: 29px;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:first-child,
.SchoolTableContainer_tableBodyRowItem__1zlF6:last-child,
.SchoolTableContainer_tableHeaderItem__1RuG1:first-child,
.SchoolTableContainer_tableHeaderItem__1RuG1:last-child {
  width: 32px;
  height: 32px;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:first-child,
.SchoolTableContainer_tableHeaderItem__1RuG1:first-child {
  display: none;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(2),
.SchoolTableContainer_tableHeaderItem__1RuG1:nth-child(2) {
  max-width: 25%;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(3),
.SchoolTableContainer_tableHeaderItem__1RuG1:nth-child(3) {
  max-width: 30%;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(4),
.SchoolTableContainer_tableHeaderItem__1RuG1:nth-child(4) {
  max-width: 15%;
}

.SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(5),
.SchoolTableContainer_tableHeaderItem__1RuG1:nth-child(5) {
  max-width: 10%;
}

.SchoolTableContainer_tableFooter__3M4OB {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 36px;
}

.SchoolTableContainer_linkBtn__3mFJW {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.SchoolTableContainer_linkBtn__3mFJW i {
  font-size: 14px;
}

@media (max-width: 991px) {
  .SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(4),
  .SchoolTableContainer_tableHeader__PzGVW,
  .SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(3) {
    display: none;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(2) {
    order: 1;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:first-child {
    order: 2;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:nth-child(5) {
    order: 3;
    max-width: 20%;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:last-child {
    order: 4;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6 {
    font-size: 14px;
    line-height: 21px;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:not(:last-child) {
    margin-right: 0;
  }

  .SchoolTableContainer_tableBodyRow__13Kqe {
    padding: 16px;
  }

  .SchoolTableContainer_tableBodyRowItem__1zlF6:first-child,
  .SchoolTableContainer_tableBodyRowItem__1zlF6:last-child {
    width: 24px;
    height: 24px;
  }

  .SchoolTableContainer_tableFooter__3M4OB {
    justify-content: center;
  }
}

.SchoolSearchContainer_searchContainer__1b9O3 {
  width: 100%;
}

.SchoolSearchContainer_searchContainer__1b9O3 button {
  margin-left: 20px;
}

.SchoolSearchContainer_searchContainer__1b9O3 form {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.SchoolSearchContainer_searchContainer__1b9O3 form input,
.SchoolSearchContainer_searchContainer__1b9O3 form input:focus,
.SchoolSearchContainer_searchContainer__1b9O3 form input:active,
.SchoolSearchContainer_searchContainer__1b9O3 form input:hover {
  background-color: var(--whiteColor);
  color: var(--text-color);
}

.dark-theme .SchoolSearchContainer_searchContainer__1b9O3 form input::-webkit-input-placeholder {
  color: var(--dark-mode-input-placeholder);
}

.dark-theme .SchoolSearchContainer_searchContainer__1b9O3 form input:-ms-input-placeholder {
  color: var(--dark-mode-input-placeholder);
}

.dark-theme .SchoolSearchContainer_searchContainer__1b9O3 form input::placeholder {
  color: var(--dark-mode-input-placeholder);
}

@media (max-width: 767px) {
  .SchoolSearchContainer_searchContainer__1b9O3 form {
    flex-flow: row wrap;
  }

  .SchoolSearchContainer_searchContainer__1b9O3 button {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }
}

.SchoolInfoModalContainer_root__PGBJU.modal-sm {
  max-width: 355px;
  background-color: var(--lavender-grey);
  border-radius: 5px;
}

.SchoolInfoModalContainer_modalBody___w2Vs {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
}

.SchoolInfoModalContainer_modalBody___w2Vs p {
  margin: 0;
}

.SchoolInfoModalContainer_modalHeader__Fm96I {
  padding: 0;
}

.SchoolInfoModalContainer_modalHeader__Fm96I.modal-header button.close:before {
  color: var(--theme-primary);
}

.SchoolInfoModalContainer_modalHeader__Fm96I.modal-header button.close {
  z-index: 20;
}

.SchoolInfoModalContainer_infoSection__bzhHi {
  padding: 20px;
  border-bottom: 1px solid var(--additional-gray-blue);
}

.SchoolInfoModalContainer_nestedSection__1b1yI {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.SchoolInfoModalContainer_nestedSection__1b1yI > div {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
}

.SchoolInfoModalContainer_infoSection__bzhHi:last-child {
  border-bottom: 1px solid transparent;
}

.SchoolInfoModalContainer_generalInfoSection__3djtc {
  display: flex;
}

.SchoolInfoModalContainer_generalInfoBox__EA24U {
  width: 50%;
  word-break: break-word;
}

.SchoolInfoModalContainer_imageContainer__2rJEL {
  width: 60%;
  padding-right: 20px;
  min-height: 20px;
}

.SchoolInfoModalContainer_titleText__sQmqc {
  font-family: Ubuntu;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.SchoolInfoModalContainer_subTitleText__3WVYa {
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue);
}

.SchoolInfoModalContainer_labelText__3TA8r {
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.SchoolInfoModalContainer_infoText__3rzuV {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SchoolInfoModalContainer_controlsContainer__3tBeg {
  display: flex;
  overflow: hidden;
}

.SchoolInfoModalContainer_controlsContainer__3tBeg .btn {
  width: 100%;
  border-radius: 0;
}

.SchoolMarkersContainer_container__TPPzG {
  position: relative;
}

.SchoolMarkersContainer_mapContainer__2kWxX {
  height: 640px;
}

@media (max-width: 991px) {
  .SchoolMarkersContainer_mapContainer__2kWxX {
    height: 350px;
  }
}

.DrivingSchoolPage_schoolPage__2CxGZ {
  padding: 60px 0 43px;
}

.DrivingSchoolPage_schoolTopContainer__2Y_en {
  max-width: 520px;
  margin-bottom: 130px;
}

@media (max-width: 991px) {
  .DrivingSchoolPage_schoolPage__2CxGZ {
    padding: 24px 0 0;
  }

  .DrivingSchoolPage_schoolTopContainer__2Y_en {
    margin-bottom: 40px;
  }
}

.CockpitTopPanel_cockpitTopPanel__1ppww {
  padding: 80px 0 46px;
}

.CockpitTopPanel_learnButtonLinkColor__1cN_e,
.CockpitTopPanel_learnButtonLinkColor__1cN_e:hover {
  color: var(--not-changine-white);
}

.CockpitTopPanel_buttonDisable__FOC-c {
  pointer-events: none;
}

.CockpitTopPanel_rulesList__3mP-G {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.CockpitTopPanel_cardFooter__1sHAE {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.CockpitTopPanel_cardFooter__1sHAE .CockpitTopPanel_rulesList__3mP-G {
  margin-right: 35px;
}

.CockpitTopPanel_cardFooter__1sHAE > div {
  margin: 0 40px 0 0;
}

.CockpitTopPanel_jList__2F58i {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 290px;
  padding: 28px;
  background-color: var(--lavender-grey);
  margin-right: 0;
  border-radius: 10px;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:first-child {
  max-width: 55%;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:not(:last-child) {
  margin-right: 20px;
}

.CockpitTopPanel_panelCount__2WHD5 {
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 52px;
  line-height: 100px;
  color: var(--neutral-gray-blue);
}

.CockpitTopPanel_panelTitle__3DyRr {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: var(--neutral-dark-blue);
}

.CockpitTopPanel_jumbotronHeader__2Zveq {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.CockpitTopPanel_jumbotronHeaderItem__3Q2Da {
  max-width: 60%;
}

.CockpitTopPanel_panelDate__tBYaB,
.CockpitTopPanel_panelTime__fiMM7 {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
}

.CockpitTopPanel_panelDate__tBYaB {
  color: var(--neutral-dark-blue);
}

.CockpitTopPanel_panelTime__fiMM7 {
  color: var(--neutral-dark-blue-50);
}

.CockpitTopPanel_daysContent__3hw4n {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin-bottom: 14px;
}

.CockpitTopPanel_daysValue__DBrBs {
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 92px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.CockpitTopPanel_daysLabel__1tmot {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-left: 10px;
}

.CockpitTopPanel_mobileJumbotron__3vzI5 {
  display: none;
}

.CockpitTopPanel_jList__2F58i .CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_jumbotron__17Ou2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  margin-bottom: 0;
  background-color: var(--neutral-light-gray);
}

.CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_panelTitle__3DyRr {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue);
}

.CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_daysValue__DBrBs,
.CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_daysLabel__1tmot {
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.CockpitTopPanel_mobileJumbotron__3vzI5 .CockpitTopPanel_daysContent__3hw4n {
  margin-bottom: 0;
}

@media (max-width: 1300px) {
  .CockpitTopPanel_rulesListItem__30ABh:not(:last-child) {
    margin-right: 15px;
  }

  .CockpitTopPanel_cardFooter__1sHAE .CockpitTopPanel_rulesList__3mP-G {
    margin-right: 15px;
  }
}

@media (max-width: 1199px) {
  .CockpitTopPanel_cardFooter__1sHAE > div {
    margin: 20px 0 40px;
  }

  .CockpitTopPanel_cardFooter__1sHAE {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .CockpitTopPanel_jList__2F58i {
    flex-flow: row wrap;
    max-width: 500px;
    margin: 0 auto;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2,
  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:first-child {
    max-width: 400px;
    width: 100%;
    margin: 15px auto 0;
    flex-grow: 1;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:not(:last-child) {
    margin-right: auto;
    margin-left: auto;
  }

  .CockpitTopPanel_cardFooter__1sHAE .CockpitTopPanel_rulesList__3mP-G {
    width: 100%;
    justify-content: center;
    margin-bottom: 28px;
    margin-right: 0;
  }

  .CockpitTopPanel_cardFooter__1sHAE > .btn {
    width: 100%;
  }

  .CockpitTopPanel_daysContent__3hw4n {
    justify-content: center;
  }

  .CockpitTopPanel_panelCount__2WHD5 {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .CockpitTopPanel_cockpitTopPanel__1ppww {
    padding: 0;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2 {
    background-color: var(--whiteColor);
    padding: 0;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:nth-child(2),
  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:nth-child(3) {
    display: none;
  }

  .CockpitTopPanel_jList__2F58i .CockpitTopPanel_jumbotron__17Ou2:not(:last-child) {
    margin-right: auto;
    margin-left: auto;
  }

  .CockpitTopPanel_mobileJumbotron__3vzI5 {
    display: block;
    margin: 10px 0;
  }
}

.CockpitCategories_cardCategoriesContainer__3xHLi {
  padding: 72px 0 88px;
  background-color: var(--additional-gray-blue);
}

.CockpitCategories_cardCategoriesItem__2Ek6F {
  position: relative;
  background-color: var(--neutral-light-gray);
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 220px;
  overflow: hidden;
  border: none;
}

.CockpitCategories_cardCategoriesItem__2Ek6F:first-child {
  background: var(--neutral-light-gray) url(/static/media/il-1.f579cf79.svg) no-repeat 100% 60%;
}

.CockpitCategories_cardCategoriesItem__2Ek6F:nth-child(2) {
  background: var(--neutral-light-gray) url(/static/media/il-2.7e8393f5.svg) no-repeat 90%;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .card-body {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 32px 36px 0;
  z-index: 2;
  width: 100%;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .card-title {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 16px;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .card-text {
  margin-bottom: 32px;
  color: var(--neutral-gray-blue);
  max-width: 300px;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .card-footer {
  position: relative;
  padding: 0 36px 28px;
  background-color: transparent;
  border-top: none;
  z-index: 2;
}

.CockpitCategories_cardCategoriesItem__2Ek6F .linkBtn {
  display: inline-flex;
}

.CockpitCategories_cardCategoriesItem__2Ek6F img {
  position: absolute;
  right: 5px;
  top: 15px;
  height: 240px;
  width: auto;
  z-index: 1;
}

.CockpitCategories_cardCategoriesList__1mZry {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.CockpitCategories_cardCategoriesItem__2Ek6F {
  max-width: calc(33.3% - 12px);
  width: 100%;
  margin-bottom: 20px;
}

.CockpitCategories_cardBadge__1Tiae {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 90px;
  height: 20px;
  background: var(--badge-category-color);
  top: 14px;
  right: -21px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.CockpitCategories_badgeText__9TygP {
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 3px;
}

.CockpitCategories_cardCategoriesItem__2Ek6F:nth-child(1),
.CockpitCategories_cardCategoriesItem__2Ek6F:nth-child(2) {
  /*max-width: calc(50% - 12px);*/
  /*width: 100%;*/
}

@media (max-width: 1199px) {
  .CockpitCategories_cardCategoriesItem__2Ek6F .card-title {
    font-size: 30px;
    line-height: 39px;
  }
}

@media (max-width: 991px) {
  .CockpitCategories_cardCategoriesItem__2Ek6F .card-title {
    font-size: 24px;
    line-height: 39px;
  }

  .CockpitCategories_cardCategoriesContainer__3xHLi {
    display: none;
  }

  .CockpitCategories_cardCategoriesItem__2Ek6F,
  .CockpitCategories_cardCategoriesItem__2Ek6F:nth-child(1),
  .CockpitCategories_cardCategoriesItem__2Ek6F:nth-child(2) {
    max-width: none;
    margin-bottom: 12px;
  }

  .CockpitCategories_cardCategoriesContainer__3xHLi {
    padding: 30px 0 28px;
  }
}

.CockpitBottomPanel_cockpitBottomPanel__bDMKx {
  padding: 80px 0 100px;
}

.CockpitBottomPanel_bottomPanelList__339DN {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 33%;
  margin-right: 0;
  border-radius: 10px;
}

.CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:first-child .CockpitBottomPanel_jumbotronBox__2-7oS .CockpitBottomPanel_jumbotronBoxItem__1nKTL {
  padding: 16px;
}

.CockpitBottomPanel_jumbotron__2RMIt {
  padding: 0;
  background-color: var(--lavender-grey);
  flex-grow: 1;
}

.CockpitBottomPanel_jumbotron__2RMIt:not(:last-child) {
  margin-right: 20px;
}

.CockpitBottomPanel_jumbotronBox__2-7oS {
  padding: 20px 28px;
}

.CockpitBottomPanel_jumbotronBox__2-7oS:not(:last-child),
.CockpitBottomPanel_jumbotronHeader__3g5Nv {
  border-bottom: 1px solid var(--additional-gray-blue);
}

.CockpitBottomPanel_jumbotronBox__2-7oS .h3 {
  color: var(--neutral-dark-blue);
  text-align: center;
}

.CockpitBottomPanel_jumbotronBoxItem__1nKTL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.CockpitBottomPanel_jumbotronBoxItem__1nKTL:not(:last-child) {
  margin-bottom: 16px;
}

.CockpitBottomPanel_instructorName__2ZCGU {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  color: var(--neutral-dark-blue);
}

.CockpitBottomPanel_instructorSc__3OCu6 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue);
}

.CockpitBottomPanel_jumbotronTime__2aGXn,
.CockpitBottomPanel_jumbotronDate__3cPRQ,
.CockpitBottomPanel_jumbotronCount__2hhDv {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.CockpitBottomPanel_jumbotronDate__3cPRQ {
  color: var(--neutral-dark-blue);
}

.CockpitBottomPanel_jumbotronTime__2aGXn {
  color: var(--neutral-dark-blue-50);
}

.CockpitBottomPanel_jumbotronCount__2hhDv {
  color: var(--functional-success);
}

.CockpitBottomPanel_jumbotronText__2KPqR {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
}

.CockpitBottomPanel_jumbotronIcon__32MPO .icon-arrow {
  font-size: 14px;
  color: var(--theme-primary);
}

.CockpitBottomPanel_progressBarContent__27ypp {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.CockpitBottomPanel_progressBarContent__27ypp .progress {
  max-width: none;
  height: 16px;
}

.progress {
  flex-grow: 1;
  border-radius: 8px;
}

.CockpitBottomPanel_progressBar___IehR .progress-bar {
  background-color: var(--theme-primary);
  border-radius: 8px;
  max-width: none;
}

.CockpitBottomPanel_jumbotronBoxDate__2A3eD {
  width: 100%;
}

.CockpitBottomPanel_instructorContent__ItRoD {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.CockpitBottomPanel_daysValue__3ob0v {
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 96px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-bottom: 14px;
}

.CockpitBottomPanel_daysText__3RvaI {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.CockpitBottomPanel_dateItemText__3URVL {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.CockpitBottomPanel_dateItemLabel__1M05t {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-50);
}

.CockpitBottomPanel_jumbotronBoxBtn__4dEW7 a,
.CockpitBottomPanel_jumbotronBoxBtn__4dEW7 .CockpitBottomPanel_btn__1TDAa {
  width: 100%;
  border-radius: 0 0 6px 6px;
}

.CockpitBottomPanel_jumbotronLabel__36SQ5 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-50);
  margin-bottom: 0;
}

.CockpitBottomPanel_jumbotronValue__1QsoP {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--neutral-gray-blue);
  margin-bottom: 0;
}

.CockpitBottomPanel_jumbotronBoxItem__1nKTL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.CockpitBottomPanel_dateItem__2g-4Z {
  max-width: calc(50% - 10px);
  width: 100%;
}

.CockpitBottomPanel_jumbotronPlaceholder__vHEAK {
  text-align: center;
}

.CockpitBottomPanel_jumbotronImg__E3ZNw {
  display: block;
  max-width: 100%;
  margin: 35px auto 44px;
}

.CockpitBottomPanel_jumbotronHeader__3g5Nv {
  min-height: 0;
  flex-shrink: 0;
}

.CockpitBottomPanel_jumbotronFooter__3G_5W {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.CockpitBottomPanel_jumbotronFooter__3G_5W .CockpitBottomPanel_jumbotronBox__2-7oS {
  border-bottom: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.CockpitBottomPanel_jumbotronFooter__3G_5W .CockpitBottomPanel_jumbotronBtn__qLPxk {
  min-height: 0;
  flex-shrink: 0;
}

.CockpitBottomPanel_jumbotronIconBox__3RdEq {
  width: 100%;
  text-align: center;
}

.CockpitBottomPanel_jumbotronIconBox__3RdEq i {
  font-size: 78px;
  color: var(--neutral-gray);
}

@media (max-width: 991px) {
  .CockpitBottomPanel_cockpitBottomPanel__bDMKx {
    padding: 0;
  }

  .CockpitBottomPanel_bottomPanelList__339DN {
    flex-flow: row wrap;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt {
    max-width: 400px;
    width: 100%;
    margin: 0 auto 20px;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:first-child {
    margin-bottom: 0;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt {
    background-color: var(--whiteColor);
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:first-child .CockpitBottomPanel_jumbotronBoxItem__1nKTL {
    background-color: var(--lavender-grey);
    border-radius: 6px;
  }

  .CockpitBottomPanel_jumbotronHeader__3g5Nv .CockpitBottomPanel_jumbotronBox__2-7oS {
    padding: 20px 28px 0;
    border-bottom: none;
  }

  .CockpitBottomPanel_jumbotronHeader__3g5Nv {
    border-bottom: none;
  }

  .CockpitBottomPanel_dateItemText__3URVL {
    font-size: 17px;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:nth-child(2) {
    background-color: var(--neutral-light-gray);
    order: 3;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:nth-child(2) > .CockpitBottomPanel_jumbotronBox__2-7oS:first-child {
    display: none;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child {
    order: 2;
    flex-flow: row wrap;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child > .CockpitBottomPanel_jumbotronBox__2-7oS:first-child,
  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child > .CockpitBottomPanel_jumbotronBox__2-7oS:nth-child(2) {
    max-width: 50%;
    width: 100%;
    border-bottom: none;
    background-color: var(--neutral-light-gray);
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child > .CockpitBottomPanel_jumbotronBox__2-7oS:first-child {
    padding-right: 10px;
  }

  .CockpitBottomPanel_jumbotronBox__2-7oS {
    padding: 20px;
  }

  .CockpitBottomPanel_daysValue__3ob0v {
    display: inline-block;
    font-size: 48px;
    line-height: 31px;
    text-align: left;
    margin-bottom: 0;
  }

  .CockpitBottomPanel_daysText__3RvaI {
    display: inline-block;
    font-size: 18px;
    line-height: 31px;
    text-align: left;
    margin-left: 5px;
  }

  .CockpitBottomPanel_jumbotronBox__2-7oS .h3 {
    font-size: 18px;
    line-height: 23px;
    text-align: left;
  }

  .CockpitBottomPanel_bottomPanelList__339DN .CockpitBottomPanel_jumbotron__2RMIt:last-child .CockpitBottomPanel_jumbotronBox__2-7oS {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .CockpitBottomPanel_instructorContent__ItRoD {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .CockpitBottomPanel_instructorContent__ItRoD .schoolLogo {
    max-width: unset;
    width: 100%;
  }

  .CockpitBottomPanel_instructorInfo__1xsn0 {
    margin-top: 20px;
    width: 100%;
  }
}

.CockpitLoggedOffline_placeholderContainer__2Chxu {
  padding-top: 75px;
  padding-bottom: 75px;
}

.CockpitLoggedOffline_invert__Zl9BY.CockpitLoggedOffline_placeholderContainer__2Chxu {
  background-color: var(--additional-gray-blue);
}

.CockpitLoggedOffline_box__2KKu4 {
  width: 100%;
  height: 220px;
  margin-bottom: 15px;
}

.CockpitLoggedOffline_box__2KKu4.CockpitLoggedOffline_vertical__3tf64 {
  height: 460px;
}

.CockpitLoggedOffline_invert__Zl9BY.CockpitLoggedOffline_box__2KKu4 {
  background-color: var(--whiteColor);
}

@media (max-width: 767px) {
  .CockpitLoggedOffline_box__2KKu4.CockpitLoggedOffline_mbRaw__1GEuL {
    height: 60px;
  }

  .CockpitLoggedOffline_box__2KKu4.CockpitLoggedOffline_vertical__3tf64 {
    height: 220px;
  }
}

.CockpitCategoriesAccordion_cardCategoriesMobile__JGk_P {
  display: none;
}

.CockpitCategoriesAccordion_cardCategoriesList__1wZ1u .CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card {
  border-radius: 6px;
  background-color: var(--whiteColor);
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2:not(:last-child) {
  margin-bottom: 12px;
  position: relative;
}

.CockpitCategoriesAccordion_cardBadge__3cooG {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: var(--badge-category-color);
  top: -4px;
  right: -11px;
  z-index: 1;
}

.CockpitCategoriesAccordion_badgeText__1sIzQ {
  font-weight: 500;
  font-size: 12px;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: relative;
  width: 100%;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  border-bottom: none;
  background-color: var(--lavender-grey);
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card {
  width: 100%;
  border-color: transparent;
  transition: all ease 0.3s;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card-body {
  padding: 18px 24px 21px;
  border: 1px solid transparent;
  width: 100%;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .collapse.show .card-body {
  width: 100%;
  border-color: var(--additional-blue);
  border-top: 0;
  border-radius: 0 0 10px 10px;
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card-header .icon-arrow {
  font-size: 24px;
  color: var(--theme-primary);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.CockpitCategoriesAccordion_cardCategoriesItem__1Hal2 .card-text {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .CockpitCategoriesAccordion_cardCategoriesMobile__JGk_P {
    display: block;
    padding: 10px 0;
  }
}

.CockpitLoggedContainer_mobileCategories__2mq8K {
  display: none;
}

@media (max-width: 991px) {
  .CockpitLoggedContainer_mobileCategories__2mq8K {
    display: block;
  }
}

.CockpitNotLoggedPage_rulesGroupContent__2UwLO {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.CockpitNotLoggedPage_imgContainer__3XC4L {
  max-width: calc(50% - 10px);
  transition: 0.5s all;
}

.CockpitNotLoggedPage_imgContainer__3XC4L img {
  height: 100%;
  width: auto;
  /*height: auto;*/
  /*width: 100%;*/
}

.dark-theme .CockpitNotLoggedPage_imgContainer__3XC4L img {
  height: 776px;
}

.CockpitNotLoggedPage_questionsByTopic__3I0nc {
  padding-top: 80px;
}

.CockpitNotLoggedPage_questionsByTopicTitle__dbtf_ {
  color: var(--neutral-dark-blue);
}

.CockpitNotLoggedPage_questionsByTopicList__1DQnU {
  -webkit-column-count: 3;
          column-count: 3;
  -webkit-column-width: 390px;
          column-width: 390px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin: 54px 0 80px;
}

.CockpitNotLoggedPage_questionsByTopicListItem__2ZRHJ {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.CockpitNotLoggedPage_questionsLink__K2J7- {
  display: inline-block;
  margin: 16px 0;
  color: var(--neutral-dark-blue);
}

.CockpitNotLoggedPage_questionsByTopicList__1DQnU > p {
  color: var(--neutral-gray-blue);
}

@media (max-width: 1450px) {
  .CockpitNotLoggedPage_imgContainer__3XC4L img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .CockpitNotLoggedPage_CockpitNotLoggedPage__26nHo .CockpitNotLoggedPage_imgContainer__3XC4L {
    display: none;
  }

  .CockpitNotLoggedPage_btnList__UcQGx {
    justify-content: center;
    margin-bottom: 20px;
  }

  .CockpitNotLoggedPage_btnList__UcQGx .CockpitNotLoggedPage_btn__2qGMS {
    margin: 10px auto 0;
  }
}

@media (max-width: 1300px) {
  .CockpitNotLoggedPage_imgContainer__3XC4L img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .CockpitNotLoggedPage_questionsByTopicList__1DQnU {
    -webkit-column-width: 320px;
            column-width: 320px;
  }
}

.DrivingInstructor_instructorPage__1fMbV {
  padding: 60px 0 120px;
}

.DrivingInstructor_instructorPage__1fMbV h1 {
  margin-bottom: 32px;
}

.DrivingInstructor_instructorSubtitle__1LhOq {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

p.DrivingInstructor_instructorSubtitle__1LhOq {
  color: var(--neutral-dark-blue);
}

.DrivingInstructor_instructorList__2m36Q p {
  position: relative;
  padding-left: 22px;
}

.DrivingInstructor_instructorList__2m36Q p:before {
  position: absolute;
  content: '';
  left: 0;
  top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--neutral-gray);
}

.DrivingInstructor_instructorPage__1fMbV .jumbotron {
  margin-right: 0;
  padding: 24px 13px 30px 25px;
  border-radius: 10px;
  max-width: 440px;
}

.DrivingInstructor_jumbotronTitle__22SrI {
  padding-left: 20px;
  max-width: 420px;
}

@media (max-width: 991px) {
  .DrivingInstructor_instructorPage__1fMbV {
    padding: 24px 0 0;
  }

  .DrivingInstructor_jumbotronTitle__22SrI {
    padding-left: 0;
  }
}

.RegisterEmailError_root__17SQa.modal-sm {
  max-width: 355px;
  background-color: var(--lavender-grey);
  border-radius: 5px;
}

.RegisterEmailError_modalHeader__1uYZw {
  padding: 0;
}

.RegisterEmailError_modalHeader__1uYZw.modal-header button.close:before {
  color: var(--theme-primary);
}

.RegisterEmailError_modalHeader__1uYZw.modal-header button.close {
  z-index: 20;
}

.RegisterFormTCS_checkList__2WO58 {
  border-radius: 6px;
  background-color: var(--neutral-light-gray);
  padding: 16px 18px;
  color: var(--text-color);
}

.RegisterFormTCS_checkList__2WO58 .custom-checkbox:not(:last-child) {
  margin-bottom: 12px;
}

.RegisterVerificationCodeError_root__2-cWx.modal-sm {
  max-width: 355px;
  background-color: var(--lavender-grey);
  border-radius: 5px;
}

.RegisterVerificationCodeError_modalHeader__iUTa4 {
  padding: 0;
}

.RegisterVerificationCodeError_modalHeader__iUTa4.modal-header button.close:before {
  color: var(--theme-primary);
}

.RegisterVerificationCodeError_modalHeader__iUTa4.modal-header button.close {
  z-index: 20;
}

.RegisterVerificationCodeError_modalBody__S4ABZ {
  padding-top: 40px;
  text-align: center;
}

.RegisterContainerTCS_registration__3cFto {
  padding: 40px 0;
}

.RegisterContainerTCS_registration__3cFto .linkBtn {
  margin-bottom: 20px;
  margin-left: 20px;
}

.RegisterContainerTCS_registration__3cFto .linkBtn .icon-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 5px;
}

.RegisterContainerTCS_registration__3cFto .btn-secondary,
.RegisterContainerTCS_registration__3cFto .btn-outline-danger {
  display: block;
  margin-left: auto;
  margin-top: 20px;
}

.RegisterContainerTCS_formRegistration__3ZdWd {
  margin-top: 24px;
}

.RegisterContainerTCS_formTitle__1PFcz {
  font-family: 'museo-slab', sans-serif;
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
  color: var(--neutral-dark-blue);
  margin-left: 20px;
}

.RegisterContainerTCS_planInfo__2Jt0G {
  width: 100%;
  border-radius: 10px;
  background-color: var(--neutral-light-gray);
  padding: 24px 32px;
}

.RegisterContainerTCS_planInfo__2Jt0G > .RegisterContainerTCS_planInfoItem__1VZaR:last-child {
  margin-top: 30px;
}

.RegisterContainerTCS_planInfoBox__rgVMa {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.RegisterContainerTCS_planInfoBox__rgVMa > .RegisterContainerTCS_planInfoItem__1VZaR {
  max-width: calc(50% - 5px);
  width: 100%;
  margin-bottom: 8px;
}

.RegisterContainerTCS_itemLabel__cWqnB {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.RegisterContainerTCS_itemValue__2kbh5 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.RegisterContainerTCS_registration__3cFto .form-group {
  margin-bottom: 22px;
}

.RegisterContainerTCS_affiliatePrice__164NK {
  position: relative;
  display: inline;
}

.RegisterContainerTCS_affiliatePrice__164NK::after {
  border-bottom: 0.13em solid red;
  content: '';
  margin-top: calc(0.2em / 2 * -1);
  position: absolute;
  top: 50%;
  width: 130%;
  left: -9px;
}

.RegisterContainerTCS_registrationButton__LElfV {
  display: flex;
  align-items: flex-end;
}

.RegisterContainerTCS_registrationButton__LElfV button {
  margin-left: 0;
  margin-bottom: 120px;
}

.RegisterContainerTCS_registration__3cFto .form-control {
  height: 44px;
  border: 1px solid var(--neutral-gray);
  background-color: var(--whiteColor);
  color: var(--text-color);
}

.dark-theme .RegisterContainerTCS_registration__3cFto .form-group input::-webkit-input-placeholder {
  color: var(--dark-mode-input-placeholder);
}

.dark-theme .RegisterContainerTCS_registration__3cFto .form-group input:-ms-input-placeholder {
  color: var(--dark-mode-input-placeholder);
}

.dark-theme .RegisterContainerTCS_registration__3cFto .form-group input::placeholder {
  color: var(--dark-mode-input-placeholder);
}

.RegisterContainerTCS_paymentList__3QPxM .list-group-item:not(:last-child) {
  margin-bottom: 8px;
}

.RegisterContainerTCS_paymentList__3QPxM .list-group-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--neutral-light-gray);
  border: 1px solid var(--additional-gray-blue);
  border-radius: 10px;
  font-family: 'Hind', sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
  cursor: pointer;
  transition: all ease 0.3s;
  padding: 12px 13px;
}

.RegisterContainerTCS_paymentList__3QPxM .list-group-item:hover,
.RegisterContainerTCS_paymentList__3QPxM .list-group-item.active {
  background-color: var(--whiteColor);
}

.RegisterContainerTCS_paymentList__3QPxM .p3 {
  color: var(--neutral-gray-blue);
}

.custom-control-label::before {
  position: absolute;
  top: 0;
  left: -24px;
  display: block;
  width: 24px;
  height: 24px;
  content: '';
  background-color: var(--not-changine-white);
  border: 1px solid var(--neutral-gray);
  transition: all ease 0.3s;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input {
  position: absolute;
  opacity: 0;
  z-index: 2;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--not-changine-black);
  background-color: var(--not-changine-white);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  content: '\e902';
  color: var(--theme-primary);
  font-family: 'icomoon';
  width: 24px;
  height: 24px;
  top: 0;
  left: -24px;
  font-size: 24px;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: var(--whiteColor);
}

.custom-control-input.is-valid:checked ~ .custom-control-label::after, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::after {
  color: var(--functional-success);
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: var(--neutral-gray-blue);
}

.custom-checkbox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.custom-control-label {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--text-color);
  padding-left: 20px;
}

.RegisterContainerTCS_planInfoTopOffset__1nJj- {
  margin-top: 20px;
}

.RegisterContainerTCS_planInfoTopOffsetMobile__2qgTb {
  display: none;
}

.RegisterContainerTCS_voucherMobile__2ejCD {
  display: none;
}

@media (max-width: 991px) {
  .RegisterContainerTCS_affiliateInfo__3jimh {
    max-width: unset;
  }

  .RegisterContainerTCS_planInfo__2Jt0G {
    margin-bottom: 20px;
  }

  .RegisterContainerTCS_registration__3cFto .linkBtn,
  .RegisterContainerTCS_formTitle__1PFcz {
    margin-left: 0;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfo__2Jt0G {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfo__2Jt0G .RegisterContainerTCS_itemLabel__cWqnB {
    display: none;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfo__2Jt0G.RegisterContainerTCS_planInfoTopOffsetMobile__2qgTb .RegisterContainerTCS_itemLabel__cWqnB {
    display: block;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfo__2Jt0G .RegisterContainerTCS_itemValue__2kbh5,
  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfoBox__rgVMa .RegisterContainerTCS_planInfoItem__1VZaR {
    margin-bottom: 0;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfoBox__rgVMa {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfo__2Jt0G > .RegisterContainerTCS_planInfoItem__1VZaR > .RegisterContainerTCS_itemValue__2kbh5,
  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfoBox__rgVMa .RegisterContainerTCS_planInfoItem__1VZaR .RegisterContainerTCS_itemValue__2kbh5 {
    font-family: 'museo-slab', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfo__2Jt0G > .RegisterContainerTCS_planInfoItem__1VZaR > .RegisterContainerTCS_itemValue__2kbh5,
  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfoBox__rgVMa .RegisterContainerTCS_planInfoItem__1VZaR:last-child .RegisterContainerTCS_itemValue__2kbh5 {
    text-transform: uppercase;
    color: var(--neutral-dark-blue);
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfo__2Jt0G > .RegisterContainerTCS_planInfoItem__1VZaR > .RegisterContainerTCS_itemValue__2kbh5 {
    margin-right: 70px;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_voucherPlan__gzCS- > .RegisterContainerTCS_planInfoItem__1VZaR > .RegisterContainerTCS_itemValue__2kbh5 {
    margin-right: 5px;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_voucherPlan__gzCS- > .RegisterContainerTCS_planInfoItem__1VZaR {
    width: 100%;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfoBox__rgVMa .RegisterContainerTCS_planInfoItem__1VZaR:first-child .RegisterContainerTCS_itemValue__2kbh5 {
    color: var(--neutral-dark-blue-50);
  }

  .RegisterContainerTCS_planInfoTopOffsetMobile__2qgTb {
    display: block;
  }

  .RegisterContainerTCS_planInfo__2Jt0G.RegisterContainerTCS_planInfoTopOffset__1nJj- {
    display: none;
  }

  .RegisterContainerTCS_planInfo__2Jt0G .RegisterContainerTCS_planInfoItem__1VZaR {
    display: none;
  }

  .RegisterContainerTCS_planInfo__2Jt0G .RegisterContainerTCS_planInfoBox__rgVMa .RegisterContainerTCS_planInfoItem__1VZaR {
    display: block;
  }

  .RegisterContainerTCS_voucherMobile__2ejCD {
    display: flex;
    width: 100%;
    height: 47px;
  }

  .RegisterContainerTCS_voucherMobile__2ejCD .RegisterContainerTCS_planInfoItem__1VZaR {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .RegisterContainerTCS_voucherMobile__2ejCD .RegisterContainerTCS_planInfoItem__1VZaR .RegisterContainerTCS_itemLabel__cWqnB,
  .RegisterContainerTCS_planInfo__2Jt0G .RegisterContainerTCS_planInfoBox__rgVMa .RegisterContainerTCS_itemLabel__cWqnB {
    display: block;
  }
  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_voucherPlan__gzCS- .RegisterContainerTCS_planInfoBox__rgVMa {
    max-width: 38%;
  }

  .RegisterContainerTCS_registration__3cFto .btn-secondary,
  .RegisterContainerTCS_registration__3cFto .btn-outline-danger {
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .RegisterContainerTCS_planInfoBox__rgVMa .RegisterContainerTCS_itemLabel__cWqnB,
  .RegisterContainerTCS_voucherMobile__2ejCD .RegisterContainerTCS_itemLabel__cWqnB {
    white-space: nowrap;
  }
  .RegisterContainerTCS_voucherPlan__gzCS- .RegisterContainerTCS_voucherMobile__2ejCD .RegisterContainerTCS_planInfoItem__1VZaR {
    align-items: center;
  }

  .RegisterContainerTCS_planInfo__2Jt0G {
    max-width: none;
  }

  .RegisterContainerTCS_affiliateInfo__3jimh.RegisterContainerTCS_planInfo__2Jt0G > .RegisterContainerTCS_planInfoItem__1VZaR > .RegisterContainerTCS_itemValue__2kbh5 {
    margin: 0 10px;
  }
}

@media (max-width: 500px) {
  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfo__2Jt0G {
    flex-flow: column nowrap;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfoBox__rgVMa,
  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_voucherPlan__gzCS- .RegisterContainerTCS_planInfoBox__rgVMa {
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_planInfoBox__rgVMa .RegisterContainerTCS_planInfoItem__1VZaR {
    text-align: center;
  }

  .RegisterContainerTCS_voucherMobile__2ejCD .RegisterContainerTCS_itemValue__2kbh5 {
    font-size: 16px;
  }

  .RegisterContainerTCS_voucherMobile__2ejCD .RegisterContainerTCS_planInfoItem__1VZaR,
  .RegisterContainerTCS_voucherPlan__gzCS-,
  .RegisterContainerTCS_voucherMobile__2ejCD .RegisterContainerTCS_planInfoItem__1VZaR {
    align-items: center;
  }
}

@media (max-width: 450px) {
  .RegisterContainerTCS_affiliateInfo__3jimh.RegisterContainerTCS_planInfo__2Jt0G > .RegisterContainerTCS_planInfoItem__1VZaR > .RegisterContainerTCS_itemValue__2kbh5 {
    font-size: 13px;
  }

  .RegisterContainerTCS_affiliateInfo__3jimh.RegisterContainerTCS_planInfo__2Jt0G {
    padding: 5px;
  }

  .RegisterContainerTCS_formRegistration__3ZdWd .RegisterContainerTCS_voucherPlan__gzCS- {
    padding: 20px 5px;
  }
}

.FaqPage_faqPage__3bBGl {
  padding: 80px 0 60px;
}

.FaqPage_faqList__Qu_Ms {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
}

.FaqPage_faqListContainer__3NTnC {
  max-width: 30%;
  width: 100%;
}

.FaqPage_faqPage__3bBGl .pageTitle {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.FaqPage_faqLink__2nBBu {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  text-transform: none;
}
.FaqPage_faqLink__2nBBu:hover {
  text-decoration: none;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .FaqPage_faqPage__3bBGl {
    padding: 0;
  }

  .FaqPage_faqPage__3bBGl .pageTitle {
    font-size: 28px;
    line-height: 42px;
  }

  .FaqPage_faqList__Qu_Ms {
    margin-top: 12px;
  }

  .FaqPage_faqListContainer__3NTnC {
    max-width: none;
  }
}

.AnleitungPage_manualPage__11RQl {
  padding-bottom: 50px;
}

.AnleitungPage_manualPageTitle__2JrvD {
  margin: 50px 0 26px;
}

.AnleitungPage_pageText__3JDtG,
.AnleitungPage_pageTextBold__3B17y {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.AnleitungPage_pageSubtitle__2DqX5 {
  display: block;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.AnleitungPage_pageTextBold__3B17y {
  font-weight: bold;
}

.AnleitungPage_manualPageContent__2FMVf {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.AnleitungPage_manualPageContentIcon__SdBP- {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 44px 0 28px;
}

.AnleitungPage_manualPageContentIcon__SdBP- div {
  margin-right: 20px;
}

.AnleitungPage_rulesList__JulAW {
  display: flex;
  margin-right: 20px;
  width: 100%;
}

.NotFoundContainer_notFoundPage__N7cSY {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-top: 60px;
}

.NotFoundContainer_pageContainer__1sVTq {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.NotFoundContainer_imgContainer__3lyo2 {
  margin-bottom: 38px;
}

.NotFoundContainer_pageTextError__nAVCB {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 120px;
  line-height: 200px;
  text-align: center;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue-70);
}

.NotFoundContainer_pageText__2MVmU {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: var(--neutral-dark-blue-70);
  margin-top: 14px;
}

.NotFoundContainer_linkBtn__1su3T {
  display: flex;
  align-items: center;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--button-default-focused);
  margin-top: 80px;
}

.NotFoundContainer_linkBtn__1su3T i {
  display: block;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

@media (max-width: 991px) {
  .NotFoundContainer_pageTextError__nAVCB {
    font-size: 96px;
  }
}

.AboutPage_aboutPage__1MWWQ {
  padding: 60px 0 30px;
}

.AboutPage_topContainer__3sXIN {
  padding-bottom: 20px;
}

.AboutPage_aboutBtnWrapper__1FzxK {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AboutPage_aboutBtn__14Ee1 {
  margin: 12px 0 28px;
  padding: 6px 65px;
}

.AboutPage_listTitle__3ttb0 {
  margin: 0;
  color: var(--not-changine-white);
}

body.lady-theme .AboutPage_listTitle__3ttb0 {
  color: var(--text-color);
}

.AboutPage_gameContainer__23tLu {
  padding: 40px 0 20px 0;
  background-color: var(--not-changine-black);
}

.AboutPage_gameContainer__23tLu {
  color: var(--whiteColor);
}

.AboutPage_gameText__VClKE {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 18px;
  color: var(--not-changine-white);
}

body .AboutPage_aboutPageGameSubtitle__2JfYJ {
  color: var(--not-changine-white);
  margin: 40px 0 28px;
}

.AboutPage_listContainer__2Kq28 {
  padding: 40px 0 20px 0;
  background-color: var(--neutral-light-gray);
}

.AboutPage_listText__17GNi {
  font-size: 15px;
  /*line-height: 24px;*/
  color: var(--neutral-gray-blue);
  margin-bottom: 18px;
}

.AboutPage_aboutPageSubtitle__-54D8 {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-color);
  text-transform: uppercase;
  margin: -5px 0 30px;
}

.AboutPage_aboutPageTextList__t99uL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.AboutPage_imageContainer__5QjGz {
  padding: 40px 0;
}

.AboutPage_imageWrapper__3Mxbr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AboutPage_imageWrapper__3Mxbr img {
  width: 100%;
  max-width: 600px;
}

.AboutPage_aboutPageItem__Z48Ug {
  display: flex;
  max-width: 33.3%;
  width: 100%;
}

.AboutPage_aboutPageItem__Z48Ug img {
  align-self: flex-start;
  margin: -20px 30px 0 0;
}

.AboutPage_aboutPageItemTitle__vChBZ {
  font-weight: bold;
  font-size: 17px;
  color: var(--text-color);
}

.AboutPage_aboutPageItem__Z48Ug img {
  max-width: 89px;
}

.AboutPage_aboutPageItem__Z48Ug:not(:last-child) {
  margin-right: 26px;
}

.AboutPage_aboutPageText__oXG8a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--neutral-gray-blue);
}

.AboutPage_aboutPageTitle__3z-mZ {
  font-weight: bold;
  font-size: 25px;
  color: var(--text-color);
}

.AboutPage_aboutPageBoldSubtitle__3cGAH {
  margin: 15px 0 10px 0;
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
}

.AboutPage_aboutPageSubtitleWithMargin__26kIf {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color);
}

.AboutPage_aboutBtn__14Ee1 a {
  color: var(--blackColor);
}

@media (max-width: 991px) {
  .AboutPage_aboutPage__1MWWQ {
    padding: 0;
  }

  .AboutPage_aboutPageTextList__t99uL {
    flex-flow: row wrap;
  }

  .AboutPage_aboutPageItem__Z48Ug {
    max-width: none;
  }

  .AboutPage_aboutPageItem__Z48Ug:not(:last-child) {
    margin-right: 0;
  }

  .AboutPage_aboutPageItem__Z48Ug:not(:first-child) {
    margin-top: 20px;
  }

  .AboutPage_aboutBtn__14Ee1 {
    max-width: 280px;
    width: 100%;
    margin: 12px auto 28px;
    display: block;
  }

  .AboutPage_videoWrapper__miaWz {
    position: relative;
    padding-bottom: 60%; /* 16:9 */
    height: 0;
    max-width: 650px;
    margin: 0 auto 40px auto;
  }
}

@media (max-width: 600px) {
  .AboutPage_aboutPageItem__Z48Ug {
    flex-direction: column;
  }

  .AboutPage_aboutPageItem__Z48Ug img {
    align-self: center;
    margin: 0 0 15px 0;
  }
}

.EbikeDisplay_responsiveVideo__1jrVE {
  max-width: 100%;
  height: auto;
}

.EbikePage_ebikeContent__1n4Kd {
  padding: 64px 0 95px;
  background-color: var(--whiteColor);
}

video.EbikePage_responsiveVideo__1o_S_ {
  max-width: 100%;
  height: auto
}

.EbikePage_ebikeContentText__2KyfM {
  padding: 60px 0 100px;
  background-color: var(--neutral-light-gray);
}

.EbikePage_ebikeContentText__2KyfM .nav {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

.EbikePage_ebikeContentText__2KyfM .nav-item {
  padding: 17px 30px 16px 16px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-bottom: 12px;
  background-color: var(--whiteColor);
}

.EbikePage_ebikeContentText__2KyfM .nav-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  padding: 0;
  color: var(--neutral-dark-blue);
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  transition: all ease-in 0.3s;
}

.EbikePage_ebikeContentText__2KyfM .nav-link:hover,
.EbikePage_ebikeContentText__2KyfM .nav-link.active {
  color: var(--button-default-focused);
}

.EbikePage_ebikeContentText__2KyfM .nav-link .icon-arrow {
  font-size: 14px;
}

.EbikePage_ebikeTextList__2CFvI .h8,
.EbikePage_ebikeTextList__2CFvI h4,
.EbikePage_ebikeContent__1n4Kd .p3 {
  color: var(--neutral-dark-blue);
}

.EbikePage_ebikeTextList__2CFvI {
  color: var(--neutral-gray-blue);
}

.EbikePage_ebikeTextTitle__1gd4X {
  margin-bottom: 24px;
}

.EbikePage_ebikeImgBox__3qhwU {
  margin-top: 12px;
}

.EbikePage_ebikeImgBox__3qhwU img {
  margin-bottom: 24px;
  width: 100%;
  height: auto;
}

.TheoryTopicsListContainer_topicList__1VkRq {
  max-width: 400px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
  margin-right: 20px;
}

.TheoryTopicsListContainer_topicList__1VkRq div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-right: 20px;
}

.TheoryTopicsListContainer_topicList__1VkRq div:not(:last-child) {
  margin-bottom: 12px;
}

.TheoryTopicsListContainer_topicList__1VkRq i {
  display: block;
  font-size: 14px;
  color: var(--theme-primary);
}

.TheoryTopicsListContainer_topicList__1VkRq button:hover,
.TheoryTopicsListContainer_topicList__1VkRq button:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

@media (max-width: 991px) {
  .TheoryTopicsListContainer_topicList__1VkRq {
    display: none;
  }
}

.TheoryBookByTopicsPage_theoryTopicPage__3_tcO {
  background-color: var(--lavender-grey);
  padding-top: 32px;
}

.theoryTopicPage .topicsListItem {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.TheoryBookByTopicsPage_theoryTopicPage__3_tcO .TheoryBookByTopicsPage_linkBtn__1FFum {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.TheoryBookByTopicsPage_linkBtn__1FFum i {
  display: block;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

.TheoryBookByTopicsPage_theoryTopicContent__2QXmA {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 100px;
  max-height: 930px;
  height: 100%;
  overflow: hidden;
}

.TheoryBookByTopicsPage_mobileView__fNXhO {
  display: none;
}

@media (max-width: 991px) {
  .TheoryBookByTopicsPage_theoryTopicPage__3_tcO {
    background-color: var(--whiteColor);
  }

  .TheoryBookByTopicsPage_theoryTopicContent__2QXmA {
    padding-bottom: 20px;
    max-height: none;
    overflow: inherit;
  }

  .TheoryBookByTopicsPage_mobileView__fNXhO {
    display: block;
    margin-bottom: 40px;
  }
}

.TheoryGlossary_glossaryListItem__3rfbP {
  margin-bottom: 16px;
}

.TheoryGlossary_glossaryLink__ICziV {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  padding: 0;
  text-align: left;
  border: none;
}

.TheoryGlossaryContainer_glossaryList__2WD_y {
  background-color: var(--whiteColor);
  padding: 32px;
  border-radius: 6px;
  overflow-y: auto;
  max-width: 400px;
  width: 100%;
  margin-right: 32px;
  min-width: 0;
  flex-shrink: 0;
}

.TheoryGlossaryContainer_glossaryList__2WD_y::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TheoryGlossaryContainer_glossaryList__2WD_y::-webkit-scrollbar {
  width: 12px;
}

.TheoryGlossaryContainer_glossaryList__2WD_y::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.TheoryGlossaryContainer_letterItem__2Y9FI {
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 16px;
}

@media (max-width: 991px) {
  .TheoryGlossaryContainer_glossaryList__2WD_y {
    width: 100%;
    max-width: none;
    max-height: 500px;
    overflow-y: auto;
    margin-bottom: 40px;
    background-color: var(--lavender-grey);
  }
}

.TheoryGlossaryPage_glossaryPage__1UHrJ {
  padding: 32px 0 104px;
  background-color: var(--lavender-grey);
}

.TheoryGlossaryPage_glossaryPage__1UHrJ .h2 {
  color: var(--neutral-dark-blue);
  margin: 20px 0;
}

.TheoryGlossaryPage_glossaryPageContent__347Qu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  max-height: 750px;
  height: 100%;
  overflow: hidden;
}

.TheoryGlossaryPage_glossaryPage__1UHrJ .TheoryGlossaryPage_linkBtn__2RyWr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--button-default-focused);
  transition: all ease 0.3s;
  text-transform: uppercase;
}

.TheoryGlossaryPage_glossaryPage__1UHrJ .TheoryGlossaryPage_linkBtn__2RyWr i {
  display: block;
  font-size: 14px;
  color: currentColor;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 5px;
}

.TheoryGlossaryPage_glossaryPage__1UHrJ .TheoryGlossaryPage_linkBtn__2RyWr:hover,
.TheoryGlossaryPage_glossaryPage__1UHrJ .TheoryGlossaryPage_linkBtn__2RyWr:focus {
  text-decoration: none;
}

.TheoryGlossaryPage_glossaryPageContent__347Qu::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.TheoryGlossaryPage_glossaryPageContent__347Qu::-webkit-scrollbar {
  width: 12px;
}

.TheoryGlossaryPage_glossaryPageContent__347Qu::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.TheoryGlossaryPage_mobileView__38HSi {
  display: none;
}

@media (max-width: 991px) {
  .TheoryGlossaryPage_glossaryPage__1UHrJ {
    padding: 24px 0 20px;
    background-color: var(--whiteColor);
  }

  .TheoryGlossaryPage_glossaryPageContent__347Qu {
    flex-flow: row wrap;
    max-height: none;
  }

  .TheoryGlossaryPage_glossaryPageContent__347Qu > div:last-child {
    display: none;
  }

  .TheoryGlossaryPage_mobileView__38HSi {
    display: block;
  }
}

.OfferCard_suggested__3TpBa {
  background-color: #e9f6fe;
}

.OfferAccordion_offerAccordionList__1xG_E {
  display: block;
}

.OfferAccordion_accordionCardItem__1Ho0Z {
  max-width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
}

.OfferAccordion_accordionBtn__3Bf9s {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  color: var(--theme-primary);
  width: 24px;
  height: 24px;
}

.OfferAccordion_accordionBtn__3Bf9s > i {
  font-size: 24px;
}

.OfferAccordion_cardHeaderText__3GEDl,
.OfferAccordion_cardHeaderDays__1GC8M {
  font-family: 'museo-slab', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.OfferAccordion_cardHeaderDays__1GC8M {
  line-height: 18px;
  color: var(--neutral-dark-blue-50);
}

.OfferAccordion_cardHeaderText__3GEDl {
  line-height: 22px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 18px 20px 18px 16px;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card-body {
  width: 100%;
  padding: 25px 0 0;
}

.OfferAccordion_accordionCardItem__1Ho0Z .collapse {
  width: 100%;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card-text {
  font-family: 'Hind', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-70);
  padding: 0 20px;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card {
  border: none;
  background-color: var(--neutral-light-gray);
  border-radius: 6px !important;
}

.OfferAccordion_accordionCardItem__1Ho0Z .card.OfferAccordion_suggested__28-Xc {
  background-color: #e9f6fe;
}

.OfferAccordion_cardHeaderInfo__1hVFK {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: 50%;
  width: 100%;
}

.OfferAccordion_accordionFooterBtn__3ulmM {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02px;
  width: 100%;
}

@media (max-width: 991px) {
  .OfferAccordion_offerAccordionList__1xG_E {
    display: flex;
  }
}

@media (max-width: 600px) {
  .OfferAccordion_cardHeaderInfo__1hVFK {
    max-width: 60%;
  }
}

.OfferCardContainer_offerCardList__mjenO .card {
  margin-bottom: 32px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: none;
  max-width: calc(33.3% - 15px);
  width: 100%;
  overflow: hidden;
}

.OfferCardContainer_offerAccordionList__1MaTj .card {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  max-width: none;
}

.OfferCardContainer_card-body__13mJ2 {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 20px 0;
  width: 100%;
}

.OfferCardContainer_card-title__3LEnS,
.OfferCardContainer_card-footer__1fCRg {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.OfferCardContainer_card-title__3LEnS {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 28px;
  color: var(--neutral-dark-blue);
}

.OfferCardContainer_header-text__H22k_ {
  font-family: 'museo-slab', sans-serif;
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: var(--modal-footer-btn-color);
}

.OfferCardContainer_card-text__3SqBG {
  font-family: 'Hind', sans-serif;
}

.OfferCardContainer_card-footer__1fCRg {
  background-color: transparent;
  border-top: none;
  padding: 0 20px 25px;
  margin-top: 44px;
}

.OfferCardContainer_footerDays__28nlN {
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  line-height: 21px;
  font-size: 14px;
  color: var(--functional-success);
}

.OfferCardContainer_card-text__3SqBG {
  color: var(--neutral-dark-blue-70);
}

.OfferCardContainer_offerCard__1Y04x {
  background-color: var(--neutral-light-gray);
}

.OfferCardContainer_placeholderBox__2LHLx {
  width: calc(33.3% - 15px);
  background-color: var(--whiteColor);
  margin-bottom: 30px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
}

.OfferCardContainer_offerCardList__mjenO {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.OfferCardContainer_offerCardList__mjenO .card {
  margin-bottom: 32px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: none;
  max-width: calc(33.3% - 15px);
  width: 100%;
  overflow: hidden;
}

.OfferCardContainer_offerCardList__mjenO .card-body {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 20px 0;
  width: 100%;
}

.OfferCardContainer_offerCardList__mjenO .card-title,
.OfferCardContainer_offerCardList__mjenO .card-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.OfferCardContainer_offerCardList__mjenO .card-title {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 28px;
  color: var(--neutral-dark-blue);
}

.OfferCardContainer_offerCardList__mjenO .header-text {
  font-family: 'museo-slab', sans-serif;
  line-height: 21px;
  font-size: 21px;
  font-weight: bold;
  color: var(--modal-footer-btn-color);
}

.OfferCardContainer_offerCardList__mjenO .card-text {
  font-family: 'Hind', sans-serif;
}

.OfferCardContainer_offerCardList__mjenO .card-footer {
  background-color: transparent;
  border-top: none;
  padding: 0 20px 25px;
  margin-top: 44px;
}

.OfferCardContainer_offerCardList__mjenO .footerDays {
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  line-height: 21px;
  font-size: 21px;
  color: var(--functional-success);
}

.OfferCardContainer_offerCardList__mjenO .continueText {
  font-size: 24px;
}

.OfferCardContainer_offerCardList__mjenO .card-text {
  color: var(--neutral-dark-blue-70);
  width: 100%;
}

.OfferCardContainer_offerCardList__mjenO .offerCard {
  background-color: var(--neutral-light-gray);
}

@media (max-width: 991px) {
  .OfferCardContainer_card___AZwS,
  .OfferCardContainer_placeholderBox__2LHLx {
    max-width: calc(50% - 15px);
    margin-bottom: 20px;
    background-color: var(--lavender-grey);
  }
}

@media (max-width: 767px) {
  .OfferCardContainer_card___AZwS {
    max-width: none;
  }

  h3,
  .OfferCardContainer_h3__115Eg {
    font-size: 14px;
    line-height: 22px;
  }

  .OfferCardContainer_placeholderBox__2LHLx {
    width: 100%;
    max-width: 100%;
    height: 60px;
    background-color: var(--lavender-grey);
  }

  .OfferCardContainer_offerAccordionList__1MaTj {
    display: flex;
    flex-direction: column;
  }
}

.VipCard_vipCard__1gLVH {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
  background: url('/static/images/vip-card.svg') no-repeat left center, var(--whiteColor);
  background-size: auto 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  padding: 10px;
}

.VipCard_cardLogo__3RRHF {
  width: 75px;
  margin-bottom: 0px;
}

.VipCard_cardTitle__JAEDR {
  font-family: 'Hind', sans-serif;
  font-size: 32px;
  padding-top: 10px;
  line-height: 35px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--theme-primary);
  margin-bottom: 10px;
  white-space: pre-line;
  text-align: right;
}

.VipCard_ruleList__3yNAj {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
}

.VipCard_ruleListItem__2Q8_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: var(--theme-primary-80);
}

.VipCard_ruleListItem__2Q8_1:not(:first-child) {
  margin-left: 12px;
}

.VipCard_ruleListItem__2Q8_1 > i {
  color: currentColor;
}

@media (max-width: 1199px) {
  .VipCard_vipCard__1gLVH {
    padding: 17px;
  }

  .VipCard_cardTitle__JAEDR {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 25px;
  }

  .VipCard_cardLogo__3RRHF {
    width: 75px;
    margin-bottom: 42px;
  }

  .VipCard_ruleListItem__2Q8_1 {
    width: 36px;
    height: 36px;
  }

  .VipCard_ruleListItem__2Q8_1 > i {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .VipCard_vipCard__1gLVH {
    padding: 14px;
    max-width: 420px;
    margin: 0 auto;
  }

  .VipCard_cardLogo__3RRHF {
    width: 68px;
    margin-bottom: 25px;
  }

  .VipCard_ruleListItem__2Q8_1:not(:first-child) {
    margin-left: 10px;
  }

  .VipCard_ruleListItem__2Q8_1 {
    width: 32px;
    height: 32px;
  }

  .VipCard_ruleListItem__2Q8_1 > i {
    font-size: 24px;
  }
}

@media (max-width: 500px) {
  .VipCard_vipCard__1gLVH {
    padding: 5px;
    margin: 0 auto;
  }

  .VipCard_vipCard__1gLVH img {
    margin-bottom: 5px;
    width: 55px;
  }

  .VipCard_cardTitle__JAEDR {
    margin: 0;
    font-size: 18px;
    line-height: normal;
    line-height: initial;
  }
}

.VipCardMobile_vipCardMobile__3hq80 {
  display: none;
  position: relative;
}

.VipCardMobile_cardLogo__3ju5G {
  width: 100%;
}

@media (max-width: 767px) {
  .VipCardMobile_vipCardMobile__3hq80 {
    display: block;
  }

  .VipCardMobile_vipCardMobile__3hq80 .linkBtn {
    position: absolute;
    top: 110px;
    right: 5px;
  }
}

@media (max-width: 530px) {
  .VipCardMobile_vipCardMobile__3hq80 .linkBtn {
    top: 100px;
    font-size: 12px;
  }
}

@media (max-width: 490px) {
  .VipCardMobile_vipCardMobile__3hq80 .linkBtn {
    top: 90px;
  }
}

@media (max-width: 440px) {
  .VipCardMobile_vipCardMobile__3hq80 .linkBtn {
    top: 80px;
  }
}

@media (max-width: 410px) {
  .VipCardMobile_vipCardMobile__3hq80 .linkBtn {
    top: 70px;
  }
}

@media (max-width: 360px) {
  .VipCardMobile_vipCardMobile__3hq80 .linkBtn {
    top: 60px;
  }
}

.OffersPage_offersPageText__1Oeui {
  padding-top: 60px;
  padding-bottom: 60px;
}

.OffersPage_mainTitle__TeBS0 {
  text-align: center;
}

.OffersPage_pageTextList__m2vWc,
.OffersPage_pageTextListMobile__t1ve- {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.OffersPage_pageTextList__m2vWc > p,
.OffersPage_pageTextListMobile__t1ve- > p {
  width: 100%;
}

.OffersPage_list__16Zr5 {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.OffersPage_list__16Zr5 ul {
  display: inline-block;
}

.OffersPage_list__16Zr5 li {
  margin: 8px 0;
  display: flex;
  align-items: center;
}

.OffersPage_list__16Zr5 svg {
  margin-right: 10px;
}

.OffersPage_listTitle__3UpYv {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.OffersPage_pageTextContainer__3jzqU {
  background-color: var(--whiteColor);
}

.OffersPage_pageTextListMobile__t1ve- {
  display: none;
}

.OffersPage_iconsList__3LA5P {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 62px;
  margin-top: 30px;
}

.OffersPage_iconsList__3LA5P div {
  width: auto;
}

.OffersPage_iconsList__3LA5P div img {
  width: 100%;
  max-width: 450px;
}

.OffersPage_iconsListItem__1gAUC {
  width: 100px;
  height: 100px;
}

.OffersPage_iconsListItem__1gAUC:not(:last-child) {
  margin-right: 20px;
}

.OffersPage_offerPageCard__361cE {
  background-color: var(--neutral-light-gray);
  padding-top: 60px;
  padding-bottom: 60px;
}

.OffersPage_furtherAdvantages__3ovbj {
  background-color: var(--whiteColor);
  padding-top: 60px;
  padding-bottom: 60px;
}

.OffersPage_offerPageCard__361cE .card {
  background-color: var(--whiteColor);
}

.OffersPage_offerPageContent__1SqiX {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.OffersPage_offerCard__22yTG {
  background-color: var(--whiteColor);
  max-width: 408px;
  width: 100%;
  margin-top: 40px;
}

.OffersPage_offerPageItem__3xXi0 {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  max-width: 50%;
  width: 100%;
}

.OffersPage_offerPageItem__3xXi0 .pageSubtitle {
  text-align: center;
}

.OffersPage_offerPageVip__2QnVW {
  background-color: var(--neutral-light-gray);
  padding: 60px 0 40px;
}

.OffersPage_offerPageVip__2QnVW .OffersPage_offerCard__22yTG {
  margin-bottom: 0;
  border: none;
}

.OffersPage_offerPageVip__2QnVW .OffersPage_offerCard__22yTG .card-body {
  width: 100%;
  padding: 40px 32px;
}

.OffersPage_offerPageVip__2QnVW .OffersPage_offerCard__22yTG .card-text {
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.OffersPage_cardFooterSingle__1bWdM {
  background-color: var(--button-primary-enabled);
  padding: 0;
  border-radius: 0 0 10px 10px;
}

.OffersPage_offerFooterLink__3ity_ {
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--not-changine-black);
  padding: 15px 20px;
  transition: all ease-in 0.3s;
}

.OffersPage_offerFooterLink__3ity_:hover,
.OffersPage_offerFooterLink__3ity_:focus {
  background-color: var(--button-primary-focused);
  color: var(--not-changine-black);
}

.OffersPage_offerVipCard__3vTqt {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .OffersPage_pageTextList__m2vWc > p {
    max-width: none;
  }
}

@media (max-width: 991px) {
  .OffersPage_offerPageItem__3xXi0 {
    max-width: none;
    margin-bottom: 24px;
  }

  .OffersPage_offerCard__22yTG {
    margin: 0 auto;
  }

  .OffersPage_pageTextListMobile__t1ve- {
    display: block;
    text-align: center;
  }

  .OffersPage_pageTextListMobile__t1ve- > p {
    margin: 0 auto 15px;
  }

  .OffersPage_pageTextList__m2vWc {
    display: none;
  }

  .OffersPage_iconsList__3LA5P {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .OffersPage_offersPageText__1Oeui {
    padding: 40px 0 40px;
  }

  .OffersPage_offerPageVip__2QnVW {
    padding: 40px 0 40px;
  }

  .OffersPage_offerPageCard__361cE {
    margin-bottom: 24px;
  }

  .OffersPage_furtherAdvantages__3ovbj {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .OffersPage_offerPageCard__361cE .offerCardList .card {
    max-width: calc(50% - 15px);
    margin-bottom: 20px;
    background-color: var(--lavender-grey);
  }
}

@media (max-width: 767px) {
  .OffersPage_list__16Zr5 {
    padding: 0;
  }

  .OffersPage_offerPageCard__361cE .offerCardList .card {
    max-width: none;
  }

  .OffersPage_offerPageCard__361cE .offerCardList {
    display: none;
  }

  h3,
  .OffersPage_h3__1SeMF {
    font-size: 18px;
    line-height: 22px;
  }

  .OffersPage_offerVipCard__3vTqt {
    display: none;
  }
}

.PromoIcon_iconsListItem__f3Xfl {
  width: 100px;
  height: 100px;
}

.PromoIcon_iconsListItem__f3Xfl:not(:last-child) {
  margin-right: 20px;
}

.SchoolInfoContainer_container__dMZtR {
  background: var(--additional-gray-blue-20);
  border-radius: 10px;
  padding: 30px 40px;
  font-family: Hind;
  font-weight: 500;
}

.SchoolInfoContainer_container__dMZtR p {
  margin: 0;
}

.SchoolInfoContainer_generalInfoBox__3H1fy {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.SchoolInfoContainer_titleText__wbua_ {
  font-family: Ubuntu;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
}

.SchoolInfoContainer_subTitleText__3taDb {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.SchoolInfoContainer_contactInfoRow__3zSP2 {
  padding-top: 50px;
}

.SchoolInfoContainer_contactInfoBoxItem__2kKWN {
  margin-bottom: 20px;
}

.SchoolInfoContainer_infoLabel__1KCfK {
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.SchoolInfoContainer_infoText__2dDX3 {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SchoolInfoContainer_controlsContainer__dDfFz {
  margin-top: 20px;
  padding: 0;
}

.SchoolInfoContainer_controlsBox__2SQ1j {
  display: flex;
  justify-content: flex-end;
}

.DrivingSchoolInfoPage_schoolInfoPage__1e_r4 {
  padding-top: 24px;
}

.DrivingSchoolInfoPage_linkBtn__2SBkH {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  text-transform: uppercase;
  font-weight: 500;
}

.DrivingSchoolInfoPage_linkBtn__2SBkH i {
  display: block;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}

.CockpitNotLoggedTopContainer_btnGroup__2oBXE {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.CockpitNotLoggedTopContainer_offersButton__1hTEL a {
  color: var(--not-changine-black);
}

.dark-theme .CockpitNotLoggedTopContainer_btnGroup__2oBXE .btn:hover {
  background-color: var(--theme-accent);
  color: var(--not-changine-black);
}

.CockpitNotLoggedTopContainer_btnGroup__2oBXE .btn:first-child,
.CockpitNotLoggedTopContainer_btnGroup__2oBXE .btn:nth-child(2) {
  margin-right: 20px;
}

@media (max-width: 1199px) {
  .CockpitNotLoggedTopContainer_btnGroup__2oBXE {
    justify-content: center;
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .CockpitNotLoggedTopContainer_btnGroup__2oBXE {
    flex-wrap: wrap;
  }

  .CockpitNotLoggedTopContainer_btnGroup__2oBXE > .btn {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .CockpitNotLoggedTopContainer_btnGroup__2oBXE > .btn:first-child,
  .CockpitNotLoggedTopContainer_btnGroup__2oBXE .btn:nth-child(2) {
    margin-right: 0;
  }
}

.HomeCards_promoCardList__1dvML {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.HomeCards_promoCardItem__1wHgM {
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  max-width: calc(25% - 10px);
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.HomeCards_cardContent__2L5cW {
  display: block;
  width: 100%;
}

.HomeCards_cardContentTitle__6NSYm,
.HomeCards_cardContentText__2UC0Z {
  margin-bottom: 10px;
}

.HomeCards_cardContentTitle__6NSYm {
  color: var(--neutral-dark-blue);
}

.HomeCards_cardContentText__2UC0Z {
  color: var(--neutral-gray-blue);
}

.HomeCards_cardFooterLink__v79Gt {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'museo-slab', sans-serif;
  font-size: 15px;
  line-height: 18px;
}

@media (max-width: 1200px) {
  .HomeCards_promoCardList__1dvML {
    flex-flow: row wrap;
  }

  .HomeCards_promoCardItem__1wHgM {
    max-width: calc(50% - 10px);
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .HomeCards_promoCardList__1dvML {
    margin: 0;
  }

  .HomeCards_promoCardItem__1wHgM {
    max-width: none;
  }
}

.HomeCarousel_homeSlider__23Fyt {
  margin-bottom: 36px;
  min-height: 200px;
  max-width: 300px;
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators {
  right: auto;
  margin-left: 0;
  margin-right: 0;
}

.HomeCarousel_homeSlider__23Fyt .carousel-control-prev,
.HomeCarousel_homeSlider__23Fyt .carousel-control-next {
  display: none;
}

.HomeCarousel_sliderTitle__1useJ {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
  margin-bottom: 4px;
}

.HomeCarousel_homeSlider__23Fyt .HomeCarousel_sliderText__2UtE5 {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
  margin: 12px 0 0;
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--neutral-gray);
  opacity: 1;
  transition: ease-in-out 0.4s;
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li:not(:first-child) {
  margin-left: 8px;
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li.active,
.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li:hover {
  background-color: var(--theme-primary);
}

.HomeCarousel_homeSlider__23Fyt .carousel-indicators > li:hover {
  cursor: pointer;
}

.HomeCarousel_langList__TCRf6 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 1199px) {
  .HomeCarousel_homeSlider__23Fyt {
    margin: 0 auto 5px;
  }
}

@media (max-width: 991px) {
  .HomeCarousel_homeSlider__23Fyt {
    display: none;
  }
}

@media (max-width: 767px) {
  .HomeCarousel_mobileSlider__3zt5n {
    text-align: left;
  }
}

.PromoContent_promoContent__3knAD {
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.PromoContent_promoTitle__urgFh {
  background-color: var(--functional-warning-bold);
  font-size: 20px;
  font-weight: bold;
  color: var(--whiteColor);
  text-align: left;
  padding: 5px 10px;
  white-space: pre-wrap;
  line-height: 30px;
  width: auto;
  display: inline-block;
  border-radius: 5px;
}

.PromoContent_promoItem__1fWBE {
  max-width: calc(50% - 10px);
  width: 100%;
}

.PromoContent_subtitle__3I5cP {
  margin: -10px 0 10px 0;
  padding-left: 5px;
  font-size: 18px;
  color: var(--text-color);
  /*text-align: left;*/
}

.PromoContent_promoItem__1fWBE:first-child {
  position: relative;
}

.PromoContent_btnList__3KgFv {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.PromoContent_btnList__3KgFv > a:last-child {
  margin-left: 20px;
}

.PromoContent_rulesImg__2hPE7 {
  width: 600px;
  /*height: 776px;*/
  /*width: 100%;*/
}

.categoryIconBarHome {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.PromoContent_rulesList__1-TMz {
  position: absolute;
  right: 0;
  bottom: 30px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.5s all;
  margin: 0 auto;
}

.dark-theme .PromoContent_rulesList__1-TMz {
  bottom: -30px;
}

.PromoContent_rulesListItem__1yoeh {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.PromoContent_rulesListItem__1yoeh:not(:last-child) {
  margin-right: 26px;
}

.PromoContent_rulesListItemText__3QfYq {
  font-family: 'museo-slab', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  margin-top: 10px;
}

.PromoContent_rulesListMobile__33Fw1 {
  display: none;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 50%;
}

.PromoContent_iconsList__3vkhM {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 62px;
}

.PromoContent_iconsListItem__2pZqb {
  width: 100px;
  height: 100px;
}

.PromoContent_iconsList__3vkhM img {
  max-width: 450px;
}

.PromoContent_loginBtnMobile__3DWNl {
  display: none;
}

.PromoContent_ruleListItem__2Kq5q:not(:first-child) {
  margin-left: 12px;
}

.PromoContent_ruleListItem__2Kq5q > i {
  color: currentColor;
}

.PromoContent_socialLinkListMobile__1TE-D {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 36px;
}

.PromoContent_socialLinkList__3ThCs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

.PromoContent_socialLinkList__3ThCs > li:not(:last-child) {
  margin-right: 40px;
}

.PromoContent_socialLinkList__3ThCs > li {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PromoContent_socialLinkItem__fZaFF {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease-in-out 0.4s;
}

.PromoContent_socialLinkItem__fZaFF:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.PromoContent_socialLinkItemText__3Va5H {
  text-transform: uppercase;
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: var(--text-color);
  margin-top: 14px;
}

.PromoContent_navbar-expand-lg__2DyTX {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: var(--whiteColor);
}

@media (min-width: 1200px) {
  .PromoContent_promoItem__1fWBE:last-child {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .PromoContent_promoTitle__urgFh {
    font-size: 16px;
    text-align: center;
    padding: 5px 10px;
    line-height: 18px;
    margin: 0 auto 10px auto;
  }

  .PromoContent_promoItem__1fWBE:first-child {
    display: none;
  }

  .PromoContent_iconsList__3vkhM > div {
    width: unset;
  }

  .PromoContent_iconsList__3vkhM img {
    width: 100%;
  }

  .PromoContent_promoItem__1fWBE {
    text-align: center;
  }

  .PromoContent_rulesListMobile__33Fw1 {
    display: flex;
    margin-bottom: 28px;
    justify-content: center;
    max-width: none;
  }

  .PromoContent_rulesListMobile__33Fw1 > i {
    font-size: 36px;
  }

  .PromoContent_btnList__3KgFv {
    justify-content: center;
  }

  .PromoContent_promoContent__3knAD {
    flex-wrap: wrap;
  }

  .PromoContent_promoItem__1fWBE {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
  }

  .PromoContent_iconsList__3vkhM {
    margin-bottom: 20px;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .PromoContent_promoTitle__urgFh {
    white-space: nowrap;
    display: inline-block;
    width: auto;
    padding: 5px 10px;
  }

  .PromoContent_promoItem__1fWBE h1 {
    margin-bottom: 12px;
  }

  .PromoContent_sectionVipContent__34bHF {
    flex-wrap: wrap;
  }

  .PromoContent_sectionVipItem__2pXhQ {
    max-width: none;
    width: 100%;
    margin-bottom: 25px;
  }

  .PromoContent_sectionCard__3CQkK {
    background-color: var(--neutral-light-gray);
  }

  .PromoContent_storeBtnList__2oH8v {
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
  }

  .PromoContent_loginBtnMobile__3DWNl {
    display: block;
  }

  .PromoContent_btnList__3KgFv {
    flex-wrap: wrap;
  }

  .PromoContent_btnList__3KgFv > a,
  .PromoContent_btnList__3KgFv > button {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .PromoContent_btnList__3KgFv > button {
    order: 1;
  }

  .PromoContent_btnList__3KgFv > a:nth-child(3) {
    margin-left: 0;
    order: 2;
  }

  .PromoContent_btnList__3KgFv > a:nth-child(2) {
    order: 3;
  }
}

@media (max-width: 600px) {
  .PromoContent_promoTitle__urgFh {
    display: inline-block;
    width: auto;
    font-size: 15px;
    padding: 5px 10px;
  }
}

@media (max-width: 500px) {
  .PromoContent_promoTitle__urgFh {
    white-space: unset;
  }
}

.VipContent_sectionVipContent__GfCk8 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
}

.VipContent_sectionVipItem__20bvX {
  max-width: 50%;
  width: 100%;
}

.VipContent_sectionVipItem__20bvX .h3 {
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
}

.VipContent_sectionVipItem__20bvX:last-child {
  max-width: 45%;
}

.VipContent_storeBtnList__aFc2C {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 72px;
}

.VipContent_storeBtnItem__1aojy {
  max-width: 164px;
  width: 100%;
  height: 48px;
  background-color: var(--blackColor);
  border-radius: 4px;
}

.VipContent_storeBtnItem__1aojy:first-child {
  margin-right: 12px;
}

.VipContent_vipText__2r_VC {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
}

@media (max-width: 1199px) {
  .VipContent_storeBtnList__aFc2C {
    justify-content: center;
  }
  .VipContent_sectionVipItem__20bvX .VipContent_pageText__2SQQY {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .VipContent_sectionVipContent__GfCk8 {
    flex-wrap: wrap;
  }

  .VipContent_sectionVipItem__20bvX,
  .VipContent_sectionVipItem__20bvX:last-child {
    max-width: none;
    width: 100%;
    margin-bottom: 25px;
  }

  .VipContent_storeBtnList__aFc2C {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .VipContent_installAppsContainer__3nkIH {
    padding-top: 25px;
  }

  .VipContent_installAppsContainer__3nkIH .VipContent_storeBtnList__aFc2C {
    justify-content: space-between;
  }

  .VipContent_installAppsContainer__3nkIH .VipContent_storeBtnList__aFc2C a {
    max-width: 200px;
  }
}

@media (max-width: 600px) {
  .VipContent_installAppsContainer__3nkIH .VipContent_storeBtnList__aFc2C {
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
  }

  .VipContent_installAppsContainer__3nkIH .VipContent_storeBtnList__aFc2C a {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
  }
}

.SocialLinkList_socialLinkItem__BzCSb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease 0.4s;
}

.SocialLinkList_socialLinkItem__BzCSb:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.SocialLinkList_socialLinkItemText__1NY2l {
  text-transform: uppercase;
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: var(--text-color);
  margin-top: 14px;
}

.SocialLinkList_socialLinkListMobile__3vqb4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 36px;
}

.SocialLinkList_socialLinkList__3Bqtk {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

.SocialLinkList_socialLinkList__3Bqtk > li:not(:last-child) {
  margin-right: 40px;
}

.SocialLinkList_socialLinkList__3Bqtk > li {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  .SocialLinkList_socialLinkListMobile__3vqb4 {
    display: none;
  }
}

.HomeCarouselMobile_mobileSlider__3DGxG {
  display: none;
}

.HomeCarouselMobile_mobileSliderItem__3kwfT:not(:last-child) {
  margin-bottom: 20px;
}

.HomeCarouselMobile_sliderTitle__1vX6A {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-gray-blue);
  margin-bottom: 4px;
}

.HomeCarouselMobile_mobileSlider__3DGxG .HomeCarouselMobile_sliderText__3Trws {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue);
  margin: 8px 0 0;
}

.HomeCarouselMobile_langList__16duO {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.HomeCarouselMobile_langList__16duO > li:not(:last-child) {
  margin-right: 5px;
}

@media (max-width: 991px) {
  .HomeCarouselMobile_mobileSlider__3DGxG {
    display: block;
  }
}

.HomePage_sectionPromo__xoNfr {
  margin-bottom: 80px;
}

.HomePage_sectionPromo__xoNfr .pageTitle {
  margin-bottom: 32px;
}

.HomePage_sectionCard__zvzZ0 {
  padding: 70px 0 100px;
}

.HomePage_rulesListMobile__XzTuv {
  display: none;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 50%;
}

.HomePage_sectionAdsMobile__DnmNG {
  display: none;
}

@media (max-width: 991px) {
  .HomePage_sectionCard__zvzZ0 {
    background-color: var(--neutral-light-gray);
    padding: 24px 0 36px;
  }

  .HomePage_sectionPromo__xoNfr {
    margin-bottom: 18px;
  }

  .HomePage_sectionAdsMobile__DnmNG {
    display: block;
    padding: 0 0 30px;
  }

  .HomePage_mobileCarousel__2XTbB {
    display: block;
    background-color: var(--neutral-light-gray);
    padding: 40px 0;
  }

  .HomePage_sectionOnlineShop__29aX7 {
    padding-top: 40px;
    padding-bottom: 70px;
  }
}

.Teacher3dNavMenu_navList__8pD2r {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Teacher3dNavMenu_navList__8pD2r .nav-item {
  width: 100%;
  background-color: var(--whiteColor);
  margin-bottom: 12px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

.Teacher3dNavMenu_navList__8pD2r .nav-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
  padding: 16px 30px 16px 16px;
  transition: all ease 0.3s;
}

.Teacher3dNavMenu_navList__8pD2r .nav-link i {
  font-size: 14px;
  color: var(--theme-primary);
}

.Teacher3dNavMenu_navList__8pD2r .nav-link.active,
.Teacher3dNavMenu_navList__8pD2r .nav-link:hover {
  color: var(--button-default-focused);
}

.Teacher3dDisplay_pageTopContent__1owFB {
  padding-bottom: 80px;
}

.Teacher3dDisplay_pageTopContent__1owFB h1 {
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .Teacher3dDisplay_pageTopContent__1owFB {
    padding-bottom: 0;
  }

  .Teacher3dDisplay_pageTopContent__1owFB h1 {
    margin-bottom: 16px;
  }
}

.PageContentTitle_pageContentTitle__cP3fs {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
}

.Teacher3dCarousel_responsiveVideo__3mkfz {
  max-width: 610px;
  width: 100%;
  height: 100%;
  min-height: 442px;
  border-radius: 6px;
}

.carousel-item p {
  margin: 12px 0 24px;
  max-width: 400px;
}

@media (max-width: 1199px) {
  .Teacher3dCarousel_responsiveVideo__3mkfz {
    min-height: 338px;
  }
}

@media (max-width: 991px) {
  .Teacher3dCarousel_responsiveVideo__3mkfz {
    min-height: auto;
    max-width: none;
  }
}

.Teacher3dPage_teacherPage__2pCD4 {
  padding-top: 40px;
  margin-bottom: -57px;
}

.Teacher3dPage_pageBottomContent__20vuz {
  padding: 60px 0 100px;
  background-color: var(--lavender-grey);
  will-change: max-height;
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-indicators {
  display: none;
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-control-next-icon,
.Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev-icon {
  display: none;
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
.Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
  transition: all ease 0.3s;
  opacity: 1;
  width: 40px;
  height: 40px;
  top: auto;
  bottom: 250px;
  margin-bottom: -20px;
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Teacher3dPage_pageBottomContent__20vuz .carousel-control-next:before,
.Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev:before {
  content: '\e918';
  font-family: 'icomoon';
  font-size: 14px;
  color: var(--whiteColor);
  width: 100%;
  height: 100%;
  line-height: 40px;
}

.Teacher3dPage_teacherContent__13xjP {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.Teacher3dPage_teacherNavList__1tQk4 {
  max-width: 400px;
  width: 100%;
  margin-right: 10%;
}

@media (max-width: 1199px) {
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
    bottom: 200px;
  }
}

@media (max-width: 991px) {
  .Teacher3dPage_teacherPage__2pCD4 {
    padding-top: 24px;
    margin-bottom: 0;
  }

  .Teacher3dPage_pageBottomContent__20vuz {
    padding: 24px 0 16px;
    background-color: var(--whiteColor);
  }

  .Teacher3dPage_navListContent__3tbQV {
    padding-top: 24px;
  }

  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
    bottom: 260px;
  }
}

@media (max-width: 767px) {
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
    bottom: 190px;
  }
}

@media (max-width: 480px) {
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-next,
  .Teacher3dPage_pageBottomContent__20vuz .carousel-control-prev {
    bottom: 130px;
  }
}

.ExtendOffersPage_root__9TXXv {
  padding-top: 40px;
  margin-bottom: -25px;
}

.ExtendOffersPage_mainTitle__2z1t4 {
  text-align: center;
}

.ExtendOffersPage_topSection__2eRtm {
  padding-bottom: 20px;
}

.ExtendOffersPage_infoBlock__24idh {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
}

.ExtendOffersPage_offerCardContainer__31oFR {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 80px 0;
}

.ExtendOffersPage_infoBlockItem__1tnAp:last-child .ExtendOffersPage_infoBlockContent__3Mbf7 {
  max-width: 500px;
  margin-left: auto;
}

.ExtendOffersPage_infoBlockContent__3Mbf7 h1 {
  max-width: 500px;
}

.ExtendOffersPage_extendPageText__2XtGO {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.ExtendOffersPage_extendPageText__2XtGO p {
  max-width: calc(50% - 10px);
  width: 100%;
}

.ExtendOffersPage_mbShow__3oQwh {
  display: none;
}

@media (max-width: 1199px) {
  .ExtendOffersPage_extendPageText__2XtGO {
    flex-flow: row wrap;
  }

  .ExtendOffersPage_extendPageText__2XtGO p {
    max-width: none;
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .ExtendOffersPage_root__9TXXv {
    padding-top: 24px;
    margin-bottom: 0;
  }

  .ExtendOffersPage_infoBlock__24idh {
    flex-flow: row wrap;
  }

  .ExtendOffersPage_infoBlockItem__1tnAp {
    max-width: 80%;
    margin: 0 auto 20px;
  }

  .ExtendOffersPage_infoBlockItem__1tnAp:last-child .ExtendOffersPage_infoBlockContent__3Mbf7 {
    margin-left: 0;
  }

  .ExtendOffersPage_mbShow__3oQwh {
    display: block;
  }
}

@media (max-width: 767px) {
  .ExtendOffersPage_offerVipCard__1RvAi {
    display: none;
  }
}

.ConfirmedPlanExtensionPage_confirmedPage__3LJH1 {
  margin-bottom: -57px;
}

.ConfirmedPlanExtensionPage_confirmedPageContent__1uZxc {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
}

.ConfirmedPlanExtensionPage_confirmedPageText__1bEQh,
.ConfirmedPlanExtensionPage_imgContainer__2JX9m {
  max-width: calc(50% - 10px);
  width: 100%;
}

.ConfirmedPlanExtensionPage_imgContainer__2JX9m img {
  height: 100%;
  width: auto;
}

.ConfirmedPlanExtensionPage_confirmedPageText__1bEQh h1 {
  margin-bottom: 24px;
}

.ConfirmedPlanExtensionPage_pageTextSmall__3dceQ {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
  margin-bottom: 16px;
}

.ConfirmedPlanExtensionPage_pageTextSubtitle__2H74L {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-gray-blue);
  max-width: 340px;
  margin-bottom: 48px;
}

.ConfirmedPlanExtensionPage_linkBtn__C7bsS {
  font-family: 'museo-slab', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  padding: 0;
  border: none;
  text-transform: uppercase;
  transition: all ease 0.3s;
}

.ConfirmedPlanExtensionPage_linkBtn__C7bsS i {
  display: inline-block;
  margin-left: 5px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-size: 14px;
  color: currentColor;
}

.ConfirmedPlanExtensionPage_linkBtn__C7bsS:hover,
.ConfirmedPlanExtensionPage_linkBtn__C7bsS:focus {
  text-decoration: none;
}

@media (max-width: 991px) {
  .ConfirmedPlanExtensionPage_confirmedPage__3LJH1 {
    padding: 24px 0 60px;
    margin-bottom: 0;
  }

  .ConfirmedPlanExtensionPage_confirmedPageContent__1uZxc {
    flex-flow: row-reverse wrap;
  }

  .ConfirmedPlanExtensionPage_confirmedPageText__1bEQh,
  .ConfirmedPlanExtensionPage_imgContainer__2JX9m {
    max-width: none;
  }

  .ConfirmedPlanExtensionPage_imgContainer__2JX9m {
    display: none;
  }

  .ConfirmedPlanExtensionPage_confirmedPageText__1bEQh {
    text-align: center;
  }

  .ConfirmedPlanExtensionPage_pageTextSmall__3dceQ {
    font-family: 'Hind', sans-serif;
    font-size: 15px;
    line-height: 21px;
    color: var(--neutral-gray-blue);
    margin-bottom: 16px;
  }

  .ConfirmedPlanExtensionPage_pageTextSubtitle__2H74L {
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto 28px;
  }
}

@media (max-width: 767px) {
  .ConfirmedPlanExtensionPage_confirmedPageText__1bEQh {
    text-align: left;
  }

  .ConfirmedPlanExtensionPage_pageTextSubtitle__2H74L {
    max-width: none;
  }

  .ConfirmedPlanExtensionPage_pageTextSubtitle__2H74L {
    margin: 0 0 28px;
  }
}

.TheoryTestPage_root__tLRNw {
  padding: 60px 0 30px;
}

.TheoryTestPage_subtitle__DDvjW {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
  margin: 20px 0 28px;
}

.TheoryTestPage_text__2qz3U {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.TheoryTestPage_links__JJdvD {
  margin-top: 20px;
}

.AutoTestTheoryPage_root__2AIxN {
  padding: 60px 0 30px;
}

.AutoTestTheoryPage_subtitle__2Bc9X {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
  margin: 20px 0 28px;
}

.AutoTestTheoryPage_text__1HwZ9 {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.AutoTestTheoryPage_links__2qqLq {
  margin-top: 20px;
}

.LearnTheoryExam_root__1IHOM {
  padding: 60px 0 30px;
}

.LearnTheoryExam_subtitle__2eO_D {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
  margin: 20px 0 28px;
}

.LearnTheoryExam_text__1dOe0 {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.LearnTheoryExam_links__3BWLj {
  margin-top: 20px;
}

.MopedTestPage_root__1uZzl {
  padding: 60px 0 30px;
}

.MopedTestPage_subtitle__2DOuq {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
  margin: 20px 0 28px;
}

.MopedTestPage_text__1kaxB {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.MopedTestPage_links__3re7k {
  margin-top: 20px;
}

.RollerExamPage_root__2eYB5 {
  padding: 60px 0 30px;
}

.RollerExamPage_subtitle__3xMU7 {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  text-transform: uppercase;
  margin: 20px 0 28px;
}

.RollerExamPage_text__3jl5g {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.RollerExamPage_links__2Vhl7 {
  margin-top: 20px;
}

.HowItWorksPage_howItWorksContainer__J0ySw {
  padding-top: 60px;
  padding-bottom: 80px;
}

.HowItWorksPage_subtitle__2kU7j {
  font-size: 24px;
  font-weight: bold;
  margin: 55px 0 30px 0;
  color: var(--text-color);
}

.HowItWorksPage_howItWoksText__3w90T {
  font-weight: 500;
  font-size: 17px;
  white-space: pre-line;
  color: var(--text-color);
}

.HowItWorksPage_textWithLineHeight__3kThb {
  font-weight: 500;
  font-size: 17px;
  white-space: pre-line;
  color: var(--text-color);
  /*line-height: 40px;*/
}

.HowItWorksPage_disabled__CaULw {
  pointer-events: none;
}

.HowItWorksPage_loginBtn__3gFRs {
  width: 100%;
  pointer-events: none;
}

.HowItWorksPage_sectionMargin__29l7e {
  margin-top: 20px;
}

.HowItWorksPage_tcsNumberContainer__XR6rY {
  background-color: var(--lavender-grey);
  margin-top: 20px;
  padding: 20px 40px;
}

.HowItWorksPage_tcsNumberTitle__kF6-E {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color);
}

ul.HowItWorksPage_tcsNumberList__33c_2 li::before {
  width: 10px;
  height: 10px;
  background-color: var(--text-color);
  border-radius: 100%;
  margin-right: 30px;
  position: absolute;
  top: 8px;
  left: 0;
  content: ' ';
}

.HowItWorksPage_phoneLink__1JDjt {
  color: var(--text-color);
  /*margin: 0 5px;*/
  text-decoration: underline;
}

.HowItWorksPage_phoneLink__1JDjt:hover {
  color: var(--text-color);
}

ul.HowItWorksPage_tcsNumberList__33c_2 {
  padding-left: 20px;
}

ul.HowItWorksPage_tcsNumberList__33c_2 li {
  position: relative;
  /*display: flex;*/
  /*align-items: center;*/
  color: var(--text-color);
  padding-left: 50px;
}

/*offersCard*/

.HowItWorksPage_offersCardContainer__14Uzz {
  background-color: var(--neutral-light-gray);
}

.HowItWorksPage_offersCardContainer__14Uzz .linkBtn,
.HowItWorksPage_offersCardContainer__14Uzz .btn-primary {
  display: none;
}

.HowItWorksPage_offersCardWrapper__3HUnV div div {
  justify-content: flex-start;
}

.HowItWorksPage_offersCardWrapper__3HUnV > div {
  margin: 0;
  padding: 0;
}

.HowItWorksPage_offersCardWrapper__3HUnV .card {
  margin: 5px;
  pointer-events: none;
  max-width: calc(20% - 10px);
}

.HowItWorksPage_offersCardWrapper__3HUnV .card-body {
  padding: 10px;
}

.HowItWorksPage_offersCardWrapper__3HUnV .card-footer {
  margin-top: 5px;
  padding-bottom: 10px;
}

.HowItWorksPage_offersCardWrapper__3HUnV h3 {
  font-size: 20px;
}

.HowItWorksPage_offersCardWrapper__3HUnV .card-title {
  margin-bottom: 5px;
}

.HowItWorksPage_offersCardWrapper__3HUnV .card-text {
  font-size: 13px;
}

.HowItWorksPage_offersCardWrapper__3HUnV .header-text {
  font-size: 18px;
}

/*cardContent*/

.HowItWorksPage_cardCategoriesContainer__oDKGD {
  padding: 72px 0 88px;
  background-color: var(--additional-gray-blue);
}

.HowItWorksPage_cardCategoriesItem__3ayIW {
  position: relative;
  background-color: var(--neutral-light-gray);
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 200px;
  overflow: hidden;
  border: none;
  pointer-events: none;
}

.HowItWorksPage_cardCategoriesItem__3ayIW .card-title {
  font-size: 25px !important;
}

.HowItWorksPage_cardCategoriesItem__3ayIW .card-footer a {
  display: none;
}

.HowItWorksPage_cardCategoriesItem__3ayIW:first-child {
  background: var(--neutral-light-gray) url(/static/media/il-1.f579cf79.svg) no-repeat 100% 60%;
}

.HowItWorksPage_cardCategoriesItem__3ayIW .card-body {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 32px 36px 0;
  z-index: 2;
  width: 100%;
}

.HowItWorksPage_cardCategoriesItem__3ayIW .card-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 16px;
}

.HowItWorksPage_cardCategoriesItem__3ayIW .card-text {
  margin-bottom: 32px;
  color: var(--neutral-gray-blue);
  /*max-width: 300px;*/
}

.HowItWorksPage_cardCategoriesItem__3ayIW .card-footer {
  position: relative;
  padding: 0 36px 28px;
  background-color: transparent;
  border-top: none;
  z-index: 2;
}

.HowItWorksPage_cardCategoriesItem__3ayIW .linkBtn {
  display: inline-flex;
}

.HowItWorksPage_cardCategoriesItem__3ayIW img {
  position: absolute;
  right: 5px;
  top: 15px;
  height: 240px;
  width: auto;
  z-index: 1;
}

.HowItWorksPage_cardCategoriesItem__3ayIW {
  /*max-width: calc(33.3% - 12px);*/
  width: 100%;
  margin-bottom: 20px;
}

.HowItWorksPage_cardCategoriesItem__3ayIW:nth-child(1),
.HowItWorksPage_cardCategoriesItem__3ayIW:nth-child(2) {
  /*max-width: calc(50% - 12px);*/
  width: 100%;
}

@media (max-width: 1199px) {
  .HowItWorksPage_cardCategoriesItem__3ayIW .card-title {
    font-size: 30px;
    line-height: 39px;
  }

  .HowItWorksPage_cardCategoriesItem__3ayIW .card-text {
    margin-bottom: 0;
  }

  .HowItWorksPage_cardCategoriesItem__3ayIW .card-body {
    padding: 10px;
  }

  .HowItWorksPage_offersCardWrapper__3HUnV .card {
    max-width: 30%;
    margin: 10px;
  }
}

@media (max-width: 991px) {
  .HowItWorksPage_cardCategoriesItem__3ayIW .card-title {
    font-size: 24px;
    line-height: 39px;
  }

  .HowItWorksPage_cardCategoriesContainer__oDKGD {
    display: none;
  }

  .HowItWorksPage_cardCategoriesItem__3ayIW,
  .HowItWorksPage_cardCategoriesItem__3ayIW:nth-child(1),
  .HowItWorksPage_cardCategoriesItem__3ayIW:nth-child(2) {
    max-width: none;
    margin-bottom: 12px;
  }

  .HowItWorksPage_cardCategoriesContainer__oDKGD {
    padding: 30px 0 28px;
  }

  .HowItWorksPage_vipCardWrapper__2_17M {
    margin-bottom: 20px;
  }

  .HowItWorksPage_buttonWrapper__j6VYf {
    text-align: center;
    margin-bottom: 20px;
  }

  .HowItWorksPage_loginBtn__3gFRs {
    width: auto;
  }
  .HowItWorksPage_cardCategoriesItem__3ayIW {
    width: 70% !important;
    margin: 0 auto 20px auto;
  }

  .HowItWorksPage_offersCardWrapper__3HUnV .card {
    max-width: unset;
    margin: 10px;
    pointer-events: auto;
  }

  .HowItWorksPage_offersCardWrapper__3HUnV div div {
    justify-content: space-around;
  }

  .HowItWorksPage_offersCardWrapper__3HUnV .show {
    pointer-events: none;
  }

  .HowItWorksPage_offersCardContainer__14Uzz {
    background-color: unset;
  }
}

@media (max-width: 600px) {
  ul.HowItWorksPage_tcsNumberList__33c_2 li {
    position: relative;
    /*display: flex;*/
    /*align-items: center;*/
    color: var(--text-color);
    padding-left: 20px;
  }

  ul.HowItWorksPage_tcsNumberList__33c_2 {
    padding-left: 0;
  }
}

.TCSOffersPage_TCSOffersPage__2TFln {
  padding-top: 60px;
  /*padding-bottom: 80px;*/
}

.TCSOffersPage_TSCOffersText__5xd4B {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  margin: 0;
}

.TCSOffersPage_itemTitle__12QZa {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  color: var(--text-color);
}

.TCSOffersPage_subtitle__Y2qYk {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0 0 0;
  color: var(--text-color);
}

.TCSOffersPage_listContainer__1Ykda {
  background-color: var(--lavender-grey);
  margin-bottom: -57px;
  padding-top: 35px;
  padding-bottom: 80px;
}

.TCSOffersPage_bannerWrapper__FxV7q div {
  width: auto;
  height: auto;
}

.TCSOffersPage_bannerWrapper__FxV7q img {
  width: 100%;
}

.TCSOffersPage_itemsWrapper__1VUQ4 {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.TCSOffersPage_itemTextContainer__7tY1G {
  padding-left: 25px;
}

.TCSOffersPage_colButtonWrapper__1PmfM {
  text-align: center;
}

.TCSOffersPage_underTitle__dclxZ {
  margin-top: 20px;
  font-weight: bold;
  font-size: 22px;
  color: var(--text-color);
}

.TCSOffersPage_underContainer__2Voy7 {
  margin-top: 60px;
}

.TCSOffersPage_underText__dkM-g {
  font-weight: 500;
  font-size: 22px;
}

.TCSOffersPage_container__2GnnY {
  padding-bottom: 45px;
}

@media (max-width: 1199px) {
  .TCSOffersPage_underContainer__2Voy7 img {
    width: 132px;
  }
}

@media (max-width: 991px) {
  .TCSOffersPage_bannerWrapper__FxV7q div {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .TCSOffersPage_bannerWrapper__FxV7q img {
    width: 350px;
  }

  .TCSOffersPage_itemsImageContainer__3auuK {
    text-align: center;
    margin: 20px 0;
  }

  .TCSOffersPage_itemsImageContainer__3auuK img {
    width: 150px;
  }

  .TCSOffersPage_colButtonWrapper__1PmfM a {
    margin: 20px 0;
  }

  .TCSOffersPage_itemTextContainer__7tY1G .TCSOffersPage_itemTitle__12QZa {
    margin-top: 20px;
  }

  .TCSOffersPage_bottomLogoContainer__2bmP9 {
    text-align: center;
    margin-bottom: 25px;
  }

  .TCSOffersPage_listContainer__1Ykda {
    margin-bottom: -20px;
  }
}

@media (max-width: 450px) {
  .TCSOffersPage_bannerWrapper__FxV7q img {
    width: 290px;
  }
}

.AutoTheorieLernenPage_root__6afWb {
  padding: 60px 0 30px;
  color: var(--text-color);
}

.AutoTheorieLernenPage_mainImage__3DWx4 {
  margin: 30px 0;
}

.AutoTheorieLernenPage_video__2aax3 {
  margin: 30px 0;
}

@media (max-width: 780px) {
  .AutoTheorieLernenPage_mainImage__3DWx4 {
    height: 185px;
    width: 300px;
  }

  .AutoTheorieLernenPage_video__2aax3 {
    height: 450px;
    width: 550px;
  }
}

@media (max-width: 680px) {
  .AutoTheorieLernenPage_video__2aax3 {
    height: 185px;
    width: 300px;
  }
}

.AutoTheoriepruefungLernenPage_root__1B0X7 {
  padding: 60px 0 30px;
  color: var(--text-color);
}

.AutoTheoriepruefungLernenPage_mainImage__3Gc0Z {
  margin: 30px 0;
}

.AutoTheoriepruefungLernenPage_video__5phwc {
  margin: 30px 0;
}

.AutoTheoriepruefungLernenPage_root__1B0X7 ul {
  margin-bottom: 25px;
}

@media (max-width: 780px) {
  .AutoTheoriepruefungLernenPage_mainImage__3Gc0Z {
    height: 185px;
    width: 300px;
  }

  .AutoTheoriepruefungLernenPage_video__5phwc {
    height: 450px;
    width: 550px;
  }

  .AutoTheoriepruefungLernenPage_mainTitle__1TjPP {
    font-size: 38px;
    line-height: 44px;
  }
}

@media (max-width: 680px) {
  .AutoTheoriepruefungLernenPage_video__5phwc {
    height: 185px;
    width: 300px;
  }
}

.TheoretischeAutopruefungPage_root__1DVDK {
  padding: 60px 0 30px;
  color: var(--text-color);
}

.TheoretischeAutopruefungPage_mainImage__ecrWp {
  margin: 30px 0;
}

.TheoretischeAutopruefungPage_video__GLx8Z {
  margin: 30px 0;
}

.TheoretischeAutopruefungPage_root__1DVDK ul {
  margin-bottom: 25px;
}

@media (max-width: 780px) {
  .TheoretischeAutopruefungPage_mainImage__ecrWp {
    height: 185px;
    width: 300px;
  }

  .TheoretischeAutopruefungPage_video__GLx8Z {
    height: 450px;
    width: 550px;
  }

  .TheoretischeAutopruefungPage_mainTitle__jDMx4 {
    font-size: 38px;
    line-height: 44px;
  }
}

@media (max-width: 680px) {
  .TheoretischeAutopruefungPage_video__GLx8Z {
    height: 185px;
    width: 300px;
  }
}

.TheoretischeFahrpruefungPage_root__2QT7V {
  padding: 60px 0 30px;
  color: var(--text-color);
}

.TheoretischeFahrpruefungPage_mainImage__M26l_ {
  margin: 30px 0;
}

@media (max-width: 780px) {
  .TheoretischeFahrpruefungPage_mainImage__M26l_ {
    height: 185px;
    width: 300px;
  }

  .TheoretischeFahrpruefungPage_mainTitle__25WXG {
    font-size: 38px;
    line-height: 44px;
  }
}

.AutoTheoriepruefungPage_root__1iRrJ {
  padding: 60px 0 30px;
  color: var(--text-color);
}

.AutoTheoriepruefungPage_mainImage__2-thP {
  margin: 30px 0;
}

@media (max-width: 780px) {
  .AutoTheoriepruefungPage_mainImage__2-thP {
    height: 185px;
    width: 300px;
  }

  .AutoTheoriepruefungPage_mainTitle__1aKAf {
    font-size: 38px;
    line-height: 44px;
  }
}

.TheoriepruefungFuehrerscheinPage_root__2Tt2g {
  padding: 60px 0 30px;
  color: var(--text-color);
}

.TheoriepruefungFuehrerscheinPage_mainImage__1XRBJ {
  margin: 30px 0;
}

@media (max-width: 780px) {
  .TheoriepruefungFuehrerscheinPage_mainImage__1XRBJ {
    height: 185px;
    width: 300px;
  }

  .TheoriepruefungFuehrerscheinPage_mainTitle__3kk3u {
    font-size: 38px;
    line-height: 44px;
  }
}

.SelectGameModeButton_selectGameModeButtonContainer__32WTY {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--text-color);
}

.SelectGameModeButton_selectGameModeButtonContainer__32WTY button {
  margin: 5px 0;
}

.SelectGameModeButton_selectGameModeButtonContainer__32WTY svg {
  width: 85%;
  height: 100%;
  padding-top: 9px;
  fill: var(--theme-primary);
}

.SelectGameModeButton_selected__2o8bv svg {
  fill: var(--whiteColor);
}

.SelectGameModeButton_selectGameModeButtonContainer__32WTY + .SelectGameModeButton_selectGameModeButtonContainer__32WTY {
  margin: 0 55px;
}

.SelectGameModeButton_selectGameModeButtonContainer__32WTY:last-child {
  margin: 0;
}

.SelectGameModeButton_textContainer__1_OAb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.SelectGameModeButton_button__1E9WT {
  border-radius: 100%;
  width: 62px;
  height: 62px;
  min-width: 62px !important;
  min-height: 62px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 0 5px 12px 5px;
  background-color: var(--button-icon-focused);
}

.SelectGameModeButton_button__1E9WT div {
  width: 100%;
}

/*.button div {*/
/*  width: 11px;*/
/*  border: 1px solid var(--theme-primary);*/
/*  margin: 0 2px;*/
/*}*/

.SelectGameModeButton_buttonIconSmallColumn__10A29 {
  height: 11px;
}

.SelectGameModeButton_buttonIconSmallMiddleColumn__1CspU {
  height: 23px;
}

.SelectGameModeButton_buttonIconSmallLargeColumn__3s7D4 {
  height: 33px;
}

.SelectGameModeButton_buttonIconSmallLargeColumnCoverColor__1Jrlf {
  background-color: var(--theme-primary);
}

/*is selected*/

/*.selected.button div {*/
/*  border: 1px solid var(--whiteColor);*/
/*}*/

/*.selected.button .buttonIconSmallLargeColumnCoverColor {*/
/*  background-color: white;*/
/*}*/

@media (max-width: 991px) {
  .SelectGameModeButton_selectGameModeButtonContainer__32WTY + .SelectGameModeButton_selectGameModeButtonContainer__32WTY {
    margin: 0 33px;
  }

  .SelectGameModeButton_selectGameModeButtonContainer__32WTY:last-child {
    margin: 0;
  }
}

@media (max-width: 500px) {
  .SelectGameModeButton_selectGameModeButtonContainer__32WTY,
  .SelectGameModeButton_selectGameModeButtonContainer__32WTY + .SelectGameModeButton_selectGameModeButtonContainer__32WTY {
    margin: 15px 0;
  }

  .SelectGameModeButton_selectGameModeButtonContainer__32WTY button {
    margin: 0;
  }

  .SelectGameModeButton_selectGameModeButtonContainer__32WTY:last-child {
    margin: 0;
  }
}

.gameListContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
  justify-content: space-between;
  font-family: 'retro', serif;
  padding: 0 20px;
}
.GameModeList_gameButton__1jsyP {
  min-width: 130px;
  /*border-color: var(--theme-primary);*/
}

.GameModeList_gameButton__1jsyP + .GameModeList_gameButton__1jsyP {
  margin: 0 15px;
}

.GameModeList_gameButton__1jsyP:last-child {
  margin: 0;
}

.GameModeList_gameButton__1jsyP.GameModeList_selected__2_59f {
  color: var(--whiteColor);
  background: var(--button-primary-enabled);
}

@media (max-width: 500px) {
  .gameListContainer {
    display: flex;
    flex-direction: column;
  }

  .gameListContainer .GameModeList_gameButton__1jsyP {
    margin: 10px 0;
  }
}

.SelectGameModeContainer_container__1ZT5Z {
  max-width: calc(50% - 10px);
  margin: 0 auto;
}

.SelectGameModeContainer_startButtonContainer__3ItLk {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /*max-width: 500px;*/
  font-family: 'retro', serif;
}

.SelectGameModeContainer_startButton__3z1yE {
  font-family: 'retro', serif;
  border: 2px solid #000000;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  color: var(--text-color);
  background-color: var(--whiteColor);
  transition: all 0.5s ease;
}

.SelectGameModeContainer_startButton__3z1yE:hover {
  -webkit-animation: SelectGameModeContainer_retroBackgroundColorChange__wlLHo 5s infinite;
          animation: SelectGameModeContainer_retroBackgroundColorChange__wlLHo 5s infinite;
}

.SelectGameModeContainer_startButtonContainer__3ItLk .btn-outline-success {
  margin-left: 15px;
}

.SelectGameModeContainer_startButtonContainer__3ItLk a {
  font-weight: 500;
  margin-top: 30px;
}

.SelectGameModeContainer_gameCardItem__2PwfA {
  padding: 0 5px;
}

.SelectGameModeContainer_gameCardInfo__1L_kZ {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-radius: 6px;
  padding: 24px 24px 8px;
  /*max-width: 500px;*/
  /*border: 2px solid #000000;*/
  width: 100%;
  margin-bottom: 20px;
  font-family: 'retro', serif;
  transition: all 0.5s ease;
}

.SelectGameModeContainer_cardLabel__3EW6b {
  font-weight: 500;
  font-size: 18px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.SelectGameModeContainer_cardValue__1_ZO2 {
  font-weight: 500;
  font-size: 20px;
  padding: 0 0 10px 0;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .SelectGameModeContainer_container__1ZT5Z {
    max-width: 100%;
  }

  h1.SelectGameModeContainer_title__279qT {
    text-align: center;
    font-size: 35px;
  }

  p.SelectGameModeContainer_description__19gsV {
    font-size: 18px;
    text-align: center;
  }

  .SelectGameModeContainer_startButtonContainer__3ItLk {
    display: flex;
    justify-content: center;
    max-width: unset;
  }

  .SelectGameModeContainer_startButtonContainer__3ItLk button {
    width: 100%;
    /*max-width: 350px;*/
  }
}

@media (max-width: 400px) {
  .SelectGameModeContainer_gameCardInfo__1L_kZ {
    justify-content: center;
  }
}

@-webkit-keyframes SelectGameModeContainer_retroBackgroundColorChange__wlLHo {
  0% {
    background-color: blue;
  }
  10% {
    background-color: #8e44ad;
  }
  20% {
    background-color: #1abc9c;
  }
  30% {
    background-color: #d35400;
  }
  40% {
    background-color: blue;
  }
  50% {
    background-color: #34495e;
  }
  60% {
    background-color: blue;
  }
  70% {
    background-color: #2980b9;
  }
  80% {
    background-color: #f1c40f;
  }
  90% {
    background-color: #2980b9;
  }
  100% {
    background-color: pink;
  }
}

@keyframes SelectGameModeContainer_retroBackgroundColorChange__wlLHo {
  0% {
    background-color: blue;
  }
  10% {
    background-color: #8e44ad;
  }
  20% {
    background-color: #1abc9c;
  }
  30% {
    background-color: #d35400;
  }
  40% {
    background-color: blue;
  }
  50% {
    background-color: #34495e;
  }
  60% {
    background-color: blue;
  }
  70% {
    background-color: #2980b9;
  }
  80% {
    background-color: #f1c40f;
  }
  90% {
    background-color: #2980b9;
  }
  100% {
    background-color: pink;
  }
}

.GameDetailsContainer_bottomDetailsContainer__xqZ9r {
  -webkit-column-count: 2;
          column-count: 2;
  display: flex;
}

.GameDetailsContainer_bottomDetailsContainer__xqZ9r > div {
  width: 100%;
}

.GameDetailsContainer_bottomDetailsContainer__xqZ9r b {
  display: block;
  font-size: 18px;
  margin-bottom: 3px;
}

.GameDetailsContainer_bold__1U_Qz {
  font-weight: bold;
  font-size: 17px;
}

.GameDetailsContainer_bottomDetailsContainer__xqZ9r p {
  font-family: 'retro', serif;
}

@media (max-width: 991px) {
  .GameDetailsContainer_bottomDetailsContainer__xqZ9r {
    display: inherit;
  }

  .GameDetailsContainer_bottomDetailsContainer__xqZ9r > div {
    width: auto;
  }
}

.GameModePage_questionPage__3Z3CB .rulesGroupItem h1 {
  margin-bottom: 20px;
}

.GameModePage_questionTopicBg__2KA82 {
  background-color: var(--additional-gray-blue-20);
}

.GameModePage_mobileView__2rppp {
  display: none;
}

.GameModePage_gameDetails__u_-nj {
  background-color: var(--additional-gray-blue-20);
  margin-top: 10px;
  padding: 30px 0 30px;
  margin-bottom: -60px;
}

.GameModePage_provideFeedBackButton__19NYj {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 10px;
  font-family: 'retro', serif;
  width: 100%;
  background-color: var(--button-primary-enabled);
  font-size: 22px;
  color: var(--whiteColor);
  text-decoration: none;
}

.GameModePage_provideFeedBackButton__19NYj:hover {
  text-decoration: none;
  color: var(--whiteColor);
}

.GameModePage_provideFeedBackButton__19NYj > div {
  padding-left: 10px;
}

@media (max-width: 600px) {
  .GameModePage_provideFeedBackButton__19NYj {
    font-size: 16px;
  }
}

.QuestionChooseModalGameContainer_gameModalContainer__3g5IH .modal-content {
  background-color: var(--whiteColor);
  border-color: var(--blackColor);
  border-style: solid;
}

.QuestionChooseModalGameContainer_gameModalContainer__3g5IH .modal-content button {
  color: var(--text-color);
  font-family: 'retro', serif;
  border-radius: 0;
}

.dark-theme .QuestionChooseModalGameContainer_gameModalContainer__3g5IH .modal-content .QuestionChooseModalGameContainer_examList__afteU button {
  color: var(--not-changine-black);
  background-color: var(--modal-btn-color);
}

.QuestionChooseModalGameContainer_examList__afteU {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  max-height: 364px;
  overflow-y: auto;
}

.QuestionChooseModalGameContainer_examList__afteU::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.QuestionChooseModalGameContainer_examList__afteU::-webkit-scrollbar {
  width: 12px;
}

.QuestionChooseModalGameContainer_examList__afteU::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.QuestionChooseModalGameContainer_examListItem__1PIhR {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid #e2eaf6;

  /*background: var(--not-changine-white);*/
}

.QuestionChooseModalGameContainer_examListItemCorrect__1p-ut {
  border-color: var(--functional-success);
  color: var(--text-color);
  border-width: 2px;
  background-color: var(--functional-success-10);
}

.QuestionChooseModalGameContainer_examListItemCorrect__1p-ut::after {
  content: ' ';
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 19px;
  width: 11px;
  border-bottom: 4px solid var(--functional-success);
  border-right: 4px solid var(--functional-success);
  top: -5px;
  position: absolute;
  right: 0;
  z-index: 9;
}

.QuestionChooseModalGameContainer_examListItemCorrect__1p-ut span::before {
  content: ' ';
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 19px;
  width: 12px;
  border-bottom: 5px solid var(--whiteColor);
  border-right: 5px solid var(--whiteColor);
  top: -5px;
  position: absolute;
  right: 0;
}

.QuestionChooseModalGameContainer_examListItemIncorrect__1fiP2 {
  border-color: var(--functional-warning);
  border-width: 2px;
  background-color: var(--functional-warning-light);
}

body.dark-theme .QuestionChooseModalGameContainer_gameModalContainer__3g5IH .QuestionChooseModalGameContainer_examList__afteU .QuestionChooseModalGameContainer_examListItemIncorrect__1fiP2 {
  color: var(--text-color);
  background-color: var(--functional-warning-light);
}

body.dark-theme .QuestionChooseModalGameContainer_gameModalContainer__3g5IH .QuestionChooseModalGameContainer_examList__afteU .QuestionChooseModalGameContainer_examListItemCorrect__1p-ut {
  background-color: var(--functional-success-10);
  color: var(--text-color);
}

.QuestionChooseModalGameContainer_examListItemIncorrect__1fiP2::after {
  content: 'x';
  display: inline-block;
  height: 21px;
  width: 11px;
  color: var(--functional-warning);
  font-size: 26px;
  font-weight: 700;
  top: -15px;
  position: absolute;
  right: 0;
  z-index: 9;
}

.QuestionChooseModalGameContainer_examListItemIncorrect__1fiP2 span::before {
  content: 'x';
  display: inline-block;
  height: 21px;
  width: 11px;
  color: var(--whiteColor);
  font-size: 29px;
  font-weight: 700;
  top: -17px;
  position: absolute;
  right: 1px;
}

.QuestionChooseModalGameContainer_favorite__U0JSe:before,
.QuestionChooseModalGameContainer_marked__2ATOO:before {
  position: absolute;
  content: '';
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--button-primary-pressed);
  z-index: 1;
}

.QuestionChooseModalGameContainer_checked__2Z5nG {
  background-color: var(--button-secondary-pressed);
}

.QuestionChooseModalGameContainer_questionsNumbers__3dP5W {
  /*position: relative;*/
  z-index: 2;
}

@media (max-width: 991px) {
  .QuestionChooseModalGameContainer_examListItem__1PIhR {
    margin: 5px;
  }

  .QuestionChooseModalGameContainer_examList__afteU::-webkit-scrollbar {
    width: 8px;
  }
}

.QuestionGameChoose_container__2E5NU {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.QuestionGameChoose_container__2E5NU div {
  width: 7px;
  height: 7px;
  border: 1px solid var(--text-color);
  margin: 3px 3px;
  background-color: var(--blackColor);
}

.CountProgressBar_container__2weJg {
  width: 100%;
  transition: all ease-in 1s;
  opacity: 1;
  padding: 0 4px;
}

.CountProgressBar_container__2weJg path {
  transition: all ease-in 0.5s;
}

.CountProgressBar_hidden__18mIX {
  opacity: 0;
}

@media (max-width: 560px) {
  .CountProgressBar_container__2weJg span {
    font-size: 18px;
  }
}

.TopController_container__M9S3S {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.TopController_bounceScore__Javco {
  color: var(--functional-success-bold);
  font-weight: 500;
  font-size: 40px;
  position: absolute;
  z-index: 999;
  opacity: 0;
}

.TopController_defaultHeart__210y1 {
  height: 25px;
  width: 25px;
}

.TopController_defaultHeart__210y1 polygon {
  fill: var(--wrong-game-button);
}

.TopController_wrapper__3VNGS {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-gray);
  background-color: var(--whiteColor);
  max-width: 650px;
  width: 100%;
  border-radius: 5px;
}

.TopController_wrapper__3VNGS span {
  font-family: 'retro', serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--neutral-gray-blue);
  text-align: center;
}

.TopController_topLayer__HAa-m {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-gray);
}

.TopController_bottomLayer__26H6g {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TopController_headerCountBox__2zF1T {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.TopController_headerScoreContainer__ADa4z {
  display: flex;
  justify-content: center;
}

.TopController_headerScoreBox__VQu4v {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
}

.TopController_doubleCounter__1ikLd {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'retro', serif;
  font-weight: 500;
  height: 50px;
  font-size: 13px;
  width: 100%;
  line-height: 20px;
  color: var(--neutral-gray-blue);
}

.TopController_lifeContainer__OK2dl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid var(--neutral-gray);
  height: 50px;
  width: 100%;
}

.TopController_lifeContainer__OK2dl svg {
  margin: 2px;
}

.TopController_doubleCounter__1ikLd div,
.TopController_lifeContainer__OK2dl div {
  margin: 0;
  padding: 0;
}

.TopController_scoreWrapper__6fgPG {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: 'retro', serif;
  font-weight: 500;
  height: 43px;
  font-size: 13px;
  width: 100%;
  line-height: 20px;
  color: var(--neutral-gray-blue);
  transition: all ease-in 0.5s;
  white-space: nowrap;
}

.TopController_extraPointContainer__tID50 {
  width: 100%;
  height: 43px;
  line-height: 20px;
  border-left: 1px solid var(--neutral-gray);
}

.TopController_containerHide__MYFy6 {
  opacity: 0;
  padding: 0;
  height: 0;
  width: 0;
}

.TopController_livesHeartContainer__2v3jK {
  position: relative;
}

.TopController_controllerItem__1lNQl {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TopController_controllerItem__1lNQl span {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}

.TopController_loseHeart__PVJez {
  position: absolute;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  opacity: 0;
  right: -25px;
}

.TopController_loseHeartActive__szrQA {
  -webkit-animation-name: TopController_fadeOut__9iEB1;
          animation-name: TopController_fadeOut__9iEB1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.TopController_questionBtnListItem__3ylzl {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--theme-primary);
  border: none;
  padding: 0;
}

.TopController_questionBtnListItem__3ylzl > i {
  font-size: 36px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TopController_headerCountBox__2zF1T button {
  background-color: var(--whiteColor);
  border: 1px solid var(--blackColor);
  color: var(--text-color);
}

@media (max-width: 500px) {
  .TopController_lifeContainer__OK2dl svg {
    width: 18px;
  }
}

@-webkit-keyframes TopController_fadeOut__9iEB1 {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: -50px;
    opacity: 0;
  }
}

@keyframes TopController_fadeOut__9iEB1 {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: -50px;
    opacity: 0;
  }
}

.SingleCard_singleCardWrapper__25hLo {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.SingleCard_singleCardWrapper__25hLo p {
  font-family: 'retro', serif;
  color: var(--text-color);
}

.SingleCard_answerTextContainer__20BQ7 {
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.SingleCard_answerText__2nfnk {
  display: flex;
  justify-content: center;
  align-items: center;
  /*max-width: 310px;*/
  /*width: 100%;*/
  height: 100%;
  color: var(--whiteColor);
  font-weight: normal;
  font-size: 22px;
  margin: 0;
  border-radius: 0;
  padding: 0 20px;
}

.SingleCard_answerTextWrong__12l-M {
  background-color: var(--functional-warning-bold);
}

.SingleCard_answerTextCorrect__23Isb {
  background-color: var(--functional-success-bold);
}

.SingleCard_answerTextNotAnswer__1v-rN {
  background-color: var(--not-answer);
}

.SingleCard_singleCardWrapper__25hLo .card {
  max-width: 650px;
  background-color: var(--whiteColor);
  border: 4px solid #000000;
}

.SingleCard_singleCardWrapper__25hLo .card .card-img-top {
  padding: 20px;
  border-radius: 30px;
}

.SingleCard_buttonsWrapper__2Yglr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  margin: 0 auto;
}

.SingleCard_wrongButton__8R-lQ {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  font-family: 'retro', serif;
  padding: 0 0 10px 0;
  font-size: 38px;
  line-height: 0;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--wrong-game-button);
}

.SingleCard_correctButton__EGqwf {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--functional-success);
}

.SingleCard_correctButton__EGqwf img {
  width: 35px;
  height: 35px;
}

.SingleCard_titleText__30vsY {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
}

.SingleCard_btnPrev__3QLQ5,
.SingleCard_btnNext__1FQAh,
.SingleCard_btnExit__3Vhi9 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}

.SingleCard_btnPrev__3QLQ5 {
  margin-right: 10px;
}

.SingleCard_btnPrev__3QLQ5 svg,
.SingleCard_btnNext__1FQAh svg {
  width: 50px;
  height: 50px;
}

.SingleCard_btnPrev__3QLQ5 svg path,
.SingleCard_btnNext__1FQAh svg path {
  fill: var(--blackColor);
}

.SingleCard_btnNext__1FQAh svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.SingleCard_isButtonDisabled__egOeh {
  cursor: unset;
}

.SingleCard_wrongCircle__1tH7u {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border: 2px solid var(--wrong-game-button);
  /*border-radius: 100%;*/
}

.SingleCard_correctCircle__2nrf7 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border: 2px solid var(--functional-success);
  /*border-radius: 100%;*/
}

@media (max-width: 500px) {
  .SingleCard_answerText__2nfnk {
    font-size: 17px;
  }
}

.VolumeSettings_volumeIcon__AvVcg {
  cursor: pointer;
  transition: all ease-in 0.5s;
}

.VolumeSettings_volumeIconActive__2B45Z {
  /*transform: rotate(360deg);*/
}

.CircleLight_circleLight__24_RP {
  padding: 5px;
}

.TrafficLight_trafficLightContainer__1pCsm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blackColor);
  border-radius: 5px;
  padding: 10px;
}

.TrafficLightWrapper_container__uE2kH svg rect {
  fill: var(--blackColor);
}

.GameContainer_gamePage__1fyJG {
  background-color: var(--not-changine-black);
  overflow: hidden;
}

.GameContainer_trafficLightContainer__J3VZe {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 380px;
}

.GameContainer_questionLinkBottom__18ja6 {
  max-width: 435px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
  /*padding-bottom: 30px;*/
  margin-top: 40px;
}

.GameContainer_questionLinkBottom__18ja6 .btn {
  font-family: 'retro', serif;
}

.GameContainer_questionLinkBottom__18ja6 svg {
  margin: 0 10px;
}

.GameContainer_questionLinkContainer__2AJQj {
  display: flex;
  justify-content: center;
}

@media (max-width: 550px) {
  .GameContainer_questionLinkBottom__18ja6 .btnLabel {
    border: 1px solid;
  }

  .GameContainer_questionLinkBottom__18ja6 {
    margin: 10px 0;
  }

  .GameContainer_questionLinkBottom__18ja6 a {
    margin: 0 5px;
  }
}

.GameCongratulation_resultPageItem__3MIOC {
  width: 100%;
  padding: 40px 0;
}

.GameCongratulation_resultPageItem__3MIOC h1 {
  color: var(--text-color);
  font-family: 'retro', serif;
  text-align: center;
  font-size: 70px;
  text-transform: uppercase;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}

@media (max-width: 900px) {
  .GameCongratulation_resultPageItem__3MIOC h1 {
    font-size: 50px;
  }
}

@media (max-width: 600px) {
  .GameCongratulation_resultPageItem__3MIOC h1 {
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  .GameCongratulation_resultPageItem__3MIOC h1 {
    font-size: 27px;
  }
}

.rain {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
}

.rain.back-row {
  display: none;
  z-index: 1;
  bottom: 60px;
  opacity: 0.5;
}

.drop {
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 120px;
  pointer-events: none;
  -webkit-animation: RainContainer_drop__3eO51 0.5s linear infinite;
          animation: RainContainer_drop__3eO51 0.5s linear infinite;
}

@-webkit-keyframes RainContainer_drop__3eO51 {
  0% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  75% {
    -webkit-transform: translateY(90vh);
            transform: translateY(90vh);
  }
  100% {
    -webkit-transform: translateY(90vh);
            transform: translateY(90vh);
  }
}

@keyframes RainContainer_drop__3eO51 {
  0% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  75% {
    -webkit-transform: translateY(90vh);
            transform: translateY(90vh);
  }
  100% {
    -webkit-transform: translateY(90vh);
            transform: translateY(90vh);
  }
}

.stem {
  width: 1px;
  height: 60%;
  margin-left: 7px;
  background: linear-gradient(to bottom, #a8a8a8, rgba(255, 255, 255, 0.25));
  -webkit-animation: RainContainer_stem__2n-AE 0.5s linear infinite;
          animation: RainContainer_stem__2n-AE 0.5s linear infinite;
}

@-webkit-keyframes stem {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes stem {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.splat {
  width: 15px;
  height: 10px;
  border-top: 2px dotted rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  opacity: 1;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: RainContainer_splat__yj4Aw 0.5s linear infinite;
          animation: RainContainer_splat__yj4Aw 0.5s linear infinite;
  display: none;
}

body.splat-toggle .splat {
  display: block;
}

@-webkit-keyframes splat {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  90% {
    opacity: 0.5;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes splat {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  90% {
    opacity: 0.5;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.GameResultInfo_resultPageItem__32Fip {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.GameResultInfo_resultPageItem__32Fip .jumbotron {
  max-width: 440px;
  margin-bottom: 36px;
  margin-right: 0;
}

.GameResultInfo_resultList__3alm0 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
  /*padding-right: 20px;*/
}

.GameResultInfo_resultLabel__2ygVH {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--neutral-gray-blue-50);
}

.GameResultInfo_resultValue__2JeSf {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.GameResultInfo_resultShareTitle__342Qm {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

@media (max-width: 1199px) {
  .GameResultInfo_resultPageItem__32Fip {
    max-width: none;
    width: 100%;
  }
}

.GameResultContainer_resultPage__uOxGQ {
  padding: 88px 0 140px;
  position: relative;
  z-index: 1;
  font-family: 'retro', serif;
}

.GameResultContainer_resultPage__uOxGQ h1,
.GameResultContainer_resultPage__uOxGQ span,
.GameResultContainer_resultPage__uOxGQ div,
.GameResultContainer_resultPage__uOxGQ button {
  font-family: 'retro', serif;
}

.GameResultContainer_examButtonList__1N3L8 {
  flex-wrap: wrap;
  max-width: 320px;
}

.GameResultContainer_examButtonList__1N3L8 button {
  margin-top: 10px;
  width: 100%;
}

.GameResultContainer_examButtonList__1N3L8 button.btn:first-child {
  margin-right: 0;
}

.GameResultContainer_resultPageContent__I3eDV {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-right: 0;
}

.GameResultContainer_resultPageRateContainer__CZGC5 {
  max-width: 320px;
  text-align: center;
  margin: -15px 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GameResultContainer_resultPageRateContainer__CZGC5 p {
  margin: 0;
  border-radius: 6px 6px 0 0;
  background-color: var(--button-primary-disabled);
  padding: 8px 21px;
  font-size: 17px;
  font-weight: bold;
}

.GameResultContainer_resultPageRateContainer__CZGC5 a {
  display: flex;
  color: var(--neutral-dark-blue);
  justify-content: center;
  align-items: center;
  background-color: var(--theme-accent);
  height: 35px;
  border-radius: 0 0 6px 6px;
}

.GameResultContainer_examButtonList__1N3L8 {
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .GameResultContainer_resultPage__uOxGQ {
    padding: 24px 0 0;
  }

  .GameResultContainer_resultPageContentItem__2oncV {
    width: 100%;
  }

  .GameResultContainer_examButtonList__1N3L8 button {
    margin-top: 10px;
    width: 100%;
    margin-right: 0 !important;
  }
}

@media (max-width: 600px) {
  .GameResultContainer_resultPageRateContainer__CZGC5 {
    max-width: 440px;
  }

  .GameResultContainer_examButtonList__1N3L8 {
    max-width: 440px;
  }

  .GameResultContainer_examButtonList__1N3L8 button {
    max-width: 440px !important;
  }
}

.ScoreCard_scoreContainer__AS28K {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.ScoreCard_dontHaveScore__1Ff86 {
  color: var(--text-color);
}

.ScoreCard_scoreTitle__3kGoj {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  /*text-transform: uppercase;*/
  color: var(--neutral-dark-blue);
}

.ScoreCard_scoreContent__3yeTh {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin-bottom: 14px;
  padding-top: 15px;
  color: var(--text-color);
}
.ScoreCard_scoreValue__12Umh {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 60px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-bottom: 14px;
}

.ScoreCard_scoreText__3HtsG {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-left: 10px;
}

.ScoreCard_loaderWrapper__1oYpC {
  height: 150px;
}

.ScoreTypeSelector_title__2V5pI {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 25px;
  line-height: 30px;
  /*text-transform: uppercase;*/
  margin-bottom: 40px;
  color: var(--neutral-dark-blue);
  text-align: center;
}

.ScoreTypeSelector_container__36Tyu .dropdown-menu {
  text-align: center;
  width: 100%;
}

.ScoreTypeSelector_container__36Tyu .btn-secondary {
  min-width: 100%;
  position: relative;
}

.ScoreTypeSelector_container__36Tyu .btn-secondary:after {
  position: absolute;
  right: 5px;
  top: 17px;
}

.ScoreTypeSelector_container__36Tyu .dropdown-item {
  padding: 0;
}

.ScoreTypeSelector_selectDifficultyWrapper__ewJXb {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoreTypeSelector_selectDifficultyWrapper__ewJXb svg {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.GameScoreTable_tableContainer__27FrO th,
.GameScoreTable_tableContainer__27FrO td {
  text-align: center;
}

.GameScoreTable_loaderWrapper__1ivLo {
  width: 100%;
  min-width: 100%;
  position: absolute;
  min-height: 180px;
}

.GameScoreTable_backButtonWrapper__28BKU {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}

.GameScoreTable_backButtonWrapper__28BKU .btn {
  color: var(--text-color);
}

.GameScoreTable_noResultContainer__2sDqz {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
}

.GameScoreTable_noResultContainer__2sDqz p {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  color: var(--neutral-dark-blue);
}

.GameScoreTable_backButton__27Qd5 {
  font-family: 'retro', serif;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.GameScoreTable_backButton__27Qd5:hover {
  color: var(--text-color);
}

.GameScoreTable_backButton__27Qd5 svg {
  width: 25px;
  height: 25px;
}

.GameScoreTable_backButton__27Qd5 svg polygon {
  fill: var(--blackColor);
}

.GameScoreContainer_container__XgSPo {
  padding: 40px 0 180px 0;
  overflow: hidden;
  font-family: 'retro', serif;
}

.GameScoreContainer_container__XgSPo p,
.GameScoreContainer_container__XgSPo button,
.GameScoreContainer_container__XgSPo div {
  font-family: 'retro', serif;
}
.GameScoreContainer_container__XgSPo .dropdown button {
  background: var(--button-primary-enabled);
  border-color: var(--button-primary-enabled);
}

.GameScoreContainer_container__XgSPo table {
  color: var(--text-color);
}

.GameScoreContainer_container__XgSPo table tr:hover {
  color: var(--button-primary-enabled);
  cursor: pointer;
}

.GameScoreContainer_container__XgSPo .retroColorChangeClass {
  border: 1px solid var(--blackColor);
}

@media (max-width: 991px) {
  .GameScoreContainer_container__XgSPo {
    padding: 40px 0 40px 0;
  }

  .GameScoreContainer_scoreContainer__1tmvF {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }

  .GameScoreContainer_scoreContainer__1tmvF .jumbotron {
    margin: 0;
  }
}

@media (max-width: 550px) {
  .GameScoreContainer_container__XgSPo table {
    min-width: 600px;
  }
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-body {
  padding: 0 20px;
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-header {
  padding: 20px;
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-body {
  padding: 0 20px;
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-title {
  font-family: 'museo-slab', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  margin-top: 0;
}

.ModalAccessibility_leaveExamModal__3CYoE .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SuccessSignUpPopup_root__NpFg_ {
  font-family: 'Hind', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.SuccessSignUpPopup_header__lAEk4 {
  padding: 20px;
}

.SuccessSignUpPopup_body__2Uc53 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
}

.SuccessSignUpPopup_footer__gqbzH .btn {
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.SuccessSignUpPopup_title__2YhAk {
  font-family: 'museo-slab', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
  margin: 0;
}

.SuccessSignUpPopup_text__1qNmn {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  margin-bottom: 8px;
  text-align: start;
}

.SuccessSignUpPopup_text__1qNmn .SuccessSignUpPopup_linkText__2txsp {
  color: var(--button-default-focused);
}

.SuccessSignUpPopup_passwordContainer__2-ktf {
  text-align: center;
}

.SuccessSignUpPopup_passwordContainer__2-ktf .SuccessSignUpPopup_passwordInput__1WaOJ {
  display: inline;
  font-family: 'museo-slab', sans-serif;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: var(--neutral-dark-blue);
  border: 0;
  background-color: transparent;
  max-width: 100%;
}

.SuccessSignUpPopup_passwordContainer__2-ktf .SuccessSignUpPopup_copyHint__mJ67U {
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  transition: 0.3s;
  opacity: 0;
}

.SuccessSignUpPopup_passwordContainer__2-ktf .SuccessSignUpPopup_copyHint__mJ67U.SuccessSignUpPopup_copyHintShow__3Lg7R {
  opacity: 1;
}

.SuccessSignUpPopup_body__2Uc53 .SuccessSignUpPopup_copyButton__1pJok {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: var(--modal-footer-btn-color);
}

footer {
  padding: 40px 0 42px;
  border-top: 1px solid var(--neutral-gray);
  margin-top: 57px;
}

.Footer_socialLinkList__q8VD_ {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-top: 45px;
}

.Footer_underFooterLogo__Xl7UN {
  font-weight: bold;
  color: var(--text-color);
  font-size: 16px;
  margin-bottom: 5px;
}

.Footer_explanationText__3rSOI {
  margin: 0 0 10px 0;
}

.Footer_socialLinkList__q8VD_ > li:not(:last-child) {
  margin-right: 12px;
}

.Footer_footerLogo__2TNvs {
  display: block;
  max-width: 150px;
}

.Footer_socialLinkItem__1Jeq4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease-in-out 0.4s;
}

.dark-theme .Footer_socialLinkItem__1Jeq4,
.dark-theme .Footer_socialLinkItem__1Jeq4:hover {
  color: var(--text-color);
  background-color: var(--neutral-light-gray);
}

.Footer_socialLinkItem__1Jeq4:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.Footer_brandLinkList__1WDHl {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding-top: 12px;
}

.Footer_brandLinkList__1WDHl > li {
  background-color: #f5f5f5;
  max-width: calc(50% - 8px);
  height: 36px;
  width: 100%;
  border-radius: 2px;
  margin-top: 12px;
}

.Footer_brandLinkList__1WDHl > li:nth-child(event) {
  margin-right: 12px;
}

.Footer_brandLinkItem__DRzYs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Footer_footerTextWrapper__1Sacs {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  height: 100%;
}

.Footer_footerText__2uHpc {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.Footer_footerTextItem__hAUsY {
  white-space: nowrap;
}

.Footer_footerTextItem__hAUsY:not(:first-child):before {
  content: '·';
  margin: 0 5px;
}

.Footer_partnerLink__krOjR {
  display: block;
  text-align: center;
}

.Footer_footerTextWrapper__1Sacs {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  height: 100%;
}

.Footer_footerCopyright__3QU6m,
.Footer_footerCopyrightLink__DfmeY,
.Footer_footerCopyrightMobile__1dZYr {
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 18px;
  color: var(--footer-text);
}

.Footer_footerCopyright__3QU6m {
  margin-top: 16px;
}

.Footer_footerCopyrightLink__DfmeY {
  display: inline-block;
  padding: 0 2px;
}

.Footer_footerCopyrightLink__DfmeY:hover {
  text-decoration: none;
  color: rgba(75, 101, 132, 0.7);
}

.Footer_footerSeoContainer__WnFc- {
  padding-top: 20px;
  text-align: center;
  color: rgba(75, 101, 132, 0.7);
}

@media (max-width: 1200px) {
  .Footer_socialLinkItem__1Jeq4 {
    width: 30px;
    height: 30px;
  }

  .Footer_socialLinkItem__1Jeq4 > i {
    font-size: 20px;
  }

  .Footer_footerTextItem__hAUsY:last-child {
    display: block;
  }

  .Footer_footerTextItem__hAUsY:last-child:before {
    content: none;
  }
}

@media (max-width: 991px) {
  footer {
    padding: 24px 0 30px;
    margin-top: 20px;
  }

  footer .Footer_socialLinkList__q8VD_,
  .Footer_footerCopyright__3QU6m {
    display: none;
  }

  .Footer_footerText__2uHpc {
    font-size: 14px;
    line-height: 22px;
  }

  .Footer_footerTextItem__hAUsY:last-child:first-letter {
    display: none;
  }

  .Footer_footerCopyrightMobile__1dZYr {
    margin-top: 36px;
  }

  .Footer_footerLogo__2TNvs {
    display: block;
    max-width: 117px;
    margin-bottom: 26px;
  }

  .Footer_partnerLink__krOjR {
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .Footer_footerCopyrightMobile__1dZYr {
    display: none;
  }

  .Footer_footerCopyright__3QU6m {
    display: block;
  }
}

.LoginModal_forgotPassLink__13Pq7 {
  text-decoration: underline;
}

.LoginModal_formLinkBox__1PsnK {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.LoginModal_actionBtn__3Fuia {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--not-changine-white);
  width: 100%;
  border-radius: 0 0 4px 4px;
}

.modalLogin .custom-control-label {
  color: var(--not-changine-black);
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-body {
  padding: 0 20px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-header {
  padding: 20px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-body {
  padding: 0 20px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-body p {
  color: var(--not-changine-black);
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-title {
  font-family: 'museo-slab', sans-serif;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--not-changine-black);
  margin-top: 0;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .form-group {
  margin-bottom: 35px;
}

.RecoveryPasswordModal_linkBtn__1bLYN {
  font-family: 'museo-slab', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  border: none;
  transition: all ease 0.3s;
  padding: 6px 24px;
}

.RecoveryPasswordModal_recoveryModal__1Iet0 .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LogoutModal_actionBtn__3TLmL {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--not-changine-white);
  width: 100%;
  border-radius: 0 0 4px 4px;
}

.LogoutModal_text__1MDD6 {
  color: var(--not-changine-black);
}

.GoodByeModal_modalGoodBye__2j7Vx {
  padding: 0;
}

.GoodByeModal_modalGoodBye__2j7Vx .modal-body {
  padding: 0;
}

.GoodByeModal_modalGoodBye__2j7Vx .modal-body {
  padding: 0;
}

.GoodByeModal_modalGoodBye__2j7Vx .modal-footer {
  padding: 25px 20px;
}

.GoodByeModal_modalFooterText__1wrdU {
  letter-spacing: 0.1px;
  color: var(--not-changine-black);
}

.ScrollToTopButton_btnTop__Ss9TZ {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  background-color: var(--whiteColor);
  transition: ease 0.3s;
  -webkit-transform-origin: center;
          transform-origin: center;
  border: 1px solid transparent;
  -webkit-transform: scale(0);
          transform: scale(0);
  z-index: 10;
}

.ScrollToTopButton_btnTop__Ss9TZ i {
  width: 14px;
  height: 14px;
  font-size: 14px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  color: var(--theme-primary);
}

.ScrollToTopButton_btnTop__Ss9TZ.ScrollToTopButton_visible__3ZgUE {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.ScrollToTopButton_btnTop__Ss9TZ:hover {
  cursor: pointer;
  box-shadow: none;
  border: 1px solid var(--theme-primary);
}

.ScrollToTopButton_btnTopText__QEgvX {
  font-family: 'Hind', sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: var(--text-color);
}

@media (max-width: 767px) {
  .ScrollToTopButton_btnTop__Ss9TZ {
    right: 15px;
    bottom: 15px;
  }
}

